import { startCase } from "lodash";
import { IColumnDefinition } from "../../../../bidding/common/table/types/ColumnDefinition";
import { ColumnBuilder } from "../../../../bidding/common/table/columns/column-builder/ColumnBuilder";
import { constants, roles, SORTING_TYPE } from "../../../../../constants";
import { amrFormatUtils, formatUtils, moneyUtils, numericUtils } from "../../../../../utils";
import { OnHoverTooltip } from "../../../../common";
import { OverlayClassNameContent } from "../../../common/OverlayClassNameContent";
import { constants as amrConstants } from "../../../common/constants";
import { OriginatingTransactionClassStatus, transactionClassStatusTitles } from "../../../../../types/amr-pipeline/enums/OriginatingTransactionClassStatus";
import { OriginatingTransCapitalStructure } from "./CapitalStructure";
import { LastUpdatedPopover } from '../../../aggregated/LastUpdatedPopover';
import { Difference, Dynamics } from '../../../../../utils/differ/types';
import { OriginatingTransactionClassDiff } from '../../../../../types/amr-pipeline/models/OriginatingTransactionClassDiff';
import { DifferenceField } from '../DifferenceField';
import { DifferenceType } from '../../../../../utils/differ/types';
import { BloombergLink } from "../../../../common/BloombergLink";
import { ValitanaLink } from "../../../../common/ValitanaLink";
import { AddToPortfolioLink } from "../../../common/AddToPortfolioLink";
import { user } from "../../../../../user";
import { RequiredFeature } from '../../../../access/RequiredFeature';
import { SubscriptionFeature } from '../../../../../types/billing/SubscriptionFeature';
import { OriginatingTransaction } from '../../../../../types/amr-pipeline/models/OriginatingTransaction';
import { ImSubscriptionValue } from '../../../subscription/ImSubscriptionValue';

interface ColumnContext {
    difference?: Difference<OriginatingTransactionClassDiff>;
    transaction: OriginatingTransaction;
}

const balance = {
    columnKey: "balance",
    renderColumnHeaderContent: () => "Balance",
    renderColumnContent: (transactionClass: OriginatingTransCapitalStructure, { difference }: ColumnContext) =>
        <DifferenceField formatter={moneyUtils.money} difference={difference?.balance}>
            { transactionClass.balance != null && numericUtils.isNumber(transactionClass.balance)
                ? moneyUtils.money(transactionClass.balance)
                : constants.emptyPlaceholder
            }
        </DifferenceField>,
    className: "data-list-cell-md text-right data-list-cell-right-offset data-list-cell-change",
    sortingField: "balance",
    sortingType: SORTING_TYPE.number
};

export const amrColumn: IColumnDefinition<OriginatingTransCapitalStructure> = {
    columnKey: "amr",
    renderColumnHeaderContent: () => "AMR",
    renderColumnContent: (transactionClass, { difference }: ColumnContext) =>
        <DifferenceField difference={difference?.amr} formatter={formatUtils.formatBoolean}>
            {formatUtils.formatBoolean(transactionClass.amr)}
        </DifferenceField>,
    headerClassName: "data-list-cell-xs",
    bodyClassName: "data-list-cell-xs text-capitalize",
    sortingField: "amr",
};

const classIndicatorColumnDefinitions: IColumnDefinition<OriginatingTransCapitalStructure>[] = [
    {
        columnKey: "ticker144A",
        renderColumnHeaderContent: () => "Ticker (144A)",
        renderColumnContent: (transactionClass) =>
            transactionClass.ticker144A || constants.emptyPlaceholder,
        className: "data-list-cell-lg",
        sortingField: "ticker144A",
        sortingType: SORTING_TYPE.string
    },
    {
        columnKey: "tickerRegS",
        renderColumnHeaderContent: () => "Ticker (Reg S)",
        renderColumnContent: (transactionClass) =>
            transactionClass.tickerRegS || constants.emptyPlaceholder,
        className: "data-list-cell-lg",
        sortingField: "tickerRegS",
        sortingType: SORTING_TYPE.string
    },
    {
        columnKey: "tickerAccdInvCertif",
        renderColumnHeaderContent: () => "Ticker (Acc’d Inv./Certif.)",
        renderColumnContent: (transactionClass) =>
            transactionClass.tickerAccdInvCertif || constants.emptyPlaceholder,
        className: "data-list-cell-lg-02",
        sortingField: "tickerAccdInvCertif",
        sortingType: SORTING_TYPE.string
    },
];

const capitalStructureColumnDefinitions: IColumnDefinition<OriginatingTransCapitalStructure>[] = [
    {
        columnKey: "className",
        stickLeft: true,
        renderColumnHeaderContent: () => "Class",
        renderColumnContent: (transactionClass, { difference }: ColumnContext) => (
            <OnHoverTooltip
                overlay={
                    <OverlayClassNameContent
                        transactionClass={transactionClass}
                    />
                }
            >
                <DifferenceField difference={difference?.name}>
                    <span className="text-ellipsis">
                        {`${transactionClass.name} ${
                            transactionClass.originatingTransactionClassStatus ===
                            OriginatingTransactionClassStatus.NotOffered
                                ? amrConstants.notOffered
                                : ''
                        }`}
                    </span>
                </DifferenceField>
            </OnHoverTooltip>
        ),
        className: "",
        sortingField: "name",
        sortingType: SORTING_TYPE.string
    },
    balance,
    {
        columnKey: "parSubordination",
        renderColumnHeaderContent: () => "C/E, %",
        renderColumnContent: (transactionClass, { difference }: ColumnContext) =>
            <DifferenceField formatter={formatUtils.formatDecimal} difference={difference?.parSubordination}>
                { transactionClass.parSubordination
                    ? formatUtils.formatDecimal(
                        transactionClass.parSubordination
                    )
                    : constants.emptyPlaceholder
                }
            </DifferenceField>,
        className: "data-list-cell-sm text-right data-list-cell-right-offset data-list-cell-change",
        sortingField: "parSubordination",
        sortingType: SORTING_TYPE.number
    },
    {
        columnKey: "capStructure",
        renderColumnHeaderContent: () => "% of Cap Structure",
        renderColumnContent: (transactionClass, { difference }: ColumnContext) =>
            <DifferenceField formatter={formatUtils.formatDecimal} difference={difference?.percentOfCapStructure}>
                { transactionClass.percentOfCapStructure
                    ? formatUtils.formatDecimal(
                        transactionClass.percentOfCapStructure
                    )
                    : constants.emptyPlaceholder
                }
            </DifferenceField>,
        className: "data-list-cell-lg text-right data-list-cell-right-offset data-list-cell-change",
        sortingField: "percentOfCapStructure",
        sortingType: "number",
    },
    {
        columnKey: "mvoc",
        renderColumnHeaderContent: () => "MVOC, %",
        renderColumnContent: (transactionClass, { difference }: ColumnContext) =>
            <DifferenceField formatter={formatUtils.formatDecimal} difference={difference?.mvoc}>
                { formatUtils.formatDecimal(transactionClass.mvoc) ?? constants.emptyPlaceholder }
            </DifferenceField>,
        className: "data-list-cell-sm text-right data-list-cell-right-offset data-list-cell-change",
        sortingField: "mvoc",
        sortingType: SORTING_TYPE.number
    },
    {
        columnKey: "wal",
        renderColumnHeaderContent: () => "WAL",
        renderColumnContent: (transactionClass, { difference }: ColumnContext) =>
            <DifferenceField formatter={formatUtils.formatDecimal} difference={difference?.wal}>
                { transactionClass.wal
                    ? formatUtils.formatDecimal(transactionClass.wal)
                    : constants.emptyPlaceholder
                }
            </DifferenceField>,
        className: "data-list-cell-sm text-right data-list-cell-right-offset data-list-cell-change",
        sortingField: "wal",
        sortingType: SORTING_TYPE.number
    },
    {
        columnKey: "rating",
        renderColumnHeaderContent: () => "Rtg (M/S/F/K/D)",
        renderColumnContent: (transactionClass, { difference }: ColumnContext) =>
            <DifferenceField difference={difference?.ratingString}>
                { amrFormatUtils.formatRating(transactionClass) }
            </DifferenceField>,
        className: "data-list-cell-xl",
        sortingField: "rating",
        sortingType: SORTING_TYPE.rating
    },
    {
        columnKey: "coupon",
        renderColumnHeaderContent: () => "Coupon",
        renderColumnContent: ({ coupon }, { difference }: ColumnContext) =>
            <DifferenceField difference={difference?.coupon}>{coupon}</DifferenceField>,
        className: "data-list-cell-lg",
        sortingField: "coupon",
        sortingType: SORTING_TYPE.string,
    },
    {
        columnKey: "dm",
        renderColumnHeaderContent: () => "DM, bps",
        renderColumnContent: (transactionClass, { difference, transaction }: ColumnContext) =>
            <ImSubscriptionValue transaction={transaction}>
                <DifferenceField difference={difference?.dm}>
                    { transactionClass.dm ?? constants.emptyPlaceholder }
                </DifferenceField>
            </ImSubscriptionValue>,
        className: "data-list-cell-sm text-right data-list-cell-right-offset data-list-cell-change",
        sortingField: "dm",
        sortingType: SORTING_TYPE.number
    },
    {
        columnKey: "initialPrice",
        renderColumnHeaderContent: () => "Initial Price",
        renderColumnContent: (transactionClass, { transaction }) => (
            <ImSubscriptionValue transaction={transaction}>
                {transactionClass.initialPrice ?
                    <span>{transactionClass.initialPrice}</span>
                    : constants.emptyPlaceholder}
            </ImSubscriptionValue>
        ),
        className: "data-list-cell-sm text-right data-list-cell-right-offset data-list-cell-change",
        sortingField: "initialPrice",
        sortingType: SORTING_TYPE.string
    },
    {
        columnKey: "price",
        renderColumnHeaderContent: () => "Price",
        renderColumnContent: (transactionClass, { difference, transaction }: ColumnContext) => {
            let formattedPrice = undefined;
            // Temporary formatting strings as numbers. Need to be removed after price field will be changed from string to number type
            if (difference?.price) {
                const { price } = difference
                const derivedValue = price.derivedValue
                    ? +price.derivedValue
                    : undefined;
                const currentValue = price.currentValue
                    ? +price.currentValue
                    : undefined;
                const previousValue = price.previousValue
                    ? +price.previousValue
                    : undefined;

                if (
                    numericUtils.isNumber(derivedValue)
                    && numericUtils.isNumber(currentValue)
                    && numericUtils.isNumber(previousValue)
                ) {
                    formattedPrice = {
                        ...difference?.price,
                        derivedValue,
                        currentValue,
                        previousValue,
                        dynamics: currentValue > previousValue ? Dynamics.Increased : Dynamics.Decreased,
                    }
                }
            }

            return (
                <ImSubscriptionValue transaction={transaction}>
                    <DifferenceField difference={formattedPrice || difference?.price}>
                        { transactionClass.price ?
                            <span>{transactionClass.price}</span>
                            : constants.emptyPlaceholder
                        }
                    </DifferenceField>
                </ImSubscriptionValue>
            );
        },
        className: "data-list-cell-sm text-right data-list-cell-right-offset data-list-cell-change",
        sortingField: "price",
        sortingType: SORTING_TYPE.string
    },
    {
        columnKey: "initialGuidance",
        renderColumnHeaderContent: () => "Initial Guidance",
        renderColumnContent: (transactionClass, { transaction }) => (
            <ImSubscriptionValue transaction={transaction}>
                {transactionClass.initialGuidance
                    ? <span className="text-ellipsis">{transactionClass.initialGuidance}</span>
                    : constants.emptyPlaceholder}
            </ImSubscriptionValue>
        ),
        className: "data-list-cell-sm",
        sortingField: "initialGuidance",
        sortingType: SORTING_TYPE.string
    },
    {
        columnKey: "guidance",
        renderColumnHeaderContent: (context: any) =>
            context.withGuidance ? (
                <RequiredFeature
                    feature={SubscriptionFeature.IssuanceMonitorFullAccess}
                    renderBlockedContent={() => 'Guidance'}
                >
                    <LastUpdatedPopover
                        transaction={context.transaction}
                        field="guidance"
                        historyLinkTarget="_self"
                    >
                        <span className="tooltip-wrapper pseudo-link">
                            Guidance
                        </span>
                    </LastUpdatedPopover>
                </RequiredFeature>
            ) : (
                <span>Guidance</span>
            ),
        renderColumnContent: (transactionClass, { difference, transaction }: ColumnContext) => (
            <ImSubscriptionValue transaction={transaction}>
                <DifferenceField difference={difference?.guidance}>
                    { transactionClass.guidance ?
                        difference?.guidance?.type === DifferenceType.Updated
                            ? <span className="text-ellipsis">{transactionClass.guidance}</span>
                            : (
                                <OnHoverTooltip overlay={transactionClass.guidance}>
                                    <span className="text-ellipsis">{transactionClass.guidance}</span>
                                </OnHoverTooltip>
                            )
                        : constants.emptyPlaceholder
                    }
                </DifferenceField>
            </ImSubscriptionValue>
        ),
        className: "data-list-cell-sm",
        sortingField: "guidance",
        sortingType: SORTING_TYPE.string
    },
    {
        columnKey: "originatingTransactionClassStatus",
        renderColumnHeaderContent: () => "Class Status",
        renderColumnContent: (transactionClass, { difference, transaction }: ColumnContext) =>
            <ImSubscriptionValue transaction={transaction}>
                <DifferenceField formatter={startCase} difference={difference?.originatingTransactionClassStatus}>
                    { transactionClass.originatingTransactionClassStatus
                        ? transactionClassStatusTitles[transactionClass.originatingTransactionClassStatus]
                        : constants.emptyPlaceholder
                    }
                </DifferenceField>
            </ImSubscriptionValue>,
        className: "data-list-cell-sm",
        sortingField: "originatingTransactionClassStatus",
        sortingType: SORTING_TYPE.string
    },
    {
        columnKey: "subscription",
        renderColumnHeaderContent: (context: any) =>
            context.withSubscription ? (
                <RequiredFeature
                    feature={SubscriptionFeature.IssuanceMonitorFullAccess}
                    renderBlockedContent={() => 'Subscription'}
                >
                    <LastUpdatedPopover
                        transaction={context.transaction}
                        field="subscription"
                        historyLinkTarget="_self"
                    >
                        <span className="tooltip-wrapper pseudo-link">
                            Subscription
                        </span>
                    </LastUpdatedPopover>
                </RequiredFeature>
            ) : (
                <span>Subscription</span>
            ),
        renderColumnContent: (transactionClass, { difference, transaction }: ColumnContext) => (
            <ImSubscriptionValue transaction={transaction}>
                <DifferenceField difference={difference?.subscription}>
                    { transactionClass.subscription ?
                        difference?.subscription?.type === DifferenceType.Updated
                            ? <span className="text-ellipsis">{transactionClass.subscription}</span>
                            : (
                                <OnHoverTooltip overlay={transactionClass.subscription}>
                                    <span className="text-ellipsis">{transactionClass.subscription}</span>
                                </OnHoverTooltip>
                            )
                        : constants.emptyPlaceholder
                    }
                </DifferenceField>
            </ImSubscriptionValue>
        ),
        sortingField: "subscription",
        className: "data-list-cell-md",
        sortingType: SORTING_TYPE.string
    },
    {
        columnKey: 'actions',
        renderColumnHeaderContent: () => 'Actions',
        renderColumnContent: (transactionClass, { transaction }) => {
            const isPortfolioEnabled = !user.hasRoles(roles.ArrangersClient);

            return (
                <div className="action-row">
                    {isPortfolioEnabled && (
                        <div className="action-col">
                            <AddToPortfolioLink
                                transaction={transaction}
                                transactionClass={transactionClass}
                                checkSubscription
                            />
                        </div>
                    )}
                    <div className="action-col">
                        <BloombergLink
                            placeholderIfEmpty
                            secondaryPlaceholderColor
                            identifiers={transactionClass}
                        />
                    </div>
                    <div className="action-col">
                        <ValitanaLink
                            placeholderIfEmpty
                            secondaryPlaceholderColor
                            identifiers={transactionClass}
                        />
                    </div>
                </div>
            );
        },
        className: 'data-list-cell-sm-01 text-right',
        stickRight: true,
    },
];

const coverageTestColumnDefinitions: IColumnDefinition<OriginatingTransCapitalStructure>[] = [
    {
        columnKey: "trigger",
        renderColumnHeaderContent: () => "O/C Trigger, %",
        renderColumnContent: (transactionClass, { difference }: ColumnContext) =>
            <DifferenceField formatter={formatUtils.formatDecimal} difference={difference?.ocTrigger}>
                { formatUtils.formatDecimal(transactionClass.ocTrigger) ?? constants.emptyPlaceholder }
            </DifferenceField>,
        className: "data-list-cell-md text-right data-list-cell-right-offset data-list-cell-change",
        sortingField: "ocTrigger",
        sortingType: SORTING_TYPE.number,
    },
    {
        columnKey: "target",
        renderColumnHeaderContent: () => "O/C Target, %",
        renderColumnContent: (transactionClass, { difference }: ColumnContext) =>
            <DifferenceField formatter={formatUtils.formatDecimal} difference={difference?.ocTarget}>
                { formatUtils.formatDecimal(transactionClass.ocTarget) ?? constants.emptyPlaceholder}
            </DifferenceField>,
        className: "data-list-cell-md text-right data-list-cell-right-offset data-list-cell-change",
        sortingField: "ocTarget",
        sortingType: SORTING_TYPE.number,
    },
    {
        columnKey: "cushion",
        renderColumnHeaderContent: () => "O/C Cushion, %",
        renderColumnContent: (transactionClass, { difference }: ColumnContext) =>
            <DifferenceField formatter={formatUtils.formatDecimal} difference={difference?.ocCushion}>
                { transactionClass.ocCushion
                    ? formatUtils.formatDecimal(transactionClass.ocCushion)
                    : constants.emptyPlaceholder
                }
            </DifferenceField>,
        className: "data-list-cell-md text-right data-list-cell-right-offset data-list-cell-change",
        sortingField: "ocCushion",
        sortingType: SORTING_TYPE.number,
    },
    {
        columnKey: "icTrigger",
        renderColumnHeaderContent: () => "I/C Trigger, %",
        renderColumnContent: (transactionClass, { difference }: ColumnContext) =>
            <DifferenceField formatter={formatUtils.formatDecimal} difference={difference?.icTrigger}>
                { formatUtils.formatDecimal(transactionClass.icTrigger) ?? constants.emptyPlaceholder }
            </DifferenceField>,
        className: "data-list-cell-md text-right data-list-cell-right-offset data-list-cell-change",
        sortingField: "icTrigger",
        sortingType: SORTING_TYPE.number,
    },
    amrColumn,
];

const covClassIndicatorColumnDefinitions: IColumnDefinition<OriginatingTransCapitalStructure>[] = [
    {
        columnKey: "ticker144A",
        renderColumnHeaderContent: () => "Ticker (144A)",
        renderColumnContent: (transactionClass) =>
            transactionClass.ticker144A || constants.emptyPlaceholder,
        className: "data-list-cell-lg",
        sortingField: "ticker144A",
        sortingType: SORTING_TYPE.string
    },
    {
        columnKey: "cusip144A",
        renderColumnHeaderContent: () => "CUSIP (144A)",
        renderColumnContent: (transactionClass) =>
            transactionClass.cusip144A || constants.emptyPlaceholder,
        className: "data-list-cell-md",
        sortingField: "cusip144A",
        sortingType: SORTING_TYPE.string
    },
    {
        columnKey: "isin144A",
        renderColumnHeaderContent: () => "ISIN (144A)",
        renderColumnContent: (transactionClass) =>
            transactionClass.isin144A || constants.emptyPlaceholder,
        className: "data-list-cell-sm",
        sortingField: "isin144A",
        sortingType: SORTING_TYPE.string
    },
    {
        columnKey: "tickerRegS",
        renderColumnHeaderContent: () => "Ticker (Reg S)",
        renderColumnContent: (transactionClass) =>
            transactionClass.tickerRegS || constants.emptyPlaceholder,
        className: "data-list-cell-lg",
        sortingField: "tickerRegS",
        sortingType: SORTING_TYPE.string
    },
    {
        columnKey: "cusipRegS",
        renderColumnHeaderContent: () => "CUSIP (Reg S)",
        renderColumnContent: (transactionClass) =>
            transactionClass.cusipRegS || constants.emptyPlaceholder,
        className: "data-list-cell-md",
        sortingField: "cusipRegS",
        sortingType: SORTING_TYPE.string
    },
    {
        columnKey: "isinRegS",
        renderColumnHeaderContent: () => "ISIN (Reg S)",
        renderColumnContent: (transactionClass) =>
            transactionClass.isinRegS || constants.emptyPlaceholder,
        className: "data-list-cell-md",
        sortingField: "isinRegS",
        sortingType: SORTING_TYPE.string
    },
    {
        columnKey: "tickerAccdInvCertif",
        renderColumnHeaderContent: () => "Ticker (Acc’d Inv./Certif.)",
        renderColumnContent: (transactionClass) =>
            transactionClass.tickerAccdInvCertif || constants.emptyPlaceholder,
        className: "data-list-cell-lg-02",
        sortingField: "tickerAccdInvCertif",
        sortingType: SORTING_TYPE.string
    },
    {
        columnKey: "cusipAccdInvCertif",
        renderColumnHeaderContent: () => "CUSIP (Acc’d Inv./Certif.)",
        renderColumnContent: (transactionClass) =>
            transactionClass.cusipAccdInvCertif || constants.emptyPlaceholder,
        className: "data-list-cell-lg-02",
        sortingField: "cusipAccdInvCertif",
        sortingType: SORTING_TYPE.string
    },
    {
        columnKey: "isinAccdInvCertif",
        renderColumnHeaderContent: () => "ISIN (Acc’d Inv./Certif.)",
        renderColumnContent: (transactionClass) =>
            transactionClass.isinAccdInvCertif || constants.emptyPlaceholder,
        className: "data-list-cell-lg-02",
        sortingField: "isinAccdInvCertif",
        sortingType: SORTING_TYPE.string
    },
];

export const capitalStructureColumns = capitalStructureColumnDefinitions.map((c) => {
    return new ColumnBuilder(c);
});

export const classIndicatorColumns = classIndicatorColumnDefinitions.map((c) => {
    return new ColumnBuilder(c);
});

export const coverageTestColumns = coverageTestColumnDefinitions.map((c) => {
    return new ColumnBuilder(c);
});

export const coverageClassIndicatorColumns = covClassIndicatorColumnDefinitions.map((c) => {
    return new ColumnBuilder(c);
});
