import { useMemo, useState } from 'react';
import cn from 'classnames';
import { values } from 'lodash';
import { LimitedFeaturesPopup } from './LimitedFeaturesPopup';
import { ContentSection } from '../ContentSection';
import { Table } from '../../../../bidding/common/table';
import { useSubscriptions } from '../hooks/useSubscriptions';
import { Preloader } from '../../../../common';
import { isRequestSuccess } from '../../../../../utils';
import { Subscription } from '../../../../../types/billing/Subscription';
import { AppProduct, getAppProductTitle } from '../../../../../types/billing/AppProduct';
import { IColumnDefinition } from '../../../../bidding/common/table/types/ColumnDefinition';
import { user } from '../../../../../user';
import { ColumnBuilder } from '../../../../bidding/common/table/columns/column-builder/ColumnBuilder';
import { RequestSubscriptionButton } from './RequestSubscription';
import { roles } from '../../../../../constants';
import IconSVG from "../../../../../styles/svg-icons";
import { SubscriptionEndDate } from './SubscriptionEndDate';
import { isSubscriptionObsolete, SubscriptionType } from '../../../../../types/billing/SubscriptionType';

const productRoleRestrictions: { [product in AppProduct]?: string[] } = {
    [AppProduct.KWatch]: [...roles.seller(), ...roles.bd(), roles.SellerAdministrator, roles.Administrator],
    [AppProduct.KTalk]: [], // hidden
    [AppProduct.Trading]: [...roles.seller(), ...roles.bd(), roles.SellerAdministrator, roles.Administrator],
    [AppProduct.ManagerProfile]: [...roles.seller(), ...roles.bd(), roles.SellerAdministrator, roles.Administrator],
    [AppProduct.IssuanceMonitor]: [...roles.issuanceMonitorAccess(), roles.SellerAdministrator],
    [AppProduct.DealerProfile]: roles.getAllRolesExclude(roles.Viewer),
    [AppProduct.DealDocuments]: roles.getAllRolesExclude(roles.ArrangersClient)
}

export function Plans() {
    const { requestState, subscriptions } = useSubscriptions();
    const [limitedState, setLimitedState] = useState<{ product: AppProduct, subscription: Subscription }>();

    const currentSubscription = useMemo(() => {
        const currentUser = user.current();

        return subscriptions.find(s =>
            s.products.length === currentUser?.products.length &&
            !s.products.some(p => !currentUser?.products.some(pp => pp === p))
        );
    }, [subscriptions]);

    const columns: IColumnDefinition<AppProduct, { subscriptions: Subscription[] }>[] = useMemo(() => [{
        columnKey: "product",
        renderColumnHeaderContent: () => '',
        renderColumnContent: p => getAppProductTitle(p),
        className: "data-list-cell-xs-flexible text-bold"
    },
    ...subscriptions
    .filter(s => !isSubscriptionObsolete(s.title as SubscriptionType) || s.title === currentSubscription?.title)
    .map(s => ({
        columnKey: `subscription-${s.title}`,
        renderColumnHeaderContent: () => 
            <>
                <h3 className="title">{s.title}</h3>
                <SubscriptionEndDate isCurrent={s === currentSubscription} />
                <RequestSubscriptionButton subscription={s} isCurrent={s === currentSubscription} />
            </>,
        renderColumnContent: (p: AppProduct) =>
            <>
                {
                    s.products.some(pp => pp === p)
                        ? <IconSVG name="check" width={16} height={16} />
                        : <button
                            className="pseudo-link"
                            onClick={() =>
                                setLimitedState({
                                    product: p,
                                    subscription: s
                                })
                            }
                        >
                            Limited
                        </button>
                }
            </>,
        className: cn("data-list-cell-md-flexible text-center", { "selected": s === currentSubscription })
    }))], [currentSubscription, subscriptions]);

    return (
        <ContentSection>
            <Preloader inProgress={!isRequestSuccess(requestState)}>
                <Table
                    className="data-list-striped subscription-plans"
                    dataItems={values(AppProduct)
                        // Make sure the product is awailable for current user role
                        .filter(p =>
                            !productRoleRestrictions[p] ||
                            productRoleRestrictions[p]?.some(r => user.hasRoles(r)))
                    }
                    columns={columns.map(c => new ColumnBuilder(c))}
                    createRowCustomKey={(p: AppProduct) => p}
                />
                {limitedState &&
                    <LimitedFeaturesPopup
                        product={limitedState.product}
                        subscription={limitedState.subscription.title}
                        onClose={() => setLimitedState(undefined)}
                    />
                }
            </Preloader>
        </ContentSection>
    );
}