import * as React from 'react';
import { Link } from 'react-router-dom';
import { BlockedFeatureContext } from './BlockedFeatureContext';
import { DirectBiddingDisclaimerContext } from '../direct-bidding-agreement-disclaimer/DirectBiddingDisclaimerContext';
import { OnHoverTooltip } from '../common';
import IconSVG from "../../styles/svg-icons";

interface Props {
    path: string;
    className?: string;
    text?: string;
    disabled?: boolean;
    children?: React.ReactNode;
    target?: string;
    rel?: string;
    tooltip?: string;
    withLockIcon?: boolean;
    onClick?: () => void;
}

export function FeatureLink({ path, className = '', disabled = false, text = '', children = null, target, rel, tooltip, withLockIcon = false, onClick }: Props) {
    const isBlocked = React.useContext(BlockedFeatureContext);
    const isDirectBiddingDisclaimerBlocked = React.useContext(DirectBiddingDisclaimerContext);

    if (disabled || isBlocked) {
        return <button className={className || 'btn-link'} disabled>
            {!withLockIcon && <>{children} {text}</>}
            {withLockIcon && <IconSVG width="16" height="16" name="subscribe-lock-grey" />}
        </button>
    }

    if (isDirectBiddingDisclaimerBlocked) {
        return (
            <OnHoverTooltip overlay={tooltip}>
                <button className={className || 'btn-link secondary regular'}>{children} {text}</button>
            </OnHoverTooltip>
        );
    }

    return (
        <OnHoverTooltip overlay={tooltip}>
            <Link to={path} onClick={onClick} className={className} target={target} rel={rel}>
                {children} {text}
            </Link>
        </OnHoverTooltip>
    );
}
