import moment from "moment";
import { constants, SORT, SORTING_TYPE } from "../../../../../constants";
import { ComparableDeal } from "../../../../../types/amr-pipeline/models/ComparableDeal";
import { SubscriptionFeature } from "../../../../../types/billing/SubscriptionFeature";
import { Table } from "../../../../bidding/common/table";
import { IColumnDefinition } from "../../../../bidding/common/table/types/ColumnDefinition";
import { CloManagerLink } from "../../../../clo-managers/CloManagerLink";
import { OnHoverTooltip } from "../../../../common";
import { BankLink } from "../../../common/BankLink";
import { ImTransactionDetailsLink } from "../../../common/ImTransactionDetailsLink";
import { formatUtils } from "../../../../../utils";
import { ColumnBuilder } from "../../../../bidding/common/table/columns/column-builder/ColumnBuilder";
import { RequiredFeature } from "../../../../access/RequiredFeature";
import { TransactionAccessTypeCollecting, TransactionAnalyticCollectingActions } from "../../../../../types/amr-pipeline/enums/TransactionAccessType";
import { useAppDispatch } from "../../../../../effects/useAppDispatch";
import { amrPipelineDetailedActions } from "../../../../../actions";
import { SubscribeLink } from "../../../../access/BlockedFeatureText";
import { OriginatingTransaction } from "../../../../../types/amr-pipeline/models/OriginatingTransaction";
import { compareBoolean, compareStrings } from "../../../../../utils/compare.utils";

interface ComparableDealTableItem extends ComparableDeal {
    arrangerText?: string,
    isCurrentTransaction: boolean
}

const convertToTableItem = (t: ComparableDeal, isCurrentTransaction: boolean = false): ComparableDealTableItem => ({
    ...t,
    arrangerText: t.arrangerCode ?? t.arrangerLegalName,
    isCurrentTransaction
});

const convertCurrentTransactionToTableItem = (t: OriginatingTransaction): ComparableDealTableItem =>
    convertToTableItem({
        dealLegalName: t.dealLegalName,
        dealReferenceName: t.dealReferenceName,
        transactionReferenceName: t.referenceName,
        managerLegalName: t.collateralManager?.legalName ?? '',
        managerReferenceName: t.collateralManager?.referenceName ?? '',
        arrangerLegalName: t.arranger?.legalName ?? '',
        arrangerCode: t.arranger?.code,
        arrangerReferenceName: t.arranger?.referenceName ?? '',
        pricingDate: t.pricingDate?.toString(),
        was: t.was,
        wal: t.wal,
        warf: t.warf,
        ds: t.ds
    }, true)

const sortByCurrentThenByDealName = (a: ComparableDealTableItem, b: ComparableDealTableItem) =>
    compareBoolean(a.isCurrentTransaction, b.isCurrentTransaction) || compareStrings(a.dealLegalName, b.dealLegalName)

interface Props {
    currentTransaction: OriginatingTransaction
    deals: ComparableDeal[]
}

export function ComparableDealTable({ currentTransaction, deals }: Props) {
    const dispatch = useAppDispatch();

    const logUserActivity = (action: TransactionAccessTypeCollecting, dealReferenceName: string, transactionReferenceName: string) => {
        dispatch(amrPipelineDetailedActions.logTabAccess(dealReferenceName, transactionReferenceName, action));
    }

    const columns: IColumnDefinition<ComparableDealTableItem>[] = [{
        columnKey: 'thisTransactopnFlag',
        renderColumnHeaderContent: () => '',
        renderColumnContent: deal => deal.transactionReferenceName === currentTransaction.referenceName
            ? <b className="bwic-state-my">THIS</b> : null,
        className: 'data-list-cell-xxs-02',

    }, {
        columnKey: 'dealLegalName',
        renderColumnHeaderContent: () => 'Deal Name',
        renderColumnContent: deal =>
            <ImTransactionDetailsLink
                onClick={() =>
                    logUserActivity(
                        TransactionAnalyticCollectingActions.ComparableDealsDeal,
                        deal.dealReferenceName,
                        deal.transactionReferenceName,
                    )
                }
                dealReferenceName={deal.dealReferenceName}
                transactionReferenceName={deal.transactionReferenceName}
                requiredFeatures={[SubscriptionFeature.IssuanceMonitorFullAccess]}
            >
                <OnHoverTooltip overlay={deal.dealLegalName}>
                    {deal.dealLegalName}
                </OnHoverTooltip>
            </ImTransactionDetailsLink>,
        sortingField: 'dealLegalName',
        className: 'data-list-cell-md padding-l-0',
        sortingType: SORTING_TYPE.string,
    }, {
        columnKey: 'collateralManager',
        renderColumnHeaderContent: () => 'Manager',
        renderColumnContent: deal =>
            deal.managerReferenceName && deal.managerLegalName ? (
                <CloManagerLink
                    onClick={() =>
                        logUserActivity(
                            TransactionAnalyticCollectingActions.ComparableDealsManager,
                            deal.dealReferenceName,
                            deal.transactionReferenceName,
                        )
                    }
                    referenceName={deal.managerReferenceName}
                    legalName={deal.managerLegalName}
                />) : constants.emptyPlaceholder
        ,
        sortingField: "managerLegalName",
        sortingType: SORTING_TYPE.string,
        className: 'data-list-cell-lg-02',
    }, {
        columnKey: 'arranger',
        renderColumnHeaderContent: () => "Arranger",
        renderColumnContent: deal =>
            deal.arrangerReferenceName && deal.arrangerText && deal.arrangerLegalName ? (
                <BankLink
                    onClick={() => logUserActivity(
                        TransactionAnalyticCollectingActions.ComparableDealsArranger,
                        deal.dealReferenceName,
                        deal.transactionReferenceName,
                    )}
                    legalName={deal.arrangerText}
                    referenceName={deal.arrangerReferenceName}
                    overlayText={deal.arrangerLegalName}
                />
            ) : constants.emptyPlaceholder,
        sortingField: "arrangerText",
        sortingType: SORTING_TYPE.string
    }, {
        columnKey: "pricingDate",
        renderColumnHeaderContent: () => (
            <OnHoverTooltip overlay={"Date when the new margin is determined"}>
                <span>Pricing</span>
            </OnHoverTooltip>
        ),
        renderColumnContent: deal =>
            deal.pricingDate
                ? moment(deal.pricingDate).format(constants.dateFormat)
                : constants.emptyPlaceholder,
        className: "data-list-cell-sm",
        sortingField: "pricingDate",
        sortingType: SORTING_TYPE.date
    }, {
        columnKey: 'was',
        renderColumnHeaderContent: () => "WAS, %",
        renderColumnContent: deal => deal.was ? formatUtils.formatDecimal(deal.was, 2) : constants.emptyPlaceholder,
        sortingField: "was",
        sortingType: SORTING_TYPE.number,
        className: 'text-right',
    }, {
        columnKey: 'wal',
        renderColumnHeaderContent: () => "WAL",
        renderColumnContent: deal => deal.wal ? formatUtils.formatDecimal(deal.wal, 2) : constants.emptyPlaceholder,
        sortingField: "wal",
        sortingType: SORTING_TYPE.number,
        className: 'data-list-cell-xs text-right',
    }, {
        columnKey: 'warf',
        renderColumnHeaderContent: () => "WARF",
        renderColumnContent: deal => deal.warf
            ? deal.warf.toLocaleString(
                'en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }) : constants.emptyPlaceholder,
        sortingField: "warf",
        sortingType: SORTING_TYPE.number,
        className: 'text-right',
    }, {
        columnKey: 'ds',
        renderColumnHeaderContent: () => "DS",
        renderColumnContent: deal => deal.ds ? formatUtils.formatDecimal(deal.ds, 2) : constants.emptyPlaceholder,
        sortingField: "ds",
        sortingType: SORTING_TYPE.number,
        className: 'data-list-cell-xs text-right',
    }]

    return (
        <RequiredFeature
            feature={SubscriptionFeature.IssuanceMonitorFullAccess}
            inline
            className="empty-placeholder"
            text={
                <>
                    <SubscribeLink /> to see the Comparable Transactions.
                </>
            }
        >
            <Table
                className="data-list-striped"
                defaultSortBy="isCurrentTransaction"
                defaultSortByDirection={SORT.DESC}
                dataItems={deals
                    .map(d => convertToTableItem(d))
                    .concat(convertCurrentTransactionToTableItem(currentTransaction))
                    .sort(sortByCurrentThenByDealName)
                }
                columns={columns.map(c => new ColumnBuilder(c))}
            />
        </RequiredFeature>
    )
}
