import { useSelector } from 'react-redux';
import { groupDocuments } from '../Documents';
import { AppState } from '../../../../../types/state/AppState';
import { DealsState } from '../../../../../types/state/DealsState';
import { useMemo, useState } from 'react';
import { Formik, FormikErrors } from 'formik';
import { useAppDispatch } from '../../../../../effects/useAppDispatch';
import { AmrDocument } from '../../../../../types/amr-pipeline/models/AmrDocument';
import { dealsActions } from '../../../../../actions/deals.actions';
import { DealsSaveHeader } from '../../../DealsSaveHeader';
import { RouteLeavingGuard } from '../../../../routing';
import { useHistory } from 'react-router';
import { DealsTabType } from '../../../../../types/deals/DealsTabType';
import { cleansingNoticeDocsTypeList, dealRegularDocsTypeList, DocumentType, getDocumentTypeText } from '../../../../../types/amr-pipeline/enums/DocumentType';
import { constants, routes } from '../../../../../constants';
import { EditDocumentsSection } from './EditDocumentsSection';
import { has } from 'lodash';
import { AmrFile } from '../../../../../types/amr-pipeline/models/AmrFile';
import { Preloader } from '../../../../common';
import { dealsService } from '../../../../../services/deals.service';
import moment from 'moment';
import { DownloadTemplateButton } from '../../../../common/download-template/DownloadTemplateButton';

export interface DocumentsForm {
    dealDocuments: AmrDocument[];
    cleansingNotices: AmrDocument[];
    distributionReports: AmrDocument[];
    monthlyReports: AmrDocument[];
    reportsExcelFiles: AmrDocument[];
    otherDocuments: AmrDocument[];
}

export interface DocumentsFormWithPatterns extends DocumentsForm {
    monthlyReportsPattern: string;
    distributionReportsPattern: string;
}

export const EditDocuments = () => {
    const dispatch = useAppDispatch();
    const [isUploading, setIsUploading] = useState(false);
    const { selectedDeal, isUpdating } = useSelector<AppState, DealsState>(state => state.deals);
    const history = useHistory();

    const initialValues: DocumentsFormWithPatterns = useMemo(
        () => ({
            ...groupDocuments(selectedDeal?.documents?.dealDocuments, true),
            monthlyReportsPattern:
                selectedDeal?.documents?.dealDocumentPatterns?.find(p => p.documentType === DocumentType.monthlyReport)
                    ?.pattern || '',
            distributionReportsPattern:
                selectedDeal?.documents?.dealDocumentPatterns?.find(
                    p => p.documentType === DocumentType.distributionReport,
                )?.pattern || '',
        }),
        [selectedDeal],
    );

    if (isUpdating) {
        return <Preloader inProgress />;
    }

    if (!selectedDeal) {
        return null;
    }

    const { referenceName, legalName, ticker } = selectedDeal;

    const handleSubmit = (values: DocumentsFormWithPatterns) => {
        const documentsList = [
            ...values.dealDocuments,
            ...values.cleansingNotices,
            ...values.distributionReports,
            ...values.monthlyReports,
            ...values.reportsExcelFiles,
            ...values.otherDocuments,
        ];

        dispatch(
            dealsActions.updateDealDocuments(referenceName, {
                dealDocuments: documentsList,
                dealDocumentPatterns: [
                    { pattern: values.monthlyReportsPattern, documentType: DocumentType.monthlyReport },
                    { pattern: values.distributionReportsPattern, documentType: DocumentType.distributionReport },
                ],
            }),
        );
    };

    const handleUpload = (
        name: string,
        documentType: DocumentType,
        setFieldValue: (
            field: string,
            value: any,
            shouldValidate?: boolean | undefined,
        ) => Promise<void | FormikErrors<DocumentsForm>>,
        files: AmrFile[],
    ) => {
        setFieldValue(
            name,
            files.map(f => ({
                ...f,
                isPublic: has(f, 'isPublic') ? f.isPublic : true,
                documentType: f.documentType || documentType,
                executionDate: f.executionDate || moment().format(constants.formatTimeStringWithoutTimeZone),
            })),
        );
    };

    return (
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {({ values, setFieldValue, dirty, isSubmitting, submitForm }) => {
                return (
                    <div className="tabs-content container-flex ">
                        <DealsSaveHeader
                            title="Edit Documents"
                            subTitle={legalName}
                            hasChanges={dirty}
                            isUpdating={isUploading}
                            onSaveClick={submitForm}
                            onCancelClick={() => history.push(routes.dealsUrl(referenceName, DealsTabType.Documents))}
                        />
                        <div className="tabs-data tabs-data-padding tabs-data-documents-edit">
                            <RouteLeavingGuard
                                navigate={(pathname: string) => history.push(pathname)}
                                shouldBlockNavigation={() => !!dirty && !isSubmitting}
                            />
                            <EditDocumentsSection
                                documentKey="dealDocuments"
                                title="Deal Documents"
                                dealReferenceName={referenceName}
                                items={values.dealDocuments}
                                documentTypes={dealRegularDocsTypeList}
                                setUploadProgress={setIsUploading}
                                downloadAllFetch={dealsService.getDealRegularDocuments}
                                downloadAllArgs={[referenceName]}
                                fileNameForSave={`${ticker} Deal Documents.zip`}
                                onUpload={files =>
                                    handleUpload('dealDocuments', DocumentType.otherDealDocument, setFieldValue, files)
                                }
                            />
                            <EditDocumentsSection
                                documentKey="cleansingNotices"
                                title="Cleansing Notice"
                                dealReferenceName={referenceName}
                                items={values.cleansingNotices}
                                documentTypes={cleansingNoticeDocsTypeList}
                                setUploadProgress={setIsUploading}
                                downloadAllFetch={dealsService.getCleansingNoticeDocuments}
                                downloadAllArgs={[referenceName]}
                                fileNameForSave={`${ticker} Cleansing Notice.zip`}
                                onUpload={files =>
                                    handleUpload('cleansingNotices', DocumentType.cleansingNoticeRefinancing, setFieldValue, files)
                                }
                                renderAdditionalButton={() =>
                                    <DownloadTemplateButton
                                        templateType='CleansingNoticeXlsxTemplate'
                                        fileName='Cleansing Notice Template'
                                    />
                                }
                            />
                            <EditDocumentsSection
                                documentKey="distributionReports"
                                title="Distribution Reports"
                                dealReferenceName={referenceName}
                                patternName="distributionReportsPattern"
                                items={values.distributionReports}
                                setUploadProgress={setIsUploading}
                                downloadAllFetch={dealsService.getDealDocumentsByType}
                                downloadAllArgs={[referenceName, DocumentType.distributionReport]}
                                fileNameForSave={`${ticker} ${getDocumentTypeText(
                                    DocumentType.distributionReport,
                                )}.zip`}
                                onUpload={files =>
                                    handleUpload(
                                        'distributionReports',
                                        DocumentType.distributionReport,
                                        setFieldValue,
                                        files,
                                    )
                                }
                            />
                            <EditDocumentsSection
                                documentKey="monthlyReports"
                                title="Monthly Reports"
                                dealReferenceName={referenceName}
                                items={values.monthlyReports}
                                patternName="monthlyReportsPattern"
                                setUploadProgress={setIsUploading}
                                downloadAllFetch={dealsService.getDealDocumentsByType}
                                downloadAllArgs={[referenceName, DocumentType.monthlyReport]}
                                fileNameForSave={`${ticker} ${getDocumentTypeText(DocumentType.monthlyReport)}.zip`}
                                onUpload={files =>
                                    handleUpload('monthlyReports', DocumentType.monthlyReport, setFieldValue, files)
                                }
                            />
                            <EditDocumentsSection
                                documentKey="reportsExcelFiles"
                                title="Reports Excel files"
                                dealReferenceName={referenceName}
                                items={values.reportsExcelFiles}
                                setUploadProgress={setIsUploading}
                                downloadAllFetch={dealsService.getDealDocumentsByType}
                                downloadAllArgs={[referenceName, DocumentType.reportsExcelFiles]}
                                fileNameForSave={`${ticker} ${getDocumentTypeText(DocumentType.reportsExcelFiles)}.zip`}
                                onUpload={files =>
                                    handleUpload(
                                        'reportsExcelFiles',
                                        DocumentType.reportsExcelFiles,
                                        setFieldValue,
                                        files,
                                    )
                                }
                            />
                            <EditDocumentsSection
                                documentKey="otherDocuments"
                                title="Other Documents"
                                dealReferenceName={referenceName}
                                items={values.otherDocuments}
                                setUploadProgress={setIsUploading}
                                downloadAllFetch={dealsService.getDealDocumentsByType}
                                downloadAllArgs={[referenceName, DocumentType.otherDocument]}
                                fileNameForSave={`${ticker} ${getDocumentTypeText(DocumentType.otherDocument)}.zip`}
                                onUpload={files =>
                                    handleUpload('otherDocuments', DocumentType.otherDocument, setFieldValue, files)
                                }
                            />
                        </div>
                    </div>
                );
            }}
        </Formik>
    );
};
