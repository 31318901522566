import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { amrTransactionHistoryActions } from '../../../actions';
import { constants } from '../../../constants';
import { AppState } from '../../../types/state/AppState';
import { Preloader } from '../../common';
import { Popover } from '../../controls'
import { TransactionHistoryLink } from '../common/TransactionHistoryLink';
import { Transaction } from '../../../types/amr-pipeline/models/Transaction';
import { TransactionType } from '../../../types/amr-pipeline/enums/TransactionType';
import { TransactionStatus } from '../../../types/amr-pipeline/enums/TransactionStatus';
import { dateTimeUtils } from '../../../utils';

interface LastUpdatedPopoverProps {
    transaction: Transaction;
    classReferenceName?: string;
    field: 'guidance' | 'subscription';
    children: React.ReactElement;
    historyLinkTarget?: React.HTMLAttributeAnchorTarget;
}

export const LastUpdatedPopover = ({
    transaction,
    classReferenceName,
    field,
    children,
    historyLinkTarget,
}: LastUpdatedPopoverProps) => {
    const dispatch = useDispatch();
    const { isLoading, ...rest } = useSelector((state: AppState) =>
        state.issuanceMonitor.amrPipelineHistory.lastUpdatedFields
    );

    const {
        dealReferenceName,
        dealLegalName,
        referenceName,
        version,
        type,
        status,
        lastUpdated,
    } = transaction;
    const isClosed = status === TransactionStatus.Closed;
    const withHistory =
        type !== TransactionType.amr && status !== TransactionStatus.Draft;

    const handleOpen = () => {
        dispatch(amrTransactionHistoryActions.transactionFieldsUpdatedReset());
        dispatch(
            amrTransactionHistoryActions.transactionFieldsUpdatedRequest(
                dealReferenceName,
                referenceName,
                classReferenceName
            )
        );
    };

    const renderDate = () => {
        if (!rest && !rest[field] && !isClosed) {
            return constants.emptyPlaceholder;
        }

        const dateToDisplay = isClosed
            ? moment(lastUpdated).format(constants.dateFormat)
            : dateTimeUtils
                  .changeDateTimeZone(rest[field], constants.estTimezone, false)
                  .format(constants.dateTimeFormat) + " EST";

        return <time className="deal-updates-date">{dateToDisplay}</time>;
    };

    const renderContent = () => {
        if (isLoading) {
            return <Preloader small fullScreen={false} inProgress text="Loading..." />
        }

        return (
            <div>
                {renderDate()}
                {withHistory && (
                    <div className="transaction-history-link">
                        <TransactionHistoryLink
                            target={historyLinkTarget}
                            referenceName={referenceName}
                            dealReferenceName={dealReferenceName}
                            version={version}
                        />
                    </div>
                )}
            </div>
        );
    };

    return (
        <Popover
            title="Last Updated"
            className="popover-md popover-last-updated"
            onOpen={handleOpen}
            actionButton={children}
            label={<span className="name-tag">{dealLegalName}</span>}
        >
            {renderContent()}
        </Popover>
    );
}
