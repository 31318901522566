import { constants } from "../../../constants";
import { AmrTransaction } from "../../../types/amr-pipeline/models/AmrTransaction";
import { OriginatingTransaction } from "../../../types/amr-pipeline/models/OriginatingTransaction";
import { dateTimeUtils } from "../../../utils";
import IssuanceMonitorBreadcrumbs from '../IssuanceMonitorBreadcrumbs';
import { OnHoverTooltip } from "../../common";
import { TransactionStatus } from "../../../types/amr-pipeline/enums/TransactionStatus";
import moment from "moment";

interface HeaderProps {
    versionDateTime: Date;
    selectedTransaction: OriginatingTransaction | AmrTransaction;
}

const Header = ({ versionDateTime, selectedTransaction }: HeaderProps) => {
    const historyDate = selectedTransaction.status === TransactionStatus.Closed
        && selectedTransaction.closingDate
            ? moment(selectedTransaction.closingDate).format(constants.dateFormat)
            : dateTimeUtils.changeDateTimeZone(
                versionDateTime,
                constants.estTimezone,
                false
            ).format(constants.dateTimeFormat) + " EST";

    return (
        <div className="sub-header sub-header-new-issue">
            <div className="sub-header-row type01">
                <IssuanceMonitorBreadcrumbs
                    selectedTransaction={selectedTransaction}
                />
                <div className="flex-row">
                    <OnHoverTooltip overlay={selectedTransaction.dealLegalName} wrapperClass="tooltip-title">
                        <h1>{selectedTransaction.dealLegalName}</h1>
                    </OnHoverTooltip>
                </div>
                <div className="flex-row controls-bottom-row">
                    <div className="text-sm">
                        {historyDate}
                    </div>
                    <div className="flex-item-right">
                        <span className="history-status history-status-added">Added</span>
                        <span className="history-status history-status-updated">Updated</span>
                        <span className="history-status history-status-removed">Removed</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
