import moment from 'moment';
import { mapValues, sumBy, toPairs } from 'lodash';
import { getType } from "typesafe-actions";
import { put, takeLatest, call, all, select } from "redux-saga/effects";
import { amrTransactionAnalyticsActions, errorActions } from "../actions";
import { AnyAction } from "redux";
import { amrPipelineService } from "../services/amr-pipeline.service";
import { DocumentDownloadsStatistic } from "../types/amr-pipeline/models/DocumentDownloadsStatistic";
import {
    TransactionClientActivity,
    TransactionViewHistory,
    TransactionSession,
} from '../types/amr-pipeline/models/TransactionSession';
import { TransactionAccessType } from '../types/amr-pipeline/enums/TransactionAccessType';
import { compareDates } from '../utils/compare.utils';
import { TransactionVersion } from '../types/amr-pipeline/models/TransactionVersion';
import { AppState } from '../types/state/AppState';
import { transformToTreeSelect, withDate } from '../utils/analytics.utils';
import { groupDocsWithAccessType } from '../utils/analytics.utils';

const UserAccessTypes = [
    TransactionAccessType.Overview,
    TransactionAccessType.Structure,
    TransactionAccessType.TargetPortfolio,
    TransactionAccessType.Documents,
    TransactionAccessType.Iois,
    TransactionAccessType.ComparableDeals,
];

function groupByAccessCountAndVersion(sessions: TransactionSession[], versions: TransactionVersion[]) {
    return sessions
        .reduce((acc: TransactionClientActivity[], session: TransactionSession) => {
            const grouped = toPairs(session.tabsViews);
            const documents = groupDocsWithAccessType(session.documents);
            const versionDate = versions.find(v => v.version === session.version)?.dateTime;

            return [
                ...acc,
                ...grouped.map(([accessType, entries]) => ({
                    ...session,
                    accessType,
                    versionDate,
                    documents,
                    accessDateTime: moment(session.accessDateTime).startOf('day').toDate(),
                    numberOfAccess: entries.length,
                } as TransactionClientActivity))
            ];
        }, [])
        .sort((a, b) => compareDates(b.accessDateTime, a.accessDateTime));
}

function groupByViewsAndVersion(sessions: TransactionSession[], versions: TransactionVersion[]) {
    return sessions
        .reduce((acc: TransactionViewHistory[], session: TransactionSession) => {
            const versionDate = versions.find(v => v.version === session.version)?.dateTime;
            const views = mapValues(session.tabsViews, x => x.length);
            const documentsList = groupDocsWithAccessType(session.documents);

            return [
                ...acc,
                {
                    ...session,
                    versionDate,
                    views,
                    documentsList,
                } as TransactionViewHistory
            ];
        }, [])
        .sort((a, b) => compareDates(b.accessDateTime, a.accessDateTime));
}

function* watchInit(action: AnyAction) {
    try {
        const {
            dealReferenceName,
            transactionReferenceName,
        } = action.payload;

        const endDate = new Date();
        const startDate = new Date(endDate.getTime());
        startDate.setDate(endDate.getDate() - 1);

        let transactionViewHistory: TransactionSession[];
        let recentTransactionViewHistory: TransactionSession[];
        let documentDownloadsDto: DocumentDownloadsStatistic[];
        let daysInMarket: number;
        let versions: TransactionVersion[];

        [
            transactionViewHistory,
            recentTransactionViewHistory,
            documentDownloadsDto,
            daysInMarket,
            versions,
        ] = yield all([
            call(amrPipelineService.getTransactionViewHistory, dealReferenceName, transactionReferenceName),
            call(amrPipelineService.getTransactionViewHistory, dealReferenceName, transactionReferenceName, startDate, endDate),
            call(amrPipelineService.getDocumentsDownloads, dealReferenceName, transactionReferenceName),
            call(amrPipelineService.getDaysInTheMarket, dealReferenceName, transactionReferenceName),
            call(amrPipelineService.getTransactionVersions, dealReferenceName, transactionReferenceName),
        ]);

        const documentDownloads = withDate<DocumentDownloadsStatistic>(documentDownloadsDto)

        const totalDocumentsDownloads = sumBy(documentDownloads, (dt) => dt.numberOfDocuments);

        const recentTransactionViews = recentTransactionViewHistory.length;

        const totalTransactionViews = transactionViewHistory.length;

        const clientsActivity = groupByAccessCountAndVersion(transactionViewHistory, versions);

        const users = transformToTreeSelect(clientsActivity, UserAccessTypes);

        yield put(
            amrTransactionAnalyticsActions.analyticsInitResponse({
                recentTransactionViews,
                totalTransactionViews,
                totalDocumentsDownloads,
                documentDownloads,
                daysInMarket,
                versions,
                clientsActivity,
                users
            })
        );
    } catch (e) {
        yield put(errorActions.criticalError(e));
    }
}

function* watchClientsActivityRequest(action: AnyAction) {
    try {
        const versions: TransactionVersion[] = yield select(
            (state: AppState) => state.issuanceMonitor.amrPipelineDetailed.analytics.versions
        );

        const { dealReferenceName, transactionReferenceName, startDate, endDate } = action.payload;

        const transactionSessions: TransactionSession[] = yield call(
            amrPipelineService.getTransactionViewHistory,
            dealReferenceName,
            transactionReferenceName,
            startDate ? moment(startDate).startOf('day').toDate() : undefined,
            endDate ? moment(endDate).endOf('day').toDate() : undefined,
        );

        const clientsActivity = groupByAccessCountAndVersion(transactionSessions, versions)
            .filter(x => UserAccessTypes.includes(x.accessType as TransactionAccessType));

        yield put(amrTransactionAnalyticsActions.clientsActivityResponse(clientsActivity));
    } catch (e) {
        yield put(errorActions.criticalError(e));
    }
}

function* watchTransactionHistoryActivityRequest(action: AnyAction) {
    try {
        const versions: TransactionVersion[] = yield select((s: AppState) => s.issuanceMonitor.amrPipelineDetailed.analytics.versions);

        const { dealReferenceName, transactionReferenceName, startDate, endDate } = action.payload;

        const alignedStartDate = startDate ? moment(startDate).startOf('day').toDate() : undefined;
        const alignedEndDate = endDate ? moment(endDate).endOf('day').toDate() : undefined;

        const transactionSessions: TransactionSession[] = yield call(
            amrPipelineService.getTransactionViewHistory,
            dealReferenceName,
            transactionReferenceName,
            alignedStartDate,
            alignedEndDate
        );

        const transactionHistoryActivity = groupByViewsAndVersion(transactionSessions, versions);

        yield put(amrTransactionAnalyticsActions.transactionViewHistoryResponse(transactionHistoryActivity));
    } catch (e) {
        yield put(errorActions.criticalError(e));
    }
}

function* watchTransactionHistoryActivityFiltrByBar(action: AnyAction) {
    const { dealReferenceName, transactionReferenceName, date } = action.payload;

    yield put(
        amrTransactionAnalyticsActions.transactionViewHistoryRequest(
            dealReferenceName,
            transactionReferenceName,
            date,
            date
        )
    );
}

export function* watchAmrTransactionAnalytics() {
    yield takeLatest(getType(amrTransactionAnalyticsActions.analyticsInit), watchInit);
    yield takeLatest(getType(amrTransactionAnalyticsActions.clientsActivityRequest), watchClientsActivityRequest);
    yield takeLatest(getType(amrTransactionAnalyticsActions.transactionViewHistoryRequest), watchTransactionHistoryActivityRequest);
    yield takeLatest(getType(amrTransactionAnalyticsActions.transactionViewHistoryFilterByBar), watchTransactionHistoryActivityFiltrByBar);
}
