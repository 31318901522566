import React, { useRef, useState } from "react";
import { LazyLoad } from "../common/LazyLoad";
import { MarketTrendsWebinarsContent } from "./market-trends-webinar/MarketTrendsWebinarsContent";
import { SectionContent } from "../amrPipeline/common/section/SectionContent";
import { ResourceSectionContent } from "./ResourceSectionContent";
import {
    marketMonitoringLinks,
    primaryIssuanceLinks,
    secondaryMarketLinks,
    sectionsId,
} from "./constants";

import { Webinar } from "../../types/marketing/Webinar";
import {
    HelpResourceCategory,
    HelpResourceSection,
} from "../../types/help-resources/HelpResourceCategory";
import { HelpResourceArticle } from "../../types/help-resources/HelpResourceArticle";
import classNames from "classnames";
import IconSVG from "../../styles/svg-icons";

const isElementFullyVisible = (rect: DOMRect, topMargin: number = 0) => {
    const { top, bottom } = rect;
    return top >= topMargin && bottom <= window.innerHeight;
};

const isElementVisible = (rect: DOMRect, topMargin: number = 0) => {
    const { top, bottom } = rect;
    return (
        isElementFullyVisible(rect, topMargin) || // The top and the bottom are visible
        (top < topMargin && bottom > window.innerHeight) || // The top and the bottom are outside the screen, but the middle content is visible
        (top >= topMargin && top < window.innerHeight && bottom > window.innerHeight) || // The top is visible and the bottom is outside
        (top < topMargin && bottom < window.innerHeight && bottom > topMargin) // The top is outside and the bottom is visible
    );
};

const getElementHeight = (rect: DOMRect) => {
    const { top, bottom } = rect;

    return Math.min(bottom, window.innerHeight) - Math.max(0, top);
};

interface Props {
    category?: HelpResourceCategory;
    webinar?: Webinar;
    webinarSections: HelpResourceSection[];
    webinarArticles: HelpResourceArticle[];
}

export const ResourceContent = ({
    category,
    webinar,
    webinarSections,
    webinarArticles,
}: Props) => {
    const sectionsWrapperRef = useRef<HTMLDivElement | null>(null);
    const sectionsRef = useRef<(HTMLElement | null)[]>([]);
    const fullyVisibleSecIdRef = useRef<string[]>([]);
    const [visibleSectionId, setVisibleSectionId] = useState(
        sectionsId.gettingStarted
    );
    const [visibleWebinarMonth, setVisibleWebinarMonth] = useState<
        string | null
    >(null);

    const sectionsList = [
        {
            title: "Getting Started",
            id: sectionsId.gettingStarted,
            renderSection: () => (
                <SectionContent title="Getting Started">
                    <ResourceSectionContent
                        title="Setup portfolio"
                        text="KopenTech Portfolio lets you monitor securities in real-time, review trading history, and export data to Excel. You'll receive notifications when securities are up for sale on BWIC or in dealer inventory. Our API enables seamless integration with your systems, eliminating manual updates. Make data-driven decisions in seconds."
                        videoUrl="https://www.youtube.com/watch?v=FQX8fA4ehvY"
                    />
                    <ResourceSectionContent
                        title="Invite team members"
                        text="Invite your colleagues and clients to join the KTX platform and experience the power of seamless collaboration. KopenTech enables real-time team updates, ensuring everyone stays informed and aligned, whether you’re analyzing deals or managing workflows."
                        videoUrl="https://www.youtube.com/watch?v=LfKQMbDmei4"
                    />
                </SectionContent>
            ),
        },
        {
            title: "Webinars",
            id: sectionsId.webinars,
            renderSection: () => (
                <SectionContent title="Webinars">
                    <MarketTrendsWebinarsContent
                        sections={webinarSections}
                        articles={webinarArticles}
                        monthInScroll={visibleWebinarMonth}
                        scrollToStart={scrollToWebinar}
                    />
                </SectionContent>
            ),
        },
        {
            title: "Market Monitoring",
            id: sectionsId.marketMonitoring,
            renderSection: () => (
                <SectionContent title="Market Monitoring">
                    <ResourceSectionContent
                        title="Dashboard"
                        text="A complete guide to CLO market monitoring will help you be alerted to market health indicators and market surveillance tools."
                        links={marketMonitoringLinks.dashboard}
                        videoUrl="https://www.youtube.com/watch?v=NwPMfhmS7nA"
                    />
                    <ResourceSectionContent
                        title="Portfolio"
                        text="Favorite manager new issue alert, holdings being refinanced, reset, or showing up on dealer inventory - we help you stay in the know. Connect to your OMS with a free API so you never miss a bit."
                        links={marketMonitoringLinks.portfolio}
                        videoUrl="https://www.youtube.com/watch?v=exnJ9w-XD78"
                    />
                    <ResourceSectionContent
                        title="News & Webinar"
                        text="Stay updated with daily news on financial markets, including significant movements, new issuances, and CLO AUM growth. Join our monthly CLO market trends webinars for exclusive insights from industry experts."
                        links={marketMonitoringLinks.newsAndWebinar}
                        videoUrl="https://www.youtube.com/watch?v=nWEXvsVa1js"
                    />
                </SectionContent>
            ),
        },
        {
            title: "Primary Issuance",
            id: sectionsId.primaryIssuance,
            renderSection: () => (
                <SectionContent title="Primary Issuance">
                    <ResourceSectionContent
                        title="Issuance Monitor"
                        text="New deals are posted daily, and there are total unpriced deals across the US and Europe, BSL vs. Primate credit split. Follow the deals' progress during syndicate and be notified of important updates. Download deal documents—all in one place."
                        links={primaryIssuanceLinks.issuanceMonitor}
                        videoUrl="https://www.youtube.com/watch?v=Tvb-HHBAh5Y"
                    />
                    <ResourceSectionContent
                        title="Arranger & Manager Profiles"
                        text="Access real-time data on Broker-Dealers and CLO managers in a standardized format. Our reports provide valuable insights into market-making capabilities, key personnel, managed CLOs, and ESG frameworks, enabling easy evaluation and comparison across the industry."
                        links={primaryIssuanceLinks.arrangerAndManagerProfile}
                        videoUrl="https://www.youtube.com/watch?v=MB088qm2iSA"
                    />
                </SectionContent>
            ),
        },
        {
            title: "Secondary Market",
            id: sectionsId.secondaryMarket,
            renderSection: () => (
                <SectionContent title="Secondary Market">
                    <ResourceSectionContent
                        title="KTX™ Electronic Trading"
                        text="Speed up your trading and minimize the risk of manual errors with e-trading. Seamlessly execute and access peer-to-peer trading through a centralized exchange."
                        links={secondaryMarketLinks.ktxElectronicTrading}
                        videoUrl="https://www.youtube.com/watch?v=gph-W72a2s0"
                    />
                    <ResourceSectionContent
                        title="BWIC Monitor & Dealer Inventory"
                        text="Access all scheduled, active, and completed BWICs in one place, with real-time updates on dealer price talk and post-BWIC color. Effortlessly search consolidated multi-dealer CLO Inventory, streamlining your decision-making process with filters and alerts."
                        links={secondaryMarketLinks.bwicAndDealerInventory}
                        videoUrl="https://www.youtube.com/watch?v=I_vs-PG1Vms"
                    />
                    <ResourceSectionContent
                        title="AMR Refinancing"
                        text="A new, fast, and cost-efficient way to refinance CLO securities through an online auction with multiple broker-dealers. Reduce the cost and time of CLO refinancing by 4x."
                        links={secondaryMarketLinks.amrRefinancing}
                        videoUrl="https://www.youtube.com/watch?v=gy6SN4o_hco"
                    />
                </SectionContent>
            ),
        },
    ];

    const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
        let maxHeight = 0;
        let elementId = visibleSectionId;
        const fullyVisibleElements = [];
        const hash = window.location.hash.substring(
            1,
            window.location.hash.length
        );
        const topMargin =
            sectionsWrapperRef.current?.getBoundingClientRect().top;

        for (let i = 0; i < sectionsRef.current.length; i++) {
            const section = sectionsRef.current[i];
            const sectionId = section?.getAttribute("id");

            if (!section || !sectionId) {
                return;
            }
            const rect = section.getBoundingClientRect();

            if (isElementFullyVisible(rect, topMargin)) {
                fullyVisibleElements.push(sectionId);
            }

            if (isElementVisible(rect, topMargin)) {
                const elementHeight = getElementHeight(rect);
                if (elementHeight > maxHeight) {
                    maxHeight = elementHeight;
                    elementId = sectionId;
                }

                if (sectionId === sectionsId.webinars) {
                    const webinarSections = section.querySelectorAll(
                        ".webinars-list .resources-item"
                    );
                    const monthNavigation = section.querySelector(
                        ".webinar-navigation-months"
                    );
                    const webinarMarginTop =
                        monthNavigation?.getBoundingClientRect().bottom;

                    for (let j = 0; j < webinarSections.length; j++) {
                        const webinarElement = webinarSections[j];
                        if (
                            isElementVisible(
                                webinarElement.getBoundingClientRect(),
                                webinarMarginTop
                            )
                        ) {
                            setVisibleWebinarMonth(
                                webinarElement.getAttribute("id")
                            );
                            break;
                        }
                    }
                }
            }
        }

        if (!fullyVisibleElements.length) {
            fullyVisibleSecIdRef.current = [];
            return setVisibleSectionId(elementId);
        }

        const hashedElementId = fullyVisibleElements.find((id) => id === hash);

        fullyVisibleSecIdRef.current = fullyVisibleElements;
        setVisibleSectionId(hashedElementId || fullyVisibleElements[0]);
    };

    const scrollToWebinar = () => {
        const sectionToScroll = sectionsRef.current.find(
            (s) => s?.id === sectionsId.webinars
        );
        if (!sectionToScroll) {
            return null;
        }

        sectionToScroll.scrollIntoView({
            behavior: "smooth",
            block: "start",
        });
    };

    const handleNavClick = (id: string) =>
        fullyVisibleSecIdRef.current.includes(id)
            ? setVisibleSectionId(id)
            : undefined;

    return (
        <>
            <LazyLoad className="resources-header" enabledUnloading={true}>
                <div className="resources-header-content">
                    <div className="resources-header-title">
                        Resources
                        <br />
                        Monthly Webinars, Products, Brochures and Getting Started Videos
                    </div>
                    {!!webinar && (
                        <div className="resources-header-register">
                            <a className="btn btn-main" href={webinar.link}>
                                <IconSVG
                                    name="calendar"
                                    width={16}
                                    height={16}
                                />
                                Register for the webinar
                            </a>
                            <div className="text-xl text-medium">
                                {webinar.title}
                            </div>
                            {webinar.description}
                        </div>
                    )}
                </div>
            </LazyLoad>
            <div className="resources-content">
                <nav className="resources-navigation">
                    <h2 className="resources-navigation-title">
                    Sections in Resources
                    </h2>
                    <ul className="resources-navigation-list">
                        {sectionsList.map(({ title, id }) => (
                            <li
                                key={id}
                                className={classNames({
                                    active: id === visibleSectionId,
                                })}
                                onClick={() => handleNavClick(id)}
                            >
                                <a href={"#" + id}>{title}</a>
                            </li>
                        ))}
                    </ul>
                </nav>
                <div
                    className="resources-sections"
                    ref={sectionsWrapperRef}
                    onScroll={handleScroll}
                >
                    {sectionsList.map(({ id, renderSection }, index) => (
                        <section
                            className="resources-section"
                            key={id}
                            id={id}
                            ref={(ref) => (sectionsRef.current[index] = ref)}
                        >
                            {renderSection()}
                        </section>
                    ))}
                </div>
            </div>
        </>
    );
};

