import { AmrDocument } from "../../../../types/amr-pipeline/models/AmrDocument";
import { Table } from "../../../bidding/common/table";
import { EmptySectionContent } from "../../../amrPipeline/common/section/EmptySectionContent";
import { SORT, SORTING_TYPE, constants, roles } from "../../../../constants";
import { user } from "../../../../user";
import { IColumnDefinition } from "../../../bidding/common/table/types/ColumnDefinition";
import { ColumnBuilder } from "../../../bidding/common/table/columns/column-builder/ColumnBuilder";
import IconSVG from "../../../../styles/svg-icons";
import { Checkbox, FileIcon } from "../../../controls";
import { DocumentTypeSelect } from "../../../../types/amr-pipeline/enums/DocumentType";
import moment from "moment";
import DownloadDocument from "../../../amrPipeline/common/documents/DownloadDocument";
import { OnHoverTooltip } from '../../../common';
import useDownloadDocumentList from "../../../../effects/useDownloadDocumentList";
import { dealsService } from "../../../../services/deals.service";

export type DocumentColumn = IColumnDefinition<AmrDocument>;

export enum DocumentColumnDate {
    executionDate = "executionDate",
    uploadTime = "uploadTime",
}

interface Props {
    dealReferenceName: string;
    items: AmrDocument[];
    documentTypes?: DocumentTypeSelect[];
    withPublic?: boolean;
    documentColumnDate?: DocumentColumnDate;
    onClick?: (document: AmrDocument) => void;
}

export function DocumentsList({
    dealReferenceName,
    items,
    documentTypes = [],
    withPublic = false,
    documentColumnDate = DocumentColumnDate.executionDate,
    onClick
}: Props) {
    const { loadingState, loadHandler } = useDownloadDocumentList({
        documents: items,
        downloadRequest: (referenceName: string) =>
            dealsService.getDealDocument(dealReferenceName, referenceName),
    });

    const isAdminOrDataEntry = user.hasRoles(
        roles.Administrator,
        roles.DataEntry
    );

    function onRowClick(document: AmrDocument) {
        if (onClick) {
            onClick(document);
            return;
        }

        loadHandler(document.referenceName, document.name);
    }

    const getColumns = () => {
        const documentsColumnDefinitions: DocumentColumn[] = [
            {
                columnKey: "name",
                renderColumnHeaderContent: () => "Name",
                headerClassName: "data-list-cell-xl-flexible",
                bodyClassName: "data-list-cell-xl-flexible",
                renderColumnContent: (document) => (
                    <DownloadDocument
                        document={document}
                        onClick={() => onRowClick(document)}
                    >
                        <FileIcon filename={document.name} />
                        {document.name}
                    </DownloadDocument>
                ),
                sortingField: "name",
                sortingType: SORTING_TYPE.string,
            },
            ...(documentTypes.length
                ? [
                    {
                        columnKey: "documentType",
                        renderColumnHeaderContent: () => "Type",
                        headerClassName: "data-list-cell-lg-02",
                        bodyClassName: "data-list-cell-lg-02",
                        renderColumnContent: ({ documentType }) => {
                            const typeLabel = documentTypes.find(dt=> dt.type === documentType)?.text;
                            return (
                                <OnHoverTooltip overlay={typeLabel}>
                                    {typeLabel}
                                </OnHoverTooltip>
                            );
                        },
                        sortingField: "documentType",
                        sortingType: SORTING_TYPE.string,
                    } as DocumentColumn,
                ]
                : []),
            {
                columnKey: documentColumnDate === DocumentColumnDate.executionDate ? 'executionDate' : 'uploadTime',
                renderColumnHeaderContent: () => 'Date',
                headerClassName: 'data-list-cell-lg',
                bodyClassName: 'data-list-cell-lg',
                renderColumnContent: document =>
                    documentColumnDate === DocumentColumnDate.executionDate
                        ? moment(document.executionDate).format(constants.dateFormat)
                        : moment(document.uploadTime).format(constants.dateFormat),
                sortingField: documentColumnDate === DocumentColumnDate.executionDate ? 'executionDate' : 'uploadTime',
                sortingType: SORTING_TYPE.date,
            },
            ...(isAdminOrDataEntry && withPublic
                ? [
                    {
                        columnKey: "isPublic",
                        renderColumnHeaderContent: () => "Public",
                        headerClassName: "data-list-cell-xs",
                        bodyClassName: "data-list-cell-xs",
                        renderColumnContent: (document) => (
                            <Checkbox disabled checked={document.isPublic} />
                        ),
                    } as DocumentColumn,
                ]
                : []),
            {
                columnKey: "downloadButton",
                renderColumnHeaderContent: () => "",
                headerClassName: "data-list-cell-xs",
                bodyClassName: "data-list-cell-xs",
                renderColumnContent: (document, { isLoading }) => (
                    <DownloadDocument
                        document={document}
                        onClick={() => onRowClick(document)}
                        isLoading={isLoading}
                    >
                        <IconSVG
                            name="downloadTemplate"
                            width={16}
                            height={16}
                        />
                    </DownloadDocument>
                ),
            },
        ];

        return documentsColumnDefinitions.map((c) => {
            return new ColumnBuilder(c);
        });
    };

    return (
        <div className="data-item-documents">
            {items.length ? (
                <Table
                    className="component-file-upload-list data-list-striped"
                    dataItems={items}
                    onRowClick={onRowClick}
                    columns={getColumns()}
                    createSecurityCustomArgs={(item: AmrDocument) => ({
                        isLoading: loadingState[item.referenceName],
                    })}
                    defaultSortBy="executionDate"
                    defaultSortByDirection={SORT.DESC}
                />
            ) : (
                <EmptySectionContent text="There are no documents yet" />
            )}
        </div>
    );
}
