import classNames from 'classnames';
import { user } from '../../../../../user';
import { IColumnDefinition } from "../../../../bidding/common/table/types/ColumnDefinition";
import { ColumnBuilder } from "../../../../bidding/common/table/columns/column-builder/ColumnBuilder";
import { constants, routes, roles } from "../../../../../constants";
import { formatUtils, moneyUtils, numericUtils } from "../../../../../utils";
import StatusLabel from "../../../StatusLabel";
import { AmrClass } from "../../../../../types/amr-pipeline/models/AmrClass";
import { NonAmrPeriod } from "../../../common/NonAmrPeriod";
import { OnHoverTooltip } from "../../../../common";
import { amrTrancheStatusTitles } from "../../../../../types/amr-pipeline/enums/AmrTrancheStatus";
import { Link } from "react-router-dom";
import { BloombergLink } from "../../../../common/BloombergLink";
import { ValitanaLink } from "../../../../common/ValitanaLink";
import { BwicMonitorLink } from '../../../common/BwicMonitorLink';
import { BwicInventoryLink } from '../../../common/BwicInventoryLink';
import { AddToPortfolioLink } from '../../../common/AddToPortfolioLink';

export const amrColumns = () => {
    const isAdminOrDataEntryOrMedia = user.hasRoles(roles.Administrator, roles.DataEntry, roles.Media);

    const statsColumnDefinitions: IColumnDefinition<AmrClass>[] = [
        {
            columnKey: "auctionState",
            renderColumnHeaderContent: () => "Auction State",
            renderColumnContent: (transactionClass) => (
                <StatusLabel status={amrTrancheStatusTitles[transactionClass.status]} />
            ),
            headerClassName: "data-list-cell-lg",
            bodyClassName: "data-list-cell-lg cell-amr-status",
        },
        {
            columnKey: "className",
            renderColumnHeaderContent: () => "Class",
            renderColumnContent: (transactionClass) => (
                <OnHoverTooltip overlay={transactionClass.name}>
                    <Link
                        target="_blank"
                        className="text-ellipsis"
                        to={routes.transactionClassUrl(
                            transactionClass.transactionReferenceName,
                            transactionClass.dealReferenceName,
                            transactionClass.referenceName
                        )}
                    >
                        {transactionClass.name}
                    </Link>
                </OnHoverTooltip>
            ),
        },
        {
            columnKey: "rating",
            renderColumnHeaderContent: () => "Rtg (M/S/F/K/D)",
            renderColumnContent: (transactionClass) =>
                `${transactionClass.ratingMoodys || constants.emptyPlaceholder
                }/${transactionClass.ratingSnP || constants.emptyPlaceholder
                }/${transactionClass.ratingFitch || constants.emptyPlaceholder
                }/${transactionClass.ratingKbra || constants.emptyPlaceholder
                }/${transactionClass.ratingDbrs || constants.emptyPlaceholder
                }`,
            className: "data-list-cell-xl",
        },
        {
            columnKey: "balance",
            renderColumnHeaderContent: () => "Balance",
            renderColumnContent: (transactionClass) =>
                transactionClass.balance != null && numericUtils.isNumber(transactionClass.balance)
                    ? moneyUtils.money(transactionClass.balance)
                    : constants.emptyPlaceholder,
            className: "data-list-cell-sm text-right",
        },
        {
            columnKey: "margin",
            renderColumnHeaderContent: () => "Margin, %",
            renderColumnContent: (transactionClass) =>
                transactionClass.margin
                    ? formatUtils.formatMargin(transactionClass.margin)
                    : constants.emptyPlaceholder,
            className: "text-right",
        },
        {
            columnKey: "factor",
            renderColumnHeaderContent: () => "Factor",
            renderColumnContent: (transactionClass) =>
                formatUtils.formatFactor(transactionClass.factor) ||
                constants.emptyPlaceholder,
            className: "data-list-cell-xs text-right",
        },
        {
            columnKey: "capMargin",
            renderColumnHeaderContent: () => (
                <OnHoverTooltip overlay={"Maximum margin which was accepted for the auction"} placement="bottomRight">
                    <span>Cap Margin, %</span>
                </OnHoverTooltip>
            ),
            renderColumnContent: (transactionClass) =>
                transactionClass.capMargin
                    ? formatUtils.formatMargin(transactionClass.capMargin)
                    : constants.emptyPlaceholder,
            className: "data-list-cell-sm text-right",
        },
        {
            columnKey: "nonAmr",
            renderColumnHeaderContent: () => (
                <OnHoverTooltip overlay={"Number of months before another AMR can be started"} placement="bottomRight">
                    <span>Non-AMR</span>
                </OnHoverTooltip>
            ),
            renderColumnContent: (transactionClass) => (
                <NonAmrPeriod period={transactionClass.nonAmrPeriod} />
            ),
        },
        {
            columnKey: "ticker",
            renderColumnHeaderContent: () => "Ticker (144A)",
            renderColumnContent: (transactionClass) =>
                    transactionClass.class.ticker144A || constants.emptyPlaceholder,
            className: "data-list-cell-lg",
        },
        {
            columnKey: "cusip",
            renderColumnHeaderContent: () => "CUSIP (144A)",
            renderColumnContent: (transactionClass) =>
                transactionClass.class?.cusip144A || constants.emptyPlaceholder,
            className: "data-list-cell-md",
        },
        {
            columnKey: "isin",
            renderColumnHeaderContent: () => "ISIN (144A)",
            renderColumnContent: (transactionClass) =>
                transactionClass.class?.isin144A || constants.emptyPlaceholder,
            className: "data-list-cell-md",
        },
        {
            columnKey: "tickerRegS",
            renderColumnHeaderContent: () => "Ticker (Reg S)",
            renderColumnContent: (transactionClass) =>
                transactionClass.class?.tickerRegS || constants.emptyPlaceholder,
            className: "data-list-cell-lg",
        },
        {
            columnKey: "cusipRegS",
            renderColumnHeaderContent: () => "CUSIP (Reg S)",
            renderColumnContent: (transactionClass) =>
                transactionClass.class?.cusipRegS || constants.emptyPlaceholder,
            className: "data-list-cell-md",
        },
        {
            columnKey: "isinRegS",
            renderColumnHeaderContent: () => "ISIN (Reg S)",
            renderColumnContent: (transactionClass) =>
                transactionClass.class?.isinRegS || constants.emptyPlaceholder,
            className: "data-list-cell-md",
        },
        {
            columnKey: "tickerAccdInvCertif",
            renderColumnHeaderContent: () => "Ticker (Acc’d Inv./Certif.)",
            renderColumnContent: (transactionClass) =>
                transactionClass.class?.tickerAccdInvCertif || constants.emptyPlaceholder,
            className: "data-list-cell-lg-02",
        },
        {
            columnKey: "cusipAccdInvestor",
            renderColumnHeaderContent: () => "CUSIP (Acc’d Inv./Certif.)",
            renderColumnContent: (transactionClass) =>
                transactionClass.class?.cusipAccdInvCertif || constants.emptyPlaceholder,
            className: "data-list-cell-lg-02",
        },
        {
            columnKey: "isinAccdInvestor",
            renderColumnHeaderContent: () => "ISIN (Acc’d Inv./Certif.)",
            renderColumnContent: (transactionClass) =>
                transactionClass.class?.isinAccdInvCertif || constants.emptyPlaceholder,
            className: "data-list-cell-lg-02",
        },
    ];

    const actionsColumn: IColumnDefinition<AmrClass>[] = [
        {
            columnKey: 'emptyCol',
            renderColumnHeaderContent: () => '',
            renderColumnContent: () => '',
            className: 'data-list-cell-xxxs',
            stickRight: true,
        },
        {
            columnKey: 'actions',
            renderColumnHeaderContent: () => '',
            renderColumnContent: (transactionClass, context) => (
                <div className="action-row">
                    <div className="action-col">
                        <BwicMonitorLink
                            currency={context.transaction.currency}
                            identifiers={transactionClass.class}
                            secondaryPlaceholderColor
                        />
                    </div>
                    <div className="action-col">
                        <BwicInventoryLink
                            currency={context.transaction.currency}
                            identifiers={transactionClass.class}
                            secondaryPlaceholderColor
                        />
                    </div>
                    <div className="action-col" onClick={e => e.stopPropagation()}>
                        <AddToPortfolioLink
                            transaction={context.transaction}
                            transactionClass={transactionClass}
                            checkSubscription
                        />
                    </div>
                    <div className="action-col" onClick={e => e.stopPropagation()}>
                        <BloombergLink
                            placeholderIfEmpty
                            secondaryPlaceholderColor
                            identifiers={transactionClass.class}
                        />
                    </div>
                    <div className="action-col">
                        <ValitanaLink
                            placeholderIfEmpty
                            secondaryPlaceholderColor
                            identifiers={transactionClass.class}
                        />
                    </div>
                </div>
            ),
            className: classNames(
                {
                    'data-list-cell-lg-02': isAdminOrDataEntryOrMedia,
                    'data-list-cell-md-flexible': !isAdminOrDataEntryOrMedia,
                },
                'data-list-cell-bloomberg-menu data-list-cell-actions overflow-visible text-right',
            ),
            stickRight: true,
        },
    ];

    const middleColumns = statsColumnDefinitions.map(c => {
        return new ColumnBuilder(c);
    });

    const rightColumns = actionsColumn.map(c => {
        return new ColumnBuilder(c);
    });

    return [[], middleColumns, rightColumns];
};
