import moment from 'moment';
import qs from 'query-string';
import { fetchSafe } from './fetch.safe';
import { urlBuilder } from './url.builder';
import { constants, responseType } from '../constants';
import { DownloadedDocumentAccessType } from '../types/amr-pipeline/enums/DownloadedDocumentAccessType';
import { fileUpload } from './file.upload';

export const amrPipelineService = {
    getTransactionsList,
    getTransactionClasses,
    getTransactionClassesNames,
    getTransaction,
    getTransactionHistory,
    getLatestTransactions,
    getLatestTransactionsByTicker,
    getPortfolio,
    getDocuments,
    getCollateralQualityTest,
    getAmrDocuments,
    registerUser,
    getIntexFile,
    getManagerPresentationFile,
    getDisclosureFile,
    getTransactionDocument,
    getTransactionDocuments,
    exportPortfolio,
    exportTransactionsClasses,
    getCurrency,
    getSyndicateContacts,
    getSyndicateContactsByArranger,
    getIOIs,
    submitIOIs,
    readIOIsByUser,
    exportIOIs,
    getTransactionClassesSummary,
    getTransactionsSummary,
    downloadIntexFile,
    downloadManagerPresentationFile,
    downloadDisclosureFile,
    logTabAccess,
    getDocumentsDownloads,
    getDaysInTheMarket,
    getTransactionViewHistory,
    getTransactionVersions,
    downloadStatisticDocument,
    unblockUserRequest,
    blockUserRequest,
    saveUser,
    shareTransactionToClients,
    logImUserActivity,
    getSharedClients,
    uploadDocument,
    downloadDocument,
    requestDemo,
    updateArrangerClient,
    logAccessToSharedTransaction,
    getBankTransactions,
    getDealComparableMetrics
};

function registerUser(user, company) {
    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/accounts`, {
        method: 'POST',
        body: JSON.stringify({
            userEmail: user.current().email,
            firstName: user.current().firstName,
            lastName: user.current().lastName,
            phoneNumber: user.current().publicPhone,
            companyLegalName: company.name,
            companyCountryThreeCharsCode: company.countryCode || 'USA',
            companyCityName: company.city || "New York",
            companyAddress: company.address,
            companyZipCode: company.zipCode
        })
    }, true
    );
}

function updateArrangerClient(token) {
    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/accounts/update-arranger-client`,
        {
            method: 'POST',
            responseType: responseType.none,
            body: JSON.stringify({
                token,
            }),
        },
        true,
    );
}

function logAccessToSharedTransaction(dealReferenceName, transactionReferenceName, token) {
    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/deals/${dealReferenceName}/Transactions/${transactionReferenceName}/share-clients/log`,
        {
            method: 'PUT',
            responseType: responseType.none,
            body: JSON.stringify({
                token,
            }),
        },
    );
}

function getTransactionsList(filterCriteria, searchCriteria) {
    const searchParams = {
        orderBy: searchCriteria,
    };

    return fetchSafe(`${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/Transactions`, {
        method: 'POST', body: JSON.stringify({ ...filterCriteria, ...searchParams })
    });
}

function getTransactionsSummary(transactionFilterOptions, statsToCalculate) {
    return fetchSafe(`${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/Transactions/statistics`, {
        method: 'POST',
        body: JSON.stringify({
            transactionFilterOptions,
            statsToCalculate,
        }),
    });
}

function getTransactionClassesSummary(filterCriteria) {
    return fetchSafe(`${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/transaction-classes/statistics`, {
        method: 'POST',
        body: JSON.stringify(filterCriteria),
    });
}

function getLatestTransactions(companyReferenceName) {
    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/Transactions/collateral-manager/${companyReferenceName}/latest-transaction-in-deal`
    );
}

function getLatestTransactionsByTicker(tickers) {
    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/transactions/latest-transactions-in-deals`, {
        method: 'POST',
        body: JSON.stringify({ tickers }),
    });
}

function getTransaction(dealReferenceName, transactionReferenceName) {
    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/deals/${dealReferenceName}/transactions/${transactionReferenceName}`
    );
}

function getTransactionHistory(dealReferenceName, transactionReferenceName, options = { classesOnly: false }) {
    const url = urlBuilder(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/deals/${dealReferenceName}/transactions/${transactionReferenceName}/history`,
        options
    );

    return fetchSafe(url);
}

function getTransactionClasses(filterCriteria, searchCriteria) {
    const searchParams = {
        orderBy: searchCriteria,
    };

    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/transaction-classes`, {
        method: 'POST', body: JSON.stringify({ ...filterCriteria, ...searchParams, includeTransaction: true })
    }
    );
}

function getTransactionClassesNames() {
    return fetchSafe(`${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/transaction-classes-names`);
}

function getPortfolio(dealReferenceName, transactionReferenceName) {
    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/deals/${dealReferenceName}/transactions/${transactionReferenceName}/portfolio`
    );
}

function getAmrDocuments(dealReferenceName, transactionReferenceName) {
    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/deals/${dealReferenceName}/transactions/${transactionReferenceName}/documents`
    );
}

function getDocuments(dealReferenceName, transactionReferenceName) {
    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/deals/${dealReferenceName}/transactions/${transactionReferenceName}/documents`
    );
}

function getCollateralQualityTest(dealReferenceName, transactionReferenceName) {
    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/deals/${dealReferenceName}/transactions/${transactionReferenceName}/collateral-quality-tests`
    );
}


function downloadIntexFile(dealReferenceName, transactionReferenceName) {
    const requestOptions = {
        responseType: responseType.file,
    };

    return fetchSafe(`${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/deals/${dealReferenceName}/transactions/${transactionReferenceName}/intex-file/download`, requestOptions);
}

function getIntexFile(dealReferenceName, transactionReferenceName) {
    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/deals/${dealReferenceName}/transactions/${transactionReferenceName}/intex-file/`,
        undefined,
        { successHandler: response => response.status === 204 ? null : response.json() },
    );
}

function getManagerPresentationFile(dealReferenceName, transactionReferenceName) {
    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/deals/${dealReferenceName}/transactions/${transactionReferenceName}/manager-presentation-file/`,
        undefined,
        { successHandler: response => response.status === 204 ? null : response.json() },
    );
}

function downloadManagerPresentationFile(dealReferenceName, transactionReferenceName) {
    const requestOptions = {
        responseType: responseType.file,
    };

    return fetchSafe(`${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/deals/${dealReferenceName}/transactions/${transactionReferenceName}/manager-presentation-file/download`, requestOptions);
}

function getDisclosureFile(dealReferenceName, transactionReferenceName) {
    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/deals/${dealReferenceName}/transactions/${transactionReferenceName}/disclosure-file/`,
        undefined,
        { successHandler: response => response.status === 204 ? null : response.json() },
    );
}

function downloadDisclosureFile(dealReferenceName, transactionReferenceName) {
    const requestOptions = {
        responseType: responseType.file,
    };

    return fetchSafe(`${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/deals/${dealReferenceName}/transactions/${transactionReferenceName}/disclosure-file/download`, requestOptions);
}

function getTransactionDocument(dealReferenceName, transactionReferenceName, referenceName) {
    const requestOptions = {
        responseType: responseType.file,
    };

    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/deals/${dealReferenceName}/transactions/${transactionReferenceName}/documents/${referenceName}/download`, requestOptions
    );
}

function getTransactionDocuments(
    dealReferenceName,
    transactionReferenceName,
) {
    const requestOptions = {
        responseType: responseType.file,
    };

    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/deals/${dealReferenceName}/transactions/${transactionReferenceName}/documents/download-all`,
        requestOptions
    );
}

function exportPortfolio(dealReferenceName, transactionReferenceName) {
    const requestOptions = {
        responseType: responseType.file,
    };

    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/deals/${dealReferenceName}/transactions/${transactionReferenceName}/portfolio/export`, requestOptions
    );
}

function getSyndicateContacts(dealReferenceName, transactionReferenceName) {
    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/deals/${dealReferenceName}/transactions/${transactionReferenceName}/syndicate-contacts`
    ).then(res => res == null ? [] : res);
}

function getSyndicateContactsByArranger(dealReferenceName, transactionReferenceName, companyReferenceName) {
    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/deals/${dealReferenceName}/transactions/${transactionReferenceName}/syndicate-contacts/${companyReferenceName}`
    );
}

function exportTransactionsClasses(filterCriteria, dealSortCriteria, trasactionClassSortCriteria) {
    const searchParams = {
        orderByTransaction: dealSortCriteria,
        orderByTransactionClass: trasactionClassSortCriteria,
    };
    const requestOptions = {
        body: JSON.stringify({ ...filterCriteria, ...searchParams }),
        method: 'POST',
        responseType: responseType.file
    };

    return fetchSafe(`${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/transactions/export`, requestOptions);
}

function getCurrency() {
    return fetchSafe(`${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/Currency`);
}

function getIOIs(dealReferenceName, transactionReferenceName) {
    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/deals/${dealReferenceName}/transactions/${transactionReferenceName}/iois`
    );
}

function submitIOIs(dealReferenceName, transactionReferenceName, iois) {
    const requestOptions = {
        body: JSON.stringify({ iois }),
        method: "PUT",
    };

    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/deals/${dealReferenceName}/transactions/${transactionReferenceName}/iois`, requestOptions
    );
}

function readIOIsByUser(dealReferenceName, transactionReferenceName, ioiReferenceNames) {
    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify({ ioiReferenceNames }),
        responseType: responseType.none,
    };
    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/deals/${dealReferenceName}/transactions/${transactionReferenceName}/read-iois-by-user`,
        requestOptions,
    );
}

function exportIOIs(dealReferenceName, transactionReferenceName, excludeDeleted = false) {
    const requestOptions = {
        responseType: responseType.file,
    };

    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/deals/${dealReferenceName}/transactions/${transactionReferenceName}/iois/export/${excludeDeleted}`,
        requestOptions,
    );
}

function logTabAccess(dealReferenceName, transactionReferenceName, tabAccessType) {
    return fetchSafe(`${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/deals/${dealReferenceName}/transactions/${transactionReferenceName}/tab/${tabAccessType}`, {
        method: 'POST',
        responseType: responseType.none,
    })
}

function getTransactionViewHistory(dealReferenceName, transactionReferenceName, startDate, endDate) {
    let queryString = '';

    if (startDate && endDate) {
        const queryParams = {
            'period.From': moment(startDate).format(),
            'period.To': moment(endDate).format(),
        };

        queryString = `?${qs.stringify(queryParams)}`;
    }

    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/deals/${dealReferenceName}/transactions/${transactionReferenceName}/statistics/history/${queryString}`,
    );
}

function getTransactionVersions(dealReferenceName, transactionReferenceName) {
    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/deals/${dealReferenceName}/transactions/${transactionReferenceName}/statistics/versions`,
    );
}

function getDocumentsDownloads(dealReferenceName, transactionReferenceName, startDate, endDate) {
    let queryString = '';

    if (startDate && endDate) {
        const queryParams = {
            'period.From': moment(startDate).format(constants.dateFormatISO8601),
            'period.To': moment(endDate).format(constants.dateFormatISO8601),
        };

        queryString = `?${qs.stringify(queryParams)}`;
    }

    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/deals/${dealReferenceName}/transactions/${transactionReferenceName}/statistics/document-downloads/${queryString}`
    );
}

function getDaysInTheMarket(dealReferenceName, transactionReferenceName) {
    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/deals/${dealReferenceName}/transactions/${transactionReferenceName}/statistics/days-market`
    );
}

function downloadStatisticDocument(dealReferenceName, transactionReferenceName, documentReferenceName, accessType) {
    const requestOptions = {
        responseType: responseType.file,
    };

    let documentLink;

    switch (accessType) {
        case DownloadedDocumentAccessType.DownloadAllDocuments:
        case DownloadedDocumentAccessType.DownloadOneDocument: {
            documentLink = 'documents'
            break;
        }
        case DownloadedDocumentAccessType.DownloadIntexFile: {
            documentLink = 'intex-file';
            break;
        }
        case DownloadedDocumentAccessType.DownloadManagerPresentationFile: {
            documentLink = 'manager-presentation-file';
            break;
        }
        case DownloadedDocumentAccessType.DownloadDisclosureFile: {
            documentLink = 'disclosure-file';
            break;
        }
        default: break;
    }

    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/deals/${dealReferenceName}/transactions/${transactionReferenceName}/statistics/${documentLink}/${documentReferenceName}/download`,
        requestOptions,
    );
}

function unblockUserRequest(userEmail) {
    const url = `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/Accounts/user/Unblock`;

    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify({ userEmail }),
        responseType: responseType.json,
    };

    return fetchSafe(url, requestOptions);
}

function blockUserRequest(userEmail) {
    const url = `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/Accounts/user/Block`;

    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify({ userEmail }),
        responseType: responseType.json,
    };

    return fetchSafe(url, requestOptions);
}

function saveUser(user) {
    const url = `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/Accounts/user/Update`;

    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify({ ...user }),
    };

    return fetchSafe(url, requestOptions);
}

function shareTransactionToClients(
    dealReferenceName,
    transactionReferenceName,
    recipientsEmails,
    message
) {
    const requestOptions = {
        body: JSON.stringify({ recipientsEmails, message }),
        method: "PUT",
        responseType: responseType.none,
    };

    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/deals/${dealReferenceName}/transactions/${transactionReferenceName}/share-clients/add`,
        requestOptions
    );
}

function logImUserActivity() {
    const url = `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/Accounts/log-user-activity/IM`;

    const requestOptions = {
        method: 'POST',
        responseType: responseType.none,
    };

    return fetchSafe(url, requestOptions);
}

function getSharedClients(dealReferenceName, transactionReferenceName) {
    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/deals/${dealReferenceName}/transactions/${transactionReferenceName}/share-clients`,
    );
}

function uploadDocument(file, progressCallback) {
    return fileUpload(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/documents`,
        null,
        file,
        progressCallback
    );
}

function downloadDocument(referenceName) {
    const requestOptions = {
        responseType: responseType.file,
    };

    return fetchSafe(`${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/documents/${referenceName}`, requestOptions);
}

function requestDemo() {
    const requestOptions = {
        responseType: responseType.none,
    };

    return fetchSafe(`${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/accounts/demo-request`, requestOptions);
}

function getBankTransactions(companyReferenceName) {
    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/transactions/bank/${companyReferenceName}`
    );
}

function getDealComparableMetrics(
    dealReferenceName,
    transactionReferenceName) {

    return fetchSafe(`${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/deals/${dealReferenceName}/transactions/${transactionReferenceName}/comparable-deals`);
}
