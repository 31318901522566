import classNames from "classnames";
import { constants } from "../../../../constants";
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import IconSVG from '../../../../styles/svg-icons';
import { IIndicatorSection } from './types';
import { Difference } from '../../../../utils/differ/types';
import { DealUpdateIdentifier, dealUpdatesIdentifiersTitles } from "../../../../types/amr-pipeline/enums/DealUpdateIdentifier";
import Highlighter from "../../common/Highlighter";
import { OnHoverTooltip } from "../../../common";
import { dateTimeUtils } from "../../../../utils";

interface Props {
    selected: boolean;
    searchTerms: string[];
    onViewClick: (version: number) => void;
    dealUpdatesIdentifiers?: DealUpdateIdentifier[];
    version: number;
    versionTitle: string;
    versionDate: Date;
    versionClosingDate?: Date;
    indicatorSections: IIndicatorSection<unknown>[];
    difference: Difference<unknown>;
}

export const TransactionHistoryNavItem = ({
    selected,
    searchTerms,
    onViewClick,
    dealUpdatesIdentifiers,
    version,
    versionTitle,
    versionDate,
    versionClosingDate,
    indicatorSections,
    difference,
}: Props) => {
    const isSearchTermEntered = searchTerms.some(s => !!s.length);
    const [defaultModeExpanded, setDefaultModeExpanded] = useState(selected || isSearchTermEntered);
    const [searchModeExpanded, setSearchModeExpanded] = useState(true);

    const isInitialTransaction = version === 1;
    const expanded = isSearchTermEntered ? searchModeExpanded : defaultModeExpanded;

    const dealUpdates = dealUpdatesIdentifiers?.map((d, index) => (
        <>
            <Highlighter searchWords={searchTerms} textToHighlight={dealUpdatesIdentifiersTitles[d]} />
            {dealUpdatesIdentifiers.length === index + 1 ? '' : ', '}
        </>
    ));

    useEffect(() => {
        setSearchModeExpanded(true);
    }, [searchTerms]);

    const renderContent = () => {
        if (isInitialTransaction) {
            return <span>Transaction created</span>;
        }

        if (!indicatorSections.length) {
            return undefined;
        }

        const sections = indicatorSections.map(x => x.render(searchTerms, difference));
        return sections.every(section => !section)
            ? <span>No updates available in this version</span>
            : sections;
    };

    const expandOrCollapse = (expanded: boolean) => {
        if (searchTerms && isSearchTermEntered) {
            setSearchModeExpanded(expanded);
        } else {
            setDefaultModeExpanded(expanded);
        }
    };

    const handleViewClick = () => {
        onViewClick(version);
        expandOrCollapse(true);
    };

    const handleExpand = () => expandOrCollapse(!expanded);

    const historyDate = versionClosingDate
        ? moment(versionClosingDate).format(constants.dateFormat)
        : dateTimeUtils.changeDateTimeZone(
            versionDate,
            constants.estTimezone,
            false
        ).format(constants.dateTimeFormat) + " EST";

    return (
        <div className="history-nav-box">
            <div
                className={classNames(
                    "sidebar-nav-list-item sidebar-nav-list-item-extended",
                    { active: selected, expanded }
                )}
                onClick={selected ? handleExpand : handleViewClick}
            >
                <span className="btn-link btn-expand" onClick={handleExpand}>
                    <IconSVG name="icon-expand" width="16" height="16" />
                </span>
                <div className="history-box-head">
                    <span className="history-version">{versionTitle}</span>
                    <span className="history-date">{historyDate}</span>
                </div>
                {dealUpdatesIdentifiers?.length ? (
                    <div className="history-deal-updates">
                        <OnHoverTooltip overlay={dealUpdates}>
                            <div className="text-ellipsis">
                                {dealUpdates}
                            </div>
                        </OnHoverTooltip>
                    </div>
                ) : ''}
            </div>
            {expanded && (
                <div className="history-content">
                    {renderContent()}
                </div>
            )}
        </div>
    );
};
