import React, { useState, useEffect, useRef, useContext } from "react";
import { useSelector } from "react-redux";
import { createAmrPipelineActions, createSearchTransactionActions } from "../../actions";
import classNames from "classnames";
import { ClickOutside } from "../common/ClickOutside";
import { ChipList } from "./ChipList";
import { Deal } from "../../types/amr-pipeline/models/Deal";
import { constants } from "../../constants";
import { EmptyPlaceholder, Preloader } from "../common";
import IconSVG from "../../styles/svg-icons";
import { issuanceMonitorSearchTransactionsSelector } from '../../selectors/amr-pipeline.selector';
import PipelineContext from '../amrPipeline/aggregated/PipelineContext';
import { Tranche } from "../../types/amr-pipeline/models/Tranche";
import Highlighter from "../amrPipeline/common/Highlighter";
import { withSearchedClassIndicator } from "../../utils/amr-pipeline.utils";
import { useAppDispatch } from "../../effects/useAppDispatch";
import StatusLabel from "../amrPipeline/StatusLabel";
import { TransactionStatus, transactionStatusTitles } from "../../types/amr-pipeline/enums/TransactionStatus";

const displayAmount = 10;

const renderHighlightOrPlaceholder = (
    searchTerm: string,
    value?: string,
    className = ""
) =>
    value ? (
        <Highlighter
            searchWords={[searchTerm]}
            textToHighlight={value}
            className={className}
        />
    ) : (
        constants.emptyPlaceholder
    );

interface Props {
    singleSearch?: boolean;
    disabled?: boolean;
    placeholder?: string;
    onSearch: () => any;
    className?: string;
    maxLength?: number;
}

export const TransactionsSearchInput = ({
    singleSearch = false,
    disabled = false,
    placeholder = "",
    onSearch,
    className,
    maxLength,
}: Props) => {
    const { pipelineType } = useContext(PipelineContext);
    const actions = createSearchTransactionActions(pipelineType);
    const pipelineActions = createAmrPipelineActions(pipelineType);
    const refInput = useRef<HTMLInputElement>(null);
    const refScrollableContainer = useRef<HTMLDivElement>(null);
    const refActiveItem = useRef<HTMLTableRowElement | null>(null);
    const dispatch = useAppDispatch();
    const {
        searchTerm = '',
        searchTermItems = [],
        markerPosition = -1,
        lookup = [],
        isSearching,
        hasMoreDeals,
    } = useSelector(issuanceMonitorSearchTransactionsSelector(pipelineType));

    const [index, setIndex] = useState(-1);
    const [inputFocus, setInputFocus] = useState(false);
    const [classOffset, setClassOffset] = useState(displayAmount);
    const [dealOffset, setDealOffset] = useState(displayAmount);
    const deals = lookup.filter((d) => (d as Deal).isDeal);
    const groupedDeals = deals.slice(0, dealOffset);
    const classes = groupedDeals.reduce((acc: Tranche[], dealItem) => {
        const classesWithIndicators = dealItem.classes.reduce(
            (classesAccum: Tranche[], c) => (
                withSearchedClassIndicator(searchTerm, c)
                    ? [
                            ...classesAccum,
                            {
                                ...c,
                                dealLegalName: dealItem.legalName,
                                dealReferenceName: dealItem.referenceName,
                            },
                        ]
                    : classesAccum
            ), []
        );

        return [...acc, ...classesWithIndicators];
    }, []);

    const groupedClasses = classes.slice(0, classOffset);
    const groupedDealsLength = groupedDeals.length;
    const groupedClassesLength = groupedClasses.length;
    const loadedDealsLeftAmount = deals.length - groupedDealsLength;
    const loadedClassesLeftAmount = classes.length - groupedClassesLength;

    const searchItemsLength = !!searchTermItems.length;

    useEffect(() => {
        if (
            refScrollableContainer.current &&
            refActiveItem.current &&
            lookup.length &&
            classOffset === displayAmount &&
            dealOffset === displayAmount
        ) {
            const container = refScrollableContainer.current.getBoundingClientRect();
            const item = refActiveItem.current.getBoundingClientRect();
            if (item.top < container.top || item.bottom > container.bottom) {
                refActiveItem.current.scrollIntoView(false);
            }
        }
    }, [lookup, index, classOffset, dealOffset]);

    useEffect(() => {
        dispatch(pipelineActions.setExpanded(!!searchItemsLength));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchItemsLength])

    const getPlaceholder = () => {
        if (searchTermItems.length) {
            return "";
        }
        return placeholder ? placeholder : "Search by deal name, deal ticker, class ticker, CUSIP or ISIN";
    };

    const isStatusVisible = (status: TransactionStatus) => (
        status === TransactionStatus.Active || status === TransactionStatus.Priced || status === TransactionStatus.OnHold
    )

    const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (singleSearch && searchTermItems[0]) {
            handleClearAll();
        }
        setIndex(-1);
        setClassOffset(displayAmount);
        setDealOffset(displayAmount);
        dispatch(actions.searchTermChange(e.target.value));
    };

    const handleClearAll = () => {
        setIndex(-1);
        dispatch(actions.reset());
        refInput.current && refInput.current.focus();
        onSearch();
    };

    const handleLookupItemClick = (
        label: string,
        dealReferenceName: string,
        classNames: string[] = [],
    ) => {
        setIndex(-1);
        refInput.current && refInput.current.focus();
        dispatch(actions.applyLookupItem(label, dealReferenceName, classNames));
        onSearch();
    };

    const handleRemoveChip = (i: number) => {
        dispatch(actions.removeSearchItem(i));
        if (refInput.current) {
            refInput.current.focus();
        }
        onSearch();
    };

    const handleClickOutside = (e: MouseEvent) => {
        e.stopPropagation();
        if (lookup.length) {
            setIndex(-1);
            dispatch(actions.resetSearchLookup());
        }
        setInputFocus(false);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.keyCode === 8) {
            if (markerPosition > 0 && !searchTerm) {
                dispatch(actions.removeCurrentItem());
                onSearch();
            } else if (e.currentTarget.selectionStart === 0 && !searchTerm) {
                handleRemoveChip(searchTermItems.length - 1);
            }
        } else if (
            !searchTerm &&
            e.keyCode === 37 &&
            e.currentTarget.selectionStart === 0
        ) {
            dispatch(actions.moveBack());
        } else if (!searchTerm && e.keyCode === 39 && markerPosition !== -1) {
            e.preventDefault();
            dispatch(actions.moveForward());
        }
        if (groupedDealsLength || groupedDealsLength) {
            if (e.keyCode === 13) {
                const selectedIndex = index < 0 ? 0 : index;

                // enter
                const idDealGroup = selectedIndex < groupedDealsLength;

                const dealItem = groupedDeals[selectedIndex];
                const classItem = groupedClasses[selectedIndex - groupedDealsLength];

                const referenceName = idDealGroup
                    ? dealItem.referenceName
                    : classItem.dealReferenceName;

                const label = idDealGroup
                    ? dealItem.legalName
                    : classItem.ticker144A || classItem.dealLegalName;

                const classList = idDealGroup
                    ? []
                    : [classItem.name]

                handleLookupItemClick(label, referenceName, classList);
            } else if (e.keyCode === 38) {
                // move up
                e.preventDefault();
                if (index >= 0) {
                    setIndex(index - 1);
                }
            } else if (e.keyCode === 40 && index < groupedDealsLength + groupedClassesLength - 1) {
                // move down
                setIndex(index + 1);
            }
        }
    };

    const showMoreDeals = () =>  {
        if(loadedDealsLeftAmount < displayAmount) {
            dispatch(actions.loadSearchLookup(searchTerm, true))
        }
        setDealOffset(prevVal => prevVal + displayAmount);
    }
    const showMoreClasses = () => {
        if(loadedClassesLeftAmount < displayAmount && hasMoreDeals) {
            dispatch(actions.loadSearchLookup(searchTerm, true))
        }
        setClassOffset(prevVal => prevVal + displayAmount);
    }

    const renderLookupDeal = (item: Deal, i: number) => {
        const { legalName, referenceName, ticker, transactionStatus } = item;
        return (
            <tr
                key={i}
                ref={node => (refActiveItem.current = index === i ? node : refActiveItem.current)}
                className={classNames({ active: index === i })}
                onClick={() => handleLookupItemClick(legalName, referenceName)}
            >
                <td className="col-main">
                    {isStatusVisible(transactionStatus) && <StatusLabel status={transactionStatusTitles[transactionStatus]} />}
                </td>
                <td className="col-main">
                    <div className={classNames('result-row-wrap text-ellipsis', { 'result-row-wrap-ticker': ticker })}>
                        {renderHighlightOrPlaceholder(searchTerm, ticker)}
                    </div>
                </td>
                <td className="col-main">
                    <div className="result-row-name text-ellipsis">
                        {renderHighlightOrPlaceholder(searchTerm, legalName)}
                    </div>
                </td>
            </tr>
        );
    };

    const renderLookupClass = (item: Tranche, i: number) => {
        const {
            name,
            dealLegalName,
            dealReferenceName,
            ticker144A,
            tickerRegS,
            tickerAccdInvCertif,
            cusip144A,
            cusipRegS,
            cusipAccdInvCertif,
            isin144A,
            isinRegS,
            isinAccdInvCertif
        } = item;

        const classesData =[
            { rule144a: ticker144A, regS: tickerRegS, accdInvCertif: tickerAccdInvCertif },
            { rule144a: cusip144A, regS: cusipRegS, accdInvCertif: cusipAccdInvCertif },
            { rule144a: isin144A, regS: isinRegS, accdInvCertif: isinAccdInvCertif },
        ];

        const rowIndex = groupedDealsLength + i;

        return (
            <tr
                key={rowIndex}
                ref={(node) =>
                    (refActiveItem.current =
                        index === rowIndex ? node : refActiveItem.current)
                }
                className={classNames('classes-row', { active: index === rowIndex })}
                onClick={() => handleLookupItemClick(ticker144A || dealLegalName, dealReferenceName, [name])}
            >
                <td></td>
                {classesData.map(({ rule144a, regS, accdInvCertif }, classIndex) => (
                    <td key={classIndex} className="col-main">
                        <div className="classes-wrap">
                            <div className="classes-name text-ellipsis">
                                {renderHighlightOrPlaceholder(searchTerm, rule144a)}
                            </div>
                            <div className="classes-info text-ellipsis">
                                {renderHighlightOrPlaceholder(searchTerm, regS, "classes-info-item")}
                                {` / `}
                                {renderHighlightOrPlaceholder(searchTerm, accdInvCertif, "classes-info-item")}
                            </div>
                        </div>
                    </td>
                ))}
            </tr>
        );
    };

    const renderShowMore = (
        items: Deal[] | Tranche[],
        hasMoreItems: boolean,
        showMore: () => void,
        colSpan: number
    ) => {
        if(items.length < displayAmount || (!hasMoreItems && items.length === displayAmount)) {
            return null;
        }

        return (
            <tr>
                <td className="show-more-results" colSpan={colSpan}>
                    {isSearching && <Preloader inProgress={true} small />}
                    {hasMoreItems ? (
                        <button className="btn-link" onClick={showMore}>
                            <IconSVG name="saving" width={16} height={16} />
                            Show More Results
                        </button>
                    ) : (
                        <span>You have reached the end of the results</span>
                    )}
                </td>
            </tr>
        )
    }

    return (
        <ClickOutside onClick={handleClickOutside}>
            <div className={classNames('control-search-wrapper', className, { 'control-search-focus': inputFocus, 'disabled': disabled })}>
                <div className="form-control-search-btn">
                    <i className="icon icon-search" />
                </div>
                {!singleSearch && (
                    <ChipList
                        list={searchTermItems.map((i) => i.label)}
                        selectionIndex={markerPosition}
                        onRemove={handleRemoveChip}
                    />
                )}
                <input
                    ref={refInput}
                    className="form-control form-control-search"
                    type="text"
                    value={searchTerm}
                    onChange={handleSearchTermChange}
                    onKeyDown={handleKeyDown}
                    onFocus={() => setInputFocus(true)}
                    placeholder={getPlaceholder()}
                    disabled={disabled}
                    maxLength={maxLength}
                />
                {(!!searchTerm || !!searchTermItems.length) && (
                    <button className="btn-close" onClick={handleClearAll}>
                        <IconSVG name="close" width={16} height={16} />
                    </button>
                )}
                {searchTerm.length > constants.searchTermAcceptedLength && (
                    <div ref={refScrollableContainer} className="search-lookup search-lookup-transactions">
                        {isSearching && !lookup.length && <Preloader inProgress={true} />}
                        {
                            lookup.length
                                ?
                                <>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Deals</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <>
                                                {groupedDeals.map((item, i) =>
                                                    renderLookupDeal(item, i)
                                                )}
                                                {renderShowMore(groupedDeals, !!(loadedDealsLeftAmount) || hasMoreDeals, showMoreDeals, 3)}
                                            </>
                                        </tbody>
                                    </table>
                                    {!!groupedClassesLength && (
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Classes</th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <>
                                                    {groupedClasses.map((c, i) =>
                                                        renderLookupClass(c, i)
                                                    )}
                                                    {renderShowMore(groupedClasses, !!(loadedClassesLeftAmount), showMoreClasses, 4)}
                                                </>
                                            </tbody>
                                        </table>
                                    )}
                                </>
                            : <EmptyPlaceholder textView={true} />
                        }
                    </div>
                )}
            </div>
        </ClickOutside>
    );
};
