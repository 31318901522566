import { useEffect, useState } from "react";
import { RequestState } from "../../../../../../constants/request-state";
import { useAppDispatch } from "../../../../../../effects/useAppDispatch";
import { errorActions } from "../../../../../../actions";
import { ComparableDealMetrics } from "../../../../../../types/amr-pipeline/models/ComparableDeal";
import { amrPipelineService } from "../../../../../../services/amr-pipeline.service";
import { isRequestNone } from "../../../../../../utils";

export function useDealComparativeMetrics(dealReferenceName: string, transactionReferenceName: string) {
    const dispatch = useAppDispatch();

    const [requestState, setRequestState] = useState(RequestState.none)
    const [metrics, setMetrics] = useState<ComparableDealMetrics | null>(null);

    useEffect(() => {
        if (!isRequestNone(requestState) || !dealReferenceName || !transactionReferenceName) return;

        const doRequest = async () => {
            setRequestState(RequestState.request)

            try {
                const metrics = await amrPipelineService.getDealComparableMetrics(
                    dealReferenceName, transactionReferenceName);
                setMetrics(metrics);
                setRequestState(RequestState.success);
            } catch (e) {
                setRequestState(RequestState.failure);
                dispatch(errorActions.error(e));
            }
        }

        doRequest();
    }, [requestState, metrics, dealReferenceName, transactionReferenceName, dispatch])

    return { requestState, metrics };
}