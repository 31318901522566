import moment from 'moment';
import { Plot, mainConfig } from '../../common/charts';
import { chartUtils, moneyUtils } from '../../../utils';
import { DailyIssuanceSpread, IssuanceSpreadBase, Spread } from '../../../types/dashboard/IssuanceSpread';
import { chartUsBslNewIssueSpreads } from '../../../constants/dashboard/chartUsBslNewIssueSpreads';
import { isNil } from 'lodash';
import { constants } from '../../../constants';


const getDailyDimension = (spread: IssuanceSpreadBase) => {
    const { year, month, day } = spread as DailyIssuanceSpread;
    return !isNil(year) && !isNil(month) && !isNil(day)
        ? moment([year, month - 1, day]).format(constants.dateFormat4)
        : undefined;
};

interface WeeklySpreadsChartProps {
    spreads: IssuanceSpreadBase[];
    fieldSelector: (spread: IssuanceSpreadBase) => Spread | undefined;
    getDimension?: (spread: IssuanceSpreadBase) => string | undefined;
    divId?: string;
}

export interface EmptyMarkerSpread extends Spread {
    emptyMarker: boolean
}

export function IssuanceSpreadChart({
    spreads,
    fieldSelector,
    getDimension = getDailyDimension,
    divId = 'was-warf-ds-chart'
}: WeeklySpreadsChartProps) {
    const {
        chartLayout,
        tickColor,
        tickFont,
        bar,
        border,
        zeroLineColor,
        hoverlabel,
    } = chartUsBslNewIssueSpreads;

    const isSingleValueSpread = (spread: Spread) =>
        spread.min === spread.max &&
        spread.max === spread.median &&
        spread.median === spread.q1 &&
        spread.q1 === spread.q3;

    const isEmptyMarkerSpread = (spread: Spread) =>
        (spread as EmptyMarkerSpread).emptyMarker

    const getHoverInfoText = (spread: IssuanceSpreadBase) => {
        const row = fieldSelector(spread);
        if (!row) return '';

        if (isSingleValueSpread(row)) {
            return (
                `${getDimension(spread)}<br />` +
                `${moneyUtils.money(row.median, true)} <br />`
            )
        }

        let outliers;

        if (row.bottomOutlier && row.topOutlier) {
            outliers = moneyUtils.money(row.bottomOutlier, true) + '; ' + moneyUtils.money(row.topOutlier, true);
        } else {
            const outlier = row.bottomOutlier || row.topOutlier;
            outliers = outlier ? moneyUtils.money(outlier, true) : undefined;
        }

        return (
            `${getDimension(spread)}<br />` +
            `Min: ${moneyUtils.money(row.min, true)} <br />` +
            `Q1: ${moneyUtils.money(row.q1, true)} <br />` +
            `Med: ${moneyUtils.money(row.median, true)} <br />` +
            `Q3: ${moneyUtils.money(row.q3, true)} <br />` +
            `Max: ${moneyUtils.money(row.max, true)} <br />` +
            (outliers ? `Outlier(s): ${(outliers)} <br />` : '')
        );
    };
    const getChartData = () => {
        return spreads.reduce((acc: any, spread: IssuanceSpreadBase) => {
            const dimension = getDimension(spread);
            const row = fieldSelector(spread);

            if (!row) return acc;

            const isSingleValue = isSingleValueSpread(row);
            const isEmptyMarker = isEmptyMarkerSpread(row)

            let data = [...acc];

            if (!isEmptyMarker) {
                const box = row.count ? {
                    q1: [row.q1],
                    q3: [row.q3],
                    median: [row.median],
                    lowerfence: [row.min],
                    upperfence: [row.max],
                    name: isSingleValue ? undefined : getHoverInfoText(spread),
                    x: [dimension],
                    type: 'box',
                    marker: {
                        color: border,
                    },
                    fillcolor: bar,
                    hoverinfo: isSingleValue ? 'skip' : 'name',
                    boxpoints: false,
                } : {};

                const topOutlier = {
                    x: [dimension],
                    y: [row.count ? row.topOutlier : undefined],
                    hoverinfo: 'skip',
                    mode: 'markers',
                    type: 'scatter',
                    marker: {
                        size: 6,
                        color: border,
                    },
                };

                const bottomOutliers = {
                    x: [dimension],
                    y: [row.count ? row.bottomOutlier : undefined],
                    hoverinfo: 'skip',
                    mode: 'markers',
                    type: 'scatter',
                    marker: {
                        size: 6,
                        color: border,
                    },
                };

                data.push(box, topOutlier, bottomOutliers);
            }

            if (isSingleValue || isEmptyMarker) {
                data.push({
                    x: [dimension],
                    y: [row.median],
                    name: isEmptyMarker ? undefined : getHoverInfoText(spread),
                    hoverinfo: isEmptyMarker ? 'skip' : 'name',
                    mode: 'none'
                });
            }

            return data;
        }, []);
    };

    const getLayout = () => {
        return {
            ...chartLayout,
            showlegend: false,
            autosize: true,
            hovermode: 'closest',
            hoverlabel,
            xaxis: {
                automargin: true,
                type: 'category',
                tickcolor: tickColor,
                tickfont: tickFont,
                fixedrange: true,
                dtick: 'D1',
                tickformat: '%m/%d/%y',
            },
            yaxis: {
                automargin: true,
                showgrid: true,
                showtickprefix: 'all',
                fixedrange: true,
                gridcolor: tickColor,
                ticks: 'inside',
                tickcolor: tickColor,
                tickfont: tickFont,
                zerolinecolor: zeroLineColor,
                separatethousands: true,
            },
        };
    };

    const data = getChartData();
    const layout = getLayout();

    return (
        <>
            <Plot
                onHover={chartUtils.setCursor('pointer')}
                onUnhover={chartUtils.setCursor('crosshair')}
                divId={divId}
                data={data}
                layout={layout}
                config={mainConfig}
            />
        </>
    );
}
