import { useMemo } from "react";
import { useGetWebinar } from "../../effects/useGetWebinar";
import { isRequesting } from "../../utils";
import { Preloader } from "../common";
import { useGetCategoryArticles } from "./effects/useGetCategoryArticles";
import { useGetCategorySections } from "./effects/useGetCategorySections";
import { useHelpResourcesCategories } from "./effects/useHelpResourcesCategories";
import { marketTrendsCategoryId } from "./constants";
import { ResourceContent } from "./ResourcesContent";

export const Resources = () => {
    const { requestStateGetWebinar, webinar } = useGetWebinar();
    const { categories, categoriesRequestState } = useHelpResourcesCategories();
    const { requestStateGetCategorySections, sections } =
        useGetCategorySections(marketTrendsCategoryId);
    const { requestStateGetCategoryArticles, articles } =
        useGetCategoryArticles(marketTrendsCategoryId);

    const category = useMemo(
        () => categories.find((c) => c.id === marketTrendsCategoryId),
        [categories]
    );

    return (
        <Preloader
            inProgress={
                isRequesting(categoriesRequestState) ||
                isRequesting(requestStateGetCategorySections) ||
                isRequesting(requestStateGetWebinar) ||
                isRequesting(requestStateGetCategoryArticles) ||
                !category
            }
        >
            <ResourceContent
                category={category}
                webinar={webinar}
                webinarSections={sections}
                webinarArticles={articles}
            />
        </Preloader>
    );
};

