import React from "react";
import { routes } from "../../../constants/routes";
import { OnHoverTooltip } from "../../common/OnHoverTooltip";
import { user } from "../../../user";
import { roles } from "../../../constants";

interface Props {
    referenceName: string;
    legalName: string;
    overlayText?: string;
    onClick?: () => void;
}

export function BankLink({ referenceName, legalName, overlayText, onClick }: Props) {
    const isLinkAvailable = user.hasRoles(...roles.banksAccess());

    const handleClick = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        e.preventDefault();

        if (onClick) {
            onClick();
        }

        window.open(
            window.location.origin + routes.manageBanksUrl(referenceName)
        );
    };

    return (
        <OnHoverTooltip overlay={overlayText || legalName}>
            {isLinkAvailable ? (
                <span
                    className="link-filter text-ellipsis"
                    onClick={handleClick}
                >
                    {legalName}
                </span>
            ) : (
                <span>{legalName}</span>
            )}
        </OnHoverTooltip>
    );
}
