import IconSVG from '../../styles/svg-icons';
import cn from 'classnames';
import { OnHoverTooltip } from './OnHoverTooltip';
import { ClassIndicators } from '../../types/amr-pipeline/models/ClassIndicators';
import { constants } from '../../constants';
import { logger } from '../../logging/logger';
import { SubscriptionFeature } from '../../types/billing/SubscriptionFeature';
import { ActionBlocker } from '../access/ActionBlocker';
import { useContext } from 'react';
import { BlockedFeatureContext } from '../access/BlockedFeatureContext';

type Props = {
    identifier?: string;
    identifiers?: ClassIndicators;
    text?: string;
    className?: string;
    disabled?: boolean;
    textLink?: boolean;
    placeholderIfEmpty?: boolean;
    secondaryPlaceholderColor?: boolean;
};

export function ValitanaLink({
    identifiers,
    identifier,
    className,
    placeholderIfEmpty,
    secondaryPlaceholderColor,
    disabled = false,
    textLink = false
}: Props) {
    const blockedFromOutside = useContext(BlockedFeatureContext);
    const identifierForLink = identifier ?? getValitanaIdentifer(identifiers);

    if (!identifierForLink) {
        return placeholderIfEmpty ? (
            <span className={cn({ 'action-empty-placeholder': secondaryPlaceholderColor })}>
                {constants.emptyPlaceholder}
            </span>
        ) : null;
    }

    if (disabled) {
        return (
            <button className={cn('btn-link btn-link-valitana', className)} disabled={disabled}>
                <IconSVG name="valitana" width={16} height={16} />
                {textLink && <span className="align-middle">Valitana</span>}
            </button>
        );
    }

    function getValitanaIdentifer(identifiers?: ClassIndicators) {
        if (!identifiers) {
            return undefined;
        }

        const {
            ticker144A,
            cusip144A,
            isin144A,
            tickerRegS,
            cusipRegS,
            isinRegS,
            tickerAccdInvCertif,
            cusipAccdInvCertif,
            isinAccdInvCertif,
        } = identifiers;

        return (
            ticker144A ||
            cusip144A ||
            isin144A ||
            tickerRegS ||
            cusipRegS ||
            isinRegS ||
            tickerAccdInvCertif ||
            cusipAccdInvCertif ||
            isinAccdInvCertif
        );
    }

    const generateValitanaLink = () => {
        return `https://analytics.valitana.com/security/${identifierForLink}`;
    };

    const renderBlockedView = () => (
        <button className={cn('btn-link btn-link-valitana', className)} disabled>
            <IconSVG name="valitana" width={16} height={16} />
            {textLink && <span className="align-middle">Valitana</span>}
        </button>
    );

    const renderTooltip = () => (
        <OnHoverTooltip overlay="Launch Valitana to view additional data">
            <a
                onClick={() => logger.trace('User clicked the Valitana link')}
                target="_blank"
                href={generateValitanaLink()}
                className={cn('btn-link btn-link-valitana', { 'btn-link-no-label': !textLink }, className)}
                rel="noreferrer"
            >
                <IconSVG name="valitana" width={16} height={16} />
                {textLink && <span className="align-middle">Valitana</span>}
            </a>
        </OnHoverTooltip>
    );

    return (
        <ActionBlocker feature={SubscriptionFeature.IntegrationsValitana}>
            {blocked => (blocked || blockedFromOutside ? renderBlockedView() : renderTooltip())}
        </ActionBlocker>
    );
}
