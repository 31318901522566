export enum SubscriptionType {
    basic = 'Basic',
    marketMonitoring = 'Market Monitoring',
    primaryIssuance = 'Primary Issuance',
    secondaryMarkets = 'Secondary Markets',
    pro = 'Pro',
    trial = 'Trial'
}

export function isSubscriptionObsolete(subscriotion: SubscriptionType) {
    return subscriotion === SubscriptionType.primaryIssuance ||
        subscriotion === SubscriptionType.secondaryMarkets
}