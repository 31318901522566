import { constants } from "../../../constants";
import { useAppSelector } from "../../../effects/useAppSelector";
import { TransactionStatus, transactionStatusTitles } from "../../../types/amr-pipeline/enums/TransactionStatus";
import { SubscriptionFeature } from "../../../types/billing/SubscriptionFeature";
import { PortfolioSecurity } from "../../../types/portfolio/PortfolioSecurity";
import { isRequesting } from "../../../utils";
import { ValueBlocker } from "../../access/ValueBlocker";
import { ImTransactionDetailsLink } from "../../amrPipeline/common/ImTransactionDetailsLink";
import StatusLabel from "../../amrPipeline/StatusLabel";
import { OnHoverTooltip, Preloader } from "../../common";
import { TransactionTypes } from "../../../types/amr-pipeline/enums/TransactionType";

interface Props {
    security: PortfolioSecurity
}

export function PortfolioTransactionDetailsLink({ security }: Props) {
    const requestState = useAppSelector(s => s.entities.latestTransaction.requestState);

    const transaction = useAppSelector(s => security.dealTicker
        ? s.entities.latestTransaction.items[security.dealTicker.toLowerCase()]
        : undefined
    );

    const requiredFeatures = transaction?.status === TransactionStatus.Active
        ? [] // No subscription restrictions for Active transactions
        : [SubscriptionFeature.IssuanceMonitorFullAccess, SubscriptionFeature.CanLookupTransactionFromPortfolio]

    return (
        <Preloader small inProgress={isRequesting(requestState)}>
            <ValueBlocker requireAllFeatures features={requiredFeatures}>
                {
                    transaction != null && (
                        transaction.status === TransactionStatus.Active ||
                        transaction.status === TransactionStatus.Priced) ? (
                        <ImTransactionDetailsLink
                            dealReferenceName={transaction.dealReferenceName}
                            transactionReferenceName={transaction.referenceName}
                        >
                            <OnHoverTooltip
                                wrapperClass="flex-none item-info-tooltip"
                                overlay={`Transaction Status: ${transactionStatusTitles[transaction?.status]}`}
                            >
                                <StatusLabel status={transactionStatusTitles[transaction?.status]} />
                            </OnHoverTooltip>
                            <span className="margin-l-8">
                                {TransactionTypes.find(t => t.value === transaction.type)?.text || "View"}
                            </span>
                        </ImTransactionDetailsLink>) : constants.emptyPlaceholder
                }
            </ValueBlocker>
        </Preloader>
    )
}
