import React from "react";
import { Switch, Route, Redirect } from "react-router";
import { routes } from "../../constants";
import { Resources } from "./Resources";

export const ResourcesView: React.FC = () => {
    return (
        <div className="container resources">
            <Switch>
                <Route path={routes.resourcesWebinar} component={Resources} />
                <Redirect to={routes.notFound} />
            </Switch>
        </div>
    );
};

