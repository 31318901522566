import { alertOptionTooltipText } from '../../../../../../constants/amr-pipeline/alertOptions';
import { alertDescriptions } from '../../../../../../constants/cloManagers/alertDescriptions';
import { cloImApRequiredFeatures } from '../../../../../../constants/cloManagers/cloImApRequiredFeatures';
import { SubscriptionFeature } from '../../../../../../types/billing/SubscriptionFeature';
import { AlertOption } from '../../../../../../types/email-preferences/EmailPreferences';
import { CompanyAlertsValue, SecondaryStatsAlertsValue} from '../../../../../../types/user-config/UserConfig';
import { UserConfigType } from '../../../../../../types/user-config/UserConfigType';
import { ActionBlocker } from '../../../../../access/ActionBlocker';
import EmailInfoTooltip from "../../../../../common/EmailInfoTooltip";
import { ColumnBuilder } from '../../../../../bidding/common/table/columns/column-builder/ColumnBuilder';
import { IColumnDefinition } from '../../../../../bidding/common/table/types/ColumnDefinition';
import { OnHoverTooltip } from '../../../../../common';
import { RadioButton } from '../../../../../controls';
import { MultiTableHeaderRadioButton } from '../MultiTableHeaderRadioButton';

interface ManagerColumns {
    legalName: string;
    cloManagerImAlerts: CompanyAlertsValue;
    cloManagerApAlerts: CompanyAlertsValue;
    cloManagerBwicAlerts: SecondaryStatsAlertsValue;
    cloManagerDiAlerts: SecondaryStatsAlertsValue;
}

const cloManagersImAlertsColumnsDefinition: IColumnDefinition<ManagerColumns>[] =
    [
        {
            columnKey: "managerName",
            renderColumnHeaderContent: () => "Manager Name",
            renderColumnContent: (alert) => {
                return (
                    <div className="filter-name-text text-ellipsis">
                        <OnHoverTooltip overlay={alert.legalName}>
                            {alert.legalName}
                        </OnHoverTooltip>
                    </div>
                );
            },
            className: "data-list-cell-xl",
            sortingField: "legalName",
            sortingType: "string",
            stickLeft: true,
        },
        {
            columnKey: "im-description",
            renderColumnHeaderContent: () => (
                <span className="text-medium">
                    Issuance Monitor{" "}
                    <EmailInfoTooltip overlayText={alertDescriptions.issuanceMonitor} />
                </span>
            ),
            renderColumnContent: (alert) => "",
            className: "data-list-cell-lg",
        },
        {
            columnKey: "im-never",
            renderColumnHeaderContent: ({
                cloManagerImAlerts,
                onHeaderClick,
                disabled,
            }) => (
                <MultiTableHeaderRadioButton
                    type={UserConfigType.cloManagerIssuanceMonitorAlert}
                    option={AlertOption.Never}
                    allOptions={cloManagerImAlerts}
                    onChange={onHeaderClick}
                    disabled={disabled}
                    features={cloImApRequiredFeatures}
                />
            ),
            renderColumnContent: (alertValue, { disabled, onColumnClick }) => {
                const { companyReferenceName, alertOption } =
                    alertValue.cloManagerImAlerts;
                const name = "im-" + companyReferenceName;
                return (
                    <ActionBlocker features={cloImApRequiredFeatures} requireAllFeatures>
                        {(blocked) => (
                            <RadioButton
                                disabled={disabled || blocked}
                                key={name}
                                name={name}
                                checked={alertOption === AlertOption.Never || blocked}
                                onChange={() =>
                                    onColumnClick(
                                        UserConfigType.cloManagerIssuanceMonitorAlert,
                                        companyReferenceName,
                                        AlertOption.Never
                                    )
                                }
                            />
                        )}
                    </ActionBlocker>
                );
            },
            className: "data-list-cell-control data-list-cell-xs",
        },
        {
            columnKey: "im-daily",
            renderColumnHeaderContent: ({
                cloManagerImAlerts,
                onHeaderClick,
                disabled,
            }) => (
                <>
                    <MultiTableHeaderRadioButton
                        type={UserConfigType.cloManagerIssuanceMonitorAlert}
                        option={AlertOption.Daily}
                        allOptions={cloManagerImAlerts}
                        onChange={onHeaderClick}
                        disabled={disabled}
                        features={cloImApRequiredFeatures}
                    />
                    <EmailInfoTooltip
                        overlayText="Alerts are sent at 5 PM EST every business day excluding holidays if manager’s transactions are published or updated"
                    />
                </>
            ),
            renderColumnContent: (alertValue, { disabled, onColumnClick }) => {
                const { companyReferenceName, alertOption } =
                    alertValue.cloManagerImAlerts;
                const name = "im-" + companyReferenceName;
                return (
                    <ActionBlocker features={cloImApRequiredFeatures} requireAllFeatures>
                        {(blocked) => (
                            <RadioButton
                                disabled={disabled || blocked}
                                key={name}
                                name={name}
                                checked={blocked ? false : alertOption === AlertOption.Daily}
                                onChange={() =>
                                    onColumnClick(
                                        UserConfigType.cloManagerIssuanceMonitorAlert,
                                        companyReferenceName,
                                        AlertOption.Daily
                                    )
                                }
                            />
                        )}
                    </ActionBlocker>
                );
            },
            className: "data-list-cell-control",
        },
        {
            columnKey: "im-instant",
            renderColumnHeaderContent: ({
                cloManagerImAlerts,
                onHeaderClick,
                disabled,
            }) => (
                <>
                    <MultiTableHeaderRadioButton
                        type={UserConfigType.cloManagerIssuanceMonitorAlert}
                        option={AlertOption.Instant}
                        allOptions={cloManagerImAlerts}
                        onChange={onHeaderClick}
                        disabled={disabled}
                        features={cloImApRequiredFeatures}
                    />
                    <EmailInfoTooltip
                        overlayText="Alerts are sent once manager’s transactions are published or updated"
                    />
                </>
            ),
            renderColumnContent: (alertValue, { disabled, onColumnClick }) => {
                const { companyReferenceName, alertOption } =
                    alertValue.cloManagerImAlerts;
                const name = "im-" + companyReferenceName;
                return (
                    <ActionBlocker features={cloImApRequiredFeatures} requireAllFeatures>
                        {(blocked) => (
                            <RadioButton
                                disabled={disabled || blocked}
                                key={name}
                                name={name}
                                checked={blocked ? false : alertOption === AlertOption.Instant}
                                onChange={() =>
                                    onColumnClick(
                                        UserConfigType.cloManagerIssuanceMonitorAlert,
                                        companyReferenceName,
                                        AlertOption.Instant
                                    )
                                }
                            />
                        )}
                    </ActionBlocker>
                );
            },
            className: "data-list-cell-sm-01 data-list-cell-control data-list-cell-right-border",
        },
    ];

const cloManagersApAlertsColumnsDefinition: IColumnDefinition<ManagerColumns>[] =
    [
        {
            columnKey: "ap-description",
            renderColumnHeaderContent: () => (
                <span className="text-medium">
                    Arranger Pipeline{" "}
                    <EmailInfoTooltip overlayText={alertDescriptions.arrangersPipeline} />
                </span>
            ),
            renderColumnContent: (alert) => "",
            className: "data-list-cell-lg",
        },
        {
            columnKey: "ap-never",
            renderColumnHeaderContent: ({
                cloManagerApAlerts,
                onHeaderClick,
                disabled,
            }) => (
                <MultiTableHeaderRadioButton
                    type={UserConfigType.cloManagerArrangerPipelineAlert}
                    option={AlertOption.Never}
                    allOptions={cloManagerApAlerts}
                    onChange={onHeaderClick}
                    disabled={disabled}
                    features={cloImApRequiredFeatures}
                />
            ),
            renderColumnContent: (alertValue, { disabled, onColumnClick }) => {
                const { companyReferenceName, alertOption } =
                    alertValue.cloManagerApAlerts;
                const name = "ap-" + companyReferenceName;
                return (
                    <ActionBlocker features={cloImApRequiredFeatures} requireAllFeatures>
                        {(blocked) => (
                            <RadioButton
                                disabled={disabled || blocked}
                                key={name}
                                name={name}
                                checked={alertOption === AlertOption.Never || blocked}
                                onChange={() =>
                                    onColumnClick(
                                        UserConfigType.cloManagerArrangerPipelineAlert,
                                        companyReferenceName,
                                        AlertOption.Never
                                    )
                                }
                            />
                        )}
                    </ActionBlocker>
                );
            },
            className: "data-list-cell-control data-list-cell-xs",
        },
        {
            columnKey: "ap-daily",
            renderColumnHeaderContent: ({
                cloManagerApAlerts,
                onHeaderClick,
                disabled,
            }) => (
                <>
                    <MultiTableHeaderRadioButton
                        type={UserConfigType.cloManagerArrangerPipelineAlert}
                        option={AlertOption.Daily}
                        allOptions={cloManagerApAlerts}
                        onChange={onHeaderClick}
                        disabled={disabled}
                        features={cloImApRequiredFeatures}
                    />
                    <EmailInfoTooltip
                        overlayText={alertOptionTooltipText[AlertOption.Daily]}
                    />
                </>
            ),
            renderColumnContent: (alertValue, { disabled, onColumnClick }) => {
                const { companyReferenceName, alertOption } =
                    alertValue.cloManagerApAlerts;
                const name = "ap-" + companyReferenceName;
                return (
                    <ActionBlocker features={cloImApRequiredFeatures} requireAllFeatures>
                        {(blocked) => (
                            <RadioButton
                                disabled={disabled || blocked}
                                key={name}
                                name={name}
                                checked={blocked ? false : alertOption === AlertOption.Daily}
                                onChange={() =>
                                    onColumnClick(
                                        UserConfigType.cloManagerArrangerPipelineAlert,
                                        companyReferenceName,
                                        AlertOption.Daily
                                    )
                                }
                            />
                        )}
                    </ActionBlocker>
                );
            },
            className: "data-list-cell-control",
        },
        {
            columnKey: "ap-instant",
            renderColumnHeaderContent: ({
                cloManagerApAlerts,
                onHeaderClick,
                disabled,
            }) => (
                <>
                    <MultiTableHeaderRadioButton
                        type={UserConfigType.cloManagerArrangerPipelineAlert}
                        option={AlertOption.Instant}
                        allOptions={cloManagerApAlerts}
                        onChange={onHeaderClick}
                        disabled={disabled}
                        features={cloImApRequiredFeatures}
                    />
                    <EmailInfoTooltip
                        overlayText="Alerts are sent once manager’s upcoming transactions are published on Arranger Pipeline"
                    />
                </>
            ),
            renderColumnContent: (alertValue, { disabled, onColumnClick }) => {
                const { companyReferenceName, alertOption } =
                    alertValue.cloManagerApAlerts;
                const name = "ap-" + companyReferenceName;
                return (
                    <ActionBlocker features={cloImApRequiredFeatures} requireAllFeatures>
                        {(blocked) => (
                            <RadioButton
                                disabled={disabled || blocked}
                                key={name}
                                name={name}
                                checked={blocked ? false : alertOption === AlertOption.Instant}
                                onChange={() =>
                                    onColumnClick(
                                        UserConfigType.cloManagerArrangerPipelineAlert,
                                        companyReferenceName,
                                        AlertOption.Instant
                                    )
                                }
                            />
                        )}
                    </ActionBlocker>
                );
            },
            className: "data-list-cell-sm-01 data-list-cell-control",
        },
    ];

const cloManagersBwicAlertsColumnsDefinition: IColumnDefinition<ManagerColumns>[] = [
    {
        columnKey: "bwic-description",
        renderColumnHeaderContent: () => (
            <span className="text-medium">
                BWIC
                <EmailInfoTooltip overlayText={alertDescriptions.bwic} />
            </span>
        ),
        renderColumnContent: () => "",
        className: "data-list-cell-xs-sm",
    },
    {
        columnKey: "bwic-never",
        renderColumnHeaderContent: ({
            cloManagerBwicAlerts,
            onHeaderClick,
            disabled,
        }) => (
            <MultiTableHeaderRadioButton
                type={UserConfigType.cloManagerBwicAlert}
                option={AlertOption.Never}
                allOptions={cloManagerBwicAlerts}
                onChange={onHeaderClick}
                disabled={disabled}
                feature={SubscriptionFeature.BwicMonitorAlerts}
            />
        ),
        renderColumnContent: (alertValue, { disabled, onColumnClick }) => {
            const { companyReferenceName, alertOption } =
                alertValue.cloManagerBwicAlerts;
            const name = "bwic-" + companyReferenceName;
            return (
                <ActionBlocker feature={SubscriptionFeature.BwicMonitorAlerts}>
                    {(blocked) => (
                        <RadioButton
                            disabled={disabled || blocked}
                            key={name}
                            name={name}
                            checked={alertOption === AlertOption.Never || blocked}
                            onChange={() =>
                                onColumnClick(
                                    UserConfigType.cloManagerBwicAlert,
                                    companyReferenceName,
                                    AlertOption.Never
                                )
                            }
                        />
                    )}
                </ActionBlocker>
            )
        },
        className: "data-list-cell-control",
    },
    {
        columnKey: "bwic-instant",
        renderColumnHeaderContent: ({
            cloManagerBwicAlerts,
            onHeaderClick,
            disabled,
        }) => (
            <>
                <MultiTableHeaderRadioButton
                    type={UserConfigType.cloManagerBwicAlert}
                    option={AlertOption.Instant}
                    allOptions={cloManagerBwicAlerts}
                    onChange={onHeaderClick}
                    disabled={disabled}
                    feature={SubscriptionFeature.BwicMonitorAlerts}
                />
                <EmailInfoTooltip
                    overlayText="Alerts are sent once the BWIC is posted/Post-BWIC color is added on manager's security."
                />
            </>
        ),
        renderColumnContent: (alertValue, { disabled, onColumnClick }) => {
            const { companyReferenceName, alertOption } =
                alertValue.cloManagerBwicAlerts;
            const name = "bwic-" + companyReferenceName;
            return (
                <ActionBlocker feature={SubscriptionFeature.BwicMonitorAlerts}>
                    {(blocked) => (
                        <RadioButton
                            disabled={disabled || blocked}
                            key={name}
                            name={name}
                            checked={blocked ? false : alertOption === AlertOption.Instant}
                            onChange={() =>
                                onColumnClick(
                                    UserConfigType.cloManagerBwicAlert,
                                    companyReferenceName,
                                    AlertOption.Instant
                                )
                            }
                        />
                    )}
                </ActionBlocker>
            )
        },
        className: "data-list-cell-sm data-list-cell-control data-list-cell-right-border",
    },
];

const cloManagersDiAlertsColumnsDefinition: IColumnDefinition<ManagerColumns>[] = [
    {
        columnKey: "di-description",
        renderColumnHeaderContent: () => (
            <span className="text-medium">
                Inventory
                <EmailInfoTooltip overlayText={alertDescriptions.dealerInventory} />
            </span>
        ),
        renderColumnContent: () => "",
        className: "data-list-cell-sm-01",
    },
    {
        columnKey: "di-never",
        renderColumnHeaderContent: ({
            cloManagerDealerInventoryAlert,
            onHeaderClick,
            disabled,
        }) => (
            <MultiTableHeaderRadioButton
                type={UserConfigType.cloManagerDealerInventoryAlert}
                option={AlertOption.Never}
                allOptions={cloManagerDealerInventoryAlert}
                onChange={onHeaderClick}
                disabled={disabled}
                feature={SubscriptionFeature.InventoryAlerts}
            />
        ),
        renderColumnContent: (alertValue, { disabled, onColumnClick }) => {
            const { companyReferenceName, alertOption } =
                alertValue.cloManagerDiAlerts;
            const name = "di-" + companyReferenceName;
            return (
                <ActionBlocker feature={SubscriptionFeature.InventoryAlerts}>
                    {(blocked) => (
                        <RadioButton
                            disabled={disabled || blocked}
                            key={name}
                            name={name}
                            checked={alertOption === AlertOption.Never || blocked}
                            onChange={() =>
                                onColumnClick(
                                    UserConfigType.cloManagerDealerInventoryAlert,
                                    companyReferenceName,
                                    AlertOption.Never,
                                )
                            }
                        />
                    )}
                </ActionBlocker>
            );
        },
        className: "data-list-cell-control",
    },
    {
        columnKey: "di-instant",
        renderColumnHeaderContent: ({
            cloManagerDealerInventoryAlert,
            onHeaderClick,
            disabled,
        }) => (
            <>
                <MultiTableHeaderRadioButton
                    type={UserConfigType.cloManagerDealerInventoryAlert}
                    option={AlertOption.Instant}
                    allOptions={cloManagerDealerInventoryAlert}
                    onChange={onHeaderClick}
                    disabled={disabled}
                    feature={SubscriptionFeature.InventoryAlerts}
                />
                <EmailInfoTooltip
                    overlayText="Alerts are sent once manager`s security  was added/updated/marked as traded on the Dealer Inventory."
                />
            </>
        ),
        renderColumnContent: (alertValue, { disabled, onColumnClick }) => {
            const { companyReferenceName, alertOption } =
                alertValue.cloManagerDiAlerts;
            const name = "di-" + companyReferenceName;
            return (
                <ActionBlocker feature={SubscriptionFeature.InventoryAlerts}>
                    {(blocked) => (
                        <RadioButton
                            disabled={disabled || blocked}
                            key={name}
                            name={name}
                            checked={blocked ? false : alertOption === AlertOption.Instant}
                            onChange={() =>
                                onColumnClick(
                                    UserConfigType.cloManagerDealerInventoryAlert,
                                    companyReferenceName,
                                    AlertOption.Instant
                                )
                            }
                        />
                    )}
                </ActionBlocker>
            );
        },
        className: "data-list-cell-sm data-list-cell-control data-list-cell-right-border",
    },
];

export const cloManagerAlertsColumns = [
    ...cloManagersBwicAlertsColumnsDefinition,
    ...cloManagersDiAlertsColumnsDefinition,
    ...cloManagersImAlertsColumnsDefinition,
    ...cloManagersApAlertsColumnsDefinition,
].map((c) => new ColumnBuilder(c));
