import { Fragment } from 'react'
import moment from "moment";
import { OriginatingTransaction } from "../../../../../types/amr-pipeline/models/OriginatingTransaction";
import { IssuanceSpreadBase, Spread } from "../../../../../types/dashboard/IssuanceSpread";
import { Currency } from "../../../../../types/enums/Currency";
import { isRequestSuccess } from "../../../../../utils";
import { EmptyPlaceholder, Preloader } from "../../../../common";
import { SectionContent } from "../../../common/section/SectionContent";
import { TabBody } from "../../../common/tabs/TabBody";
import { ComparableDealTable } from "./ComparableDealTable";
import { ComparativeMetricsWidget } from "./ComparativeMetricsWidget";
import { useDealComparativeMetrics } from "./hooks/useDealComparativeMetrics";
import { constants } from "../../../../../constants";
import { collateralTypesAbbr } from "../../../../../constants/collateral-types";
import { TransactionTypes } from '../../../../../types/amr-pipeline/enums/TransactionType';
import { EmptyMarkerSpread } from '../../../../dashboard/usBslNewIssueSpreads/IssuanceSpreadChart';

interface Props {
    transaction: OriginatingTransaction
    isLoading: boolean
}

const STATS_DAYS = 30;

export function isSpreadEmpty(spread?: Spread) {
    return !spread?.count
}

export function ComparativeMetrics({ transaction, isLoading }: Props) {
    const { requestState, metrics } =
        useDealComparativeMetrics(transaction.dealReferenceName, transaction.referenceName);

    const createSpreadFromSingleValue = (value?: number): Spread =>
        value == null ? {
            min: 0,
            q1: 0,
            median: metrics?.statsWAS ?? 0,
            q3: 0,
            max: 0,
            count: 1,
            emptyMarker: true
        } as EmptyMarkerSpread : {
            min: value,
            q1: value,
            median: value,
            q3: value,
            max: value,
            count: 1,
        }

    const comparableSpread: IssuanceSpreadBase = {
        was: metrics?.statsWAS,
        wal: metrics?.statsWAL,
        warf: metrics?.statsWARF,
        ds: metrics?.statsDS
    }

    const dealSpread: IssuanceSpreadBase = {
        was: createSpreadFromSingleValue(metrics?.currentWAS),
        wal: createSpreadFromSingleValue(metrics?.currentWAL),
        warf: createSpreadFromSingleValue(metrics?.currentWARF),
        ds: createSpreadFromSingleValue(metrics?.currentDS)
    }

    const getCurrency = () => {
        if (transaction.currency?.code === Currency.USD) return 'US';
        if (transaction.currency?.code === Currency.EUR) return 'EU';
        return '';
    }

    const getTerm = () => {
        if (metrics?.isLong == null) return ''
        return metrics?.isLong ? 'long-term' : 'short-term '
    }

    const getTransactionType = () =>
        TransactionTypes.find(x => x.value === transaction.type)?.text.toLowerCase()

    const renderDescription = () => [
        <Fragment key="0"><b>Active</b> and <b key="0">Priced</b></Fragment>,
        <Fragment key="1">{getCurrency() ? <b>{getCurrency()}</b> : null}</Fragment>,
        <b key="2">{collateralTypesAbbr[transaction.collateralType]}</b>,
        <Fragment key="3">{getTerm() ? <b>{getTerm()}</b> : null}</Fragment>,
        <b key="4">{getTransactionType()}</b>,
        `deals on the Issuance Monitor ${STATS_DAYS} days before`,
        moment(metrics?.date).format(constants.dateFormat),
        <Fragment key="5">(<b>{metrics?.count.toString()}</b> transactions).</Fragment>
    ]
        .filter(x => x)
        .map(x => [x, ' '])
        .flat();

    const hasData = isRequestSuccess(requestState) && !!metrics?.count

    const renderContent = () =>
        <>
            <SectionContent title="Comparative Metrics">
                <p className="comparative-metrics-info">{renderDescription()}</p>
                <div className="metrics-chart-row">
                    <div className="metrics-chart-col">
                        <ComparativeMetricsWidget
                            title="WAS *"
                            description="* Weighted Average Spread"
                            divId="us-bsl-new-issue-spreads-was-chart"
                            field="was"
                            requestState={requestState}
                            comparableSpread={comparableSpread}
                            dealSpread={dealSpread}
                            chartNameLimitedPlan="WAS"
                        />
                    </div>
                    <div className="metrics-chart-col">
                        <ComparativeMetricsWidget
                            title="WAL *"
                            description="* Weighted Average Life"
                            divId="us-bsl-new-issue-spreads-wal-chart"
                            field="wal"
                            requestState={requestState}
                            comparableSpread={comparableSpread}
                            dealSpread={dealSpread}
                            chartNameLimitedPlan="WAL"
                        />
                    </div>
                    <div className="metrics-chart-col">
                        <ComparativeMetricsWidget
                            title="WARF *"
                            description="* Weighted Average Rating Factor"
                            divId="us-bsl-new-issue-spreads-warf-chart"
                            field="warf"
                            requestState={requestState}
                            comparableSpread={comparableSpread}
                            dealSpread={dealSpread}
                            chartNameLimitedPlan="WARF"
                        />
                    </div>
                    <div className="metrics-chart-col">
                        <ComparativeMetricsWidget
                            title="DS *"
                            description="* Diversity Score"
                            divId="us-bsl-new-issue-spreads-ds-chart"
                            field="ds"
                            requestState={requestState}
                            comparableSpread={comparableSpread}
                            dealSpread={dealSpread}
                            chartNameLimitedPlan="DS"
                        />
                    </div>
                </div>
            </SectionContent>
            {
                metrics?.comparableDeals.length &&
                <SectionContent title="Reference Transactions" className="">
                    <ComparableDealTable 
                        currentTransaction={transaction}
                        deals={metrics.comparableDeals} 
                    />
                </SectionContent>
            }
        </>

    return (
        <Preloader inProgress={isLoading || !isRequestSuccess(requestState)}>
            <TabBody className="tabs-data-padding component-comparable-metrics">
                {hasData ? renderContent() : <EmptyPlaceholder text="No information to display." />}
            </TabBody>
        </Preloader>
    )
}
