import { Link } from 'react-router-dom';
import { capitalize, isNil, startCase } from 'lodash';
import { SORTING_TYPE, constants, roles, routes } from '../../../../constants';
import IconSVG from '../../../../styles/svg-icons';
import { Tranche } from '../../../../types/amr-pipeline/models/Tranche';
import { amrFormatUtils, formatUtils, moneyUtils } from '../../../../utils';
import { ColumnBuilder } from '../../../bidding/common/table/columns/column-builder/ColumnBuilder';
import { IColumnDefinition } from '../../../bidding/common/table/types/ColumnDefinition';
import { OnHoverTooltip } from '../../../common';
import { TrancheStatus } from '../../../../types/amr-pipeline/enums/TrancheStatus';
import { DealsTabType } from '../../../../types/deals/DealsTabType';
import { Deal } from '../../../../types/amr-pipeline/models/Deal';
import { user } from '../../../../user';

interface AllClassesColumnsContext {
    deal: Deal;
}

const ExcludedColumnns = [
    'status',
    'transactionReferenceAndClass',
];

const allClassesColumnDefinitions: IColumnDefinition<Tranche, AllClassesColumnsContext>[] = [
    {
        columnKey: 'name',
        renderColumnHeaderContent: () => 'Class',
        renderColumnContent: (tranche, context) => {
            if (tranche.status !== TrancheStatus.Active) {
                return tranche.name;
            }

            return (
                <Link to={routes.dealsUrl(context.deal.referenceName, DealsTabType.ClassDetail, tranche.referenceName)}>
                    {tranche.name}
                </Link>
            );
        },
        sortingField: 'name',
        sortingType: SORTING_TYPE.string,
        stickLeft: true,
    },
    {
        columnKey: 'status',
        renderColumnHeaderContent: () => 'Status',
        renderColumnContent: (tranche) => {
            return tranche.status;
        },
        sortingField: 'status',
        sortingType: SORTING_TYPE.string,
    },
    {
        columnKey: 'transactionClosingDate',
        renderColumnHeaderContent: () => 'Closing',
        renderColumnContent: (tranche) => {
            return formatUtils.formatDate(tranche.transactionClosingDate);
        },
        sortingField: 'transactionClosingDate',
        sortingType: SORTING_TYPE.date,
    },
    {
        columnKey: 'transactionType',
        renderColumnHeaderContent: () => 'Trans. Type',
        renderColumnContent: (tranche) => {
            return tranche.transactionType
                ? startCase(tranche.transactionType)
                : constants.emptyPlaceholder;
        },
        sortingField: 'transactionType',
        sortingType: SORTING_TYPE.string,
        className: 'data-list-cell-sm-01',
    },
    {
        columnKey: 'link',
        renderColumnHeaderContent: () => '',
        renderColumnContent: (tranche, context) => {
            if (!tranche.transactionReferenceName) {
                return null;
            }

            return (
                <OnHoverTooltip overlay="See on Issuance Monitor">
                    <button className="btn-link" onClick={() => {
                        window.open(routes.transactionDetailUrl(tranche.transactionReferenceName, context.deal.referenceName))
                    }}>
                        <IconSVG name="go-to" width={16} height={16} />
                    </button>
                </OnHoverTooltip>
            );
        },
        className: 'data-list-cell-xxxs',
    },
    {
        columnKey: 'ticker144A',
        renderColumnHeaderContent: () => 'Ticker (Rule 144A)',
        renderColumnContent: (tranche) => {
            return tranche.ticker144A || constants.emptyPlaceholder;
        },
        className: 'data-list-cell-lg',
    },
    {
        columnKey: 'cusiP144A',
        renderColumnHeaderContent: () => 'CUSIP (Rule 144A)',
        renderColumnContent: (tranche) => {
            return tranche.cusip144A || constants.emptyPlaceholder;
        },
        className: 'data-list-cell-md',
    },
    {
        columnKey: 'isiN144A',
        renderColumnHeaderContent: () => 'ISIN (Rule 144A)',
        renderColumnContent: (tranche) => {
            return tranche.isin144A || constants.emptyPlaceholder;
        },
        className: 'data-list-cell-md',
    },
    {
        columnKey: 'tickerRegS',
        renderColumnHeaderContent: () => 'Ticker (Reg S)',
        renderColumnContent: (tranche) => {
            return tranche.tickerRegS || constants.emptyPlaceholder;
        },
        className: 'data-list-cell-lg',
    },
    {
        columnKey: 'cusipRegS',
        renderColumnHeaderContent: () => 'CUSIP (Reg S)',
        renderColumnContent: (tranche) => {
            return tranche.cusipRegS || constants.emptyPlaceholder;
        },
        className: 'data-list-cell-sm',
    },
    {
        columnKey: 'isinRegS',
        renderColumnHeaderContent: () => 'ISIN (Reg S)',
        renderColumnContent: (tranche) => {
            return tranche.isinRegS || constants.emptyPlaceholder;
        },
        className: 'data-list-cell-sm',
    },
    {
        columnKey: 'commonCodeRegS',
        renderColumnHeaderContent: () => 'Common Code (Reg S)',
        renderColumnContent: (tranche) => {
            return tranche.commonCodeRegS || constants.emptyPlaceholder;
        },
        className: 'data-list-cell-lg text-right',
    },
    {
        columnKey: 'tickerAccdInvCertif',
        renderColumnHeaderContent: () => 'Ticker (Acc’d Inv/Certif.)',
        renderColumnContent: (tranche) => {
            return tranche.tickerAccdInvCertif || constants.emptyPlaceholder;
        },
        className: 'data-list-cell-lg',
    },
    {
        columnKey: 'cusipAccdInvCertif',
        renderColumnHeaderContent: () => 'CUSIP (Acc’d Inv/Certif.)',
        renderColumnContent: (tranche) => {
            return tranche.cusipAccdInvCertif || constants.emptyPlaceholder;
        },
        className: 'data-list-cell-lg',
    },
    {
        columnKey: 'isinAccdInvCertif',
        renderColumnHeaderContent: () => 'ISIN (Acc’d Inv/Certif.)',
        renderColumnContent: (tranche) => {
            return tranche.isinAccdInvCertif || constants.emptyPlaceholder;
        },
        className: 'data-list-cell-lg',
    },
    {
        columnKey: 'originalBalance',
        renderColumnHeaderContent: () => 'Orig. Balance',
        renderColumnContent: (tranche) => {
            return isNil(tranche.originalBalance)
                ? constants.emptyPlaceholder
                : moneyUtils.money(tranche.originalBalance);
        },
        className: 'data-list-cell-sm text-right',
    },
    {
        columnKey: 'balance',
        renderColumnHeaderContent: () => 'Cur. Balance',
        renderColumnContent: (tranche) => {
            return isNil(tranche.balance)
                ? constants.emptyPlaceholder
                : moneyUtils.money(tranche.balance);
        },
        className: 'data-list-cell-sm text-right',
    },
    {
        columnKey: 'originalMargin',
        renderColumnHeaderContent: () => 'Orig. Margin, %',
        renderColumnContent: (tranche) => {
            return isNil(tranche.originalMargin)
                ? constants.emptyPlaceholder
                : formatUtils.formatMargin(tranche.originalMargin)
        },
        className: 'data-list-cell-sm-01',
    },
    {
        columnKey: 'margin',
        renderColumnHeaderContent: () => 'Cur. Margin, %',
        renderColumnContent: (tranche) => {
            return isNil(tranche.margin)
                ? constants.emptyPlaceholder
                : formatUtils.formatMargin(tranche.margin)
        },
        className: 'data-list-cell-sm-01',
    },
    {
        columnKey: 'coupon',
        renderColumnHeaderContent: () => 'Coupon',
        renderColumnContent: (tranche) => {
            return tranche.coupon || constants.emptyPlaceholder;
        },
        className: 'data-list-cell-md',
    },
    {
        columnKey: 'minimumDenomination',
        renderColumnHeaderContent: () => 'Min. Denomination',
        renderColumnContent: (tranche) => {
            return isNil(tranche.minimumDenomination)
                ? constants.emptyPlaceholder
                : moneyUtils.money(tranche.minimumDenomination);
        },
        className: 'data-list-cell-sm text-right',
    },
    {
        columnKey: 'type',
        renderColumnHeaderContent: () => 'Type',
        renderColumnContent: (tranche) => {
            return tranche.type || constants.emptyPlaceholder;
        },
        className: 'data-list-cell-lg',
    },
    {
        columnKey: 'factor',
        renderColumnHeaderContent: () => 'Factor',
        renderColumnContent: (tranche) => {
            return isNil(tranche.factor)
                ? constants.emptyPlaceholder
                : formatUtils.formatDecimal(tranche.factor, 4);
        },
        className: 'data-list-cell-xs text-right',
    },
    {
        columnKey: 'equity',
        renderColumnHeaderContent: () => 'Equity',
        renderColumnContent: (tranche) => {
            return capitalize(formatUtils.formatBoolean(tranche.equity));
        },
        className: 'data-list-cell-xs',
    },
    {
        columnKey: 'redeemed',
        renderColumnHeaderContent: () => 'Redeemed',
        renderColumnContent: (tranche) => {
            return capitalize(formatUtils.formatBooleanWithPlaceholder(tranche.redeemed));
        },
    },
    {
        columnKey: 'amr',
        renderColumnHeaderContent: () => 'AMR Class',
        renderColumnContent: (tranche) => {
            return capitalize(formatUtils.formatBoolean(tranche.amr));
        },
    },
    {
        columnKey: 'nonAmrPeriod',
        renderColumnHeaderContent: () => 'Non-AMR',
        renderColumnContent: (tranche) => {
            return tranche.nonAmrPeriod
                ? `${tranche.nonAmrPeriod} months`
                : constants.emptyPlaceholder;
        },
    },
    {
        columnKey: 'nonAmrPeriodEnd',
        renderColumnHeaderContent: () => 'Non-AMR Period End',
        renderColumnContent: (tranche) => {
            return formatUtils.formatDate(tranche.nonAmrPeriodEnd);
        },
        className: 'data-list-cell-md',
    },
    {
        columnKey: 'originalRating',
        renderColumnHeaderContent: () => 'Orig. Rtg (M/S/F/K/D)',
        renderColumnContent: (tranche) => {
            return amrFormatUtils.formatOriginalRating(tranche);
        },
        className: 'data-list-cell-lg',
    },
    {
        columnKey: 'currentRating',
        renderColumnHeaderContent: () => 'Cur. Rtg (M/S/F/K/D)',
        renderColumnContent: (tranche) => {
            return amrFormatUtils.formatCurrentRating(tranche);
        },
        className: 'data-list-cell-lg',
    },
    {
        columnKey: 'currency',
        renderColumnHeaderContent: () => 'Currency',
        renderColumnContent: (tranche, content) => {
            return content.deal.currencyCode || constants.emptyPlaceholder;
        },
        className: 'data-list-cell-xs',
    },
    {
        columnKey: 'pariPassuTranches',
        renderColumnHeaderContent: () => 'Pari-Passu Classes',
        renderColumnContent: (tranche) => {
            return tranche.pariPassuTranches || constants.emptyPlaceholder;
        },
        className: 'data-list-cell-md',
    },
    {
        columnKey: 'trancheSupported',
        renderColumnHeaderContent: () => 'Class Supported',
        renderColumnContent: (tranche) => {
            return tranche.trancheSupported || constants.emptyPlaceholder;
        },
        className: 'data-list-cell-lg',
    },
    {
        columnKey: 'transactionReferenceAndClass',
        renderColumnHeaderContent: () => 'Trans. Reference & Class',
        renderColumnContent: (tranche) => {
            if (!tranche.transactionReferenceName || !tranche.linkedOtcReferenceName) {
                return constants.emptyPlaceholder;
            }

            return `${tranche.transactionReferenceName}, ${tranche.linkedOtcName}`;
        },
        className: 'data-list-cell-xl-xxl',
    },
];

export const getAllClassesColumns = () => {
    const columnDefinitions = user.hasRoles(...roles.bd(), ...roles.seller(), roles.Media)
        ? allClassesColumnDefinitions.filter(({ columnKey }) => !ExcludedColumnns.includes(columnKey))
        : allClassesColumnDefinitions;

    return columnDefinitions.map((c) => new ColumnBuilder(c));
};
