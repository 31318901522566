import { Fragment } from "react/jsx-runtime";
import { Player } from "../common/Player";

interface LinkData {
    text: string;
    href: string;
}

interface Props {
    title: string;
    text: string;
    videoUrl?: string;
    links?: LinkData[];
}

export function ResourceSectionContent({
    title,
    text,
    videoUrl,
    links,
}: Props) {
    return (
        <div className="resources-item flex-row">
            <div className="resources-item-details">
                <h2 className="resources-item-name">{title}</h2>

                <div className="resources-item-desc">{text}</div>

                {!!links?.length && (
                    <div className="resources-item-links flex-row">
                        {links.map(({ text, href }, index) => (
                            <Fragment key={text}>
                                {!!index && (
                                    <span className="vertical-divider vertical-divider-light" />
                                )}
                                <a
                                    className="btn btn-link secondary regular"
                                    href={href}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {text}
                                </a>
                            </Fragment>
                        ))}
                    </div>
                )}
            </div>

            {!!videoUrl && (
                <div className="resources-item-media">
                    <Player url={videoUrl} width={384} height={216} />
                </div>
            )}
        </div>
    );
}

