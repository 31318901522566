import { useState } from "react";
import { FlexRow } from "../common";
import { Popup, PopupBody, PopupFooter, RadioButton } from "../controls";
import { newBWICActions, processActions } from '../../actions';
import IconSVG from "../../styles/svg-icons";
import { useAppDispatch } from "../../effects/useAppDispatch";


interface Props {
    isAllToAllDefault?: boolean | null
}
export default function TradingProtocolPopup({ isAllToAllDefault }: Props) {
    const dispatch = useAppDispatch()

    const [visible, setVisible] = useState(true);
    const [isAllToAll, setIsAllToAll] = useState(isAllToAllDefault);


    if (!visible) return null;

    const handleClosePopup = () => dispatch(newBWICActions.previousStep());

    return (
        <Popup
            title="Trading Protocol"
            modalClass="modal-trading-protocol"
            onClose={handleClosePopup}
            renderInBody
        >
            <PopupBody>
                <p>Select one Trading Protocol from the list below:</p>
                <div className="radio-group">
                    <RadioButton
                        label="DirectBidding™"
                        name="tradingProtocolPopup"
                        checked={isAllToAll === false}
                        onChange={() => setIsAllToAll(false)}
                    />
                    <p className="text-warm-grey">Prefer transparency and relationship-driven execution? Opt for DirectBidding™ to disclose your identity to broker-dealer while still sending anonymous BWICs to the buy-side.</p>
                    <RadioButton
                        label="All-to-All"
                        name="tradingProtocolPopup"
                        checked={isAllToAll === true}
                        onChange={() => setIsAllToAll(true)}
                    />
                    <p className="text-warm-grey">Looking for privacy and anonymity in trade execution? Send anonymous BWICs to both buy-side and sell-side platform members with our All-to-All trading protocol.</p>
                </div>
            </PopupBody>
            <PopupFooter>
                <FlexRow>
                    <button
                        type="button"
                        className="btn btn-ghost"
                        onClick={handleClosePopup}
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        className="btn btn-main btn-next"
                        disabled={isAllToAll == null}
                        onClick={() => {
                            setVisible(false);
                            dispatch(processActions.setAllToAllProtocol(isAllToAll));
                        }}
                    >
                        Next <IconSVG name="icon-expand" width={16} height={16} />
                    </button>
                </FlexRow>
            </PopupFooter>
        </Popup>
    )

};
