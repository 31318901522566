import React, { useCallback, useEffect, useMemo } from 'react';
import { some } from 'lodash';
import { brokerDealerContactListActions } from '../../../actions/broker-dealer-contact-list.actions';
import { brokerDealerUserListActions } from '../../../actions/broker-dealer-user-list.actions';
import { newBwicDealersActions } from '../../../actions/new-bwic-dealers.actions';
import { useSelector } from 'react-redux';
import { newBwicDealersSelector } from '../../../selectors/new-bwic-dealers.selector';
import { FlexRow, Preloader, SearchInput } from '../../common';
import { processActions } from "../../../actions";
import IconSVG from '../../../styles/svg-icons';
import { WizardHeader } from '../WizardHeader';
import { AppState } from '../../../types/state/AppState';
import { isRequestSuccess, shouldRequest, apiUtils } from '../../../utils';
import { DealerList } from '../../dealer-list/DealerList';
import { useDealersFilter } from '../../../effects/useDealersFilter';
import { dealerListActions } from '../../../actions/dealer-list.actions';
import { DealerListFilter } from '../../dealer-list/DealerListFilter';
import { CompanyStatus } from '../../../types/company/CompanyStatus';
import { getClearingBankConfirmedAgreementsByDealer } from '../../../utils/direct-bidding.utils';
import { DealerListFilterOption } from '../../../types/state/DealerListState';
import { useBrokerDealers } from '../../../effects/useBrokerDealers';
import { useSettlementAgentAgreements } from '../../../effects/useSettlementAgentAgreements';
import { BwicProcessType } from '../../../types/models/Process';
import SelectPlatformDealersPopup from '../SelectPlatformDealersPopup';
import { user } from '../../../user/user';
import { roles } from '../../../constants/roles';
import { RadioButton } from '../../controls';
import TradingProtocolPopup from '../TradingProtocolPopup';
import { InfoTooltip } from "../../common/InfoTooltip";
import { useAgreement } from '../../seller-buyside/useAgreement';
import { SettlementAgreementStatus } from '../../../types/bid-as-dealer/SettlementAgreementStatus';
import { appConfig } from '../../../app-config';
import { useAppDispatch } from '../../../effects/useAppDispatch';

export const NewBwicDealers: React.FC = () => {
    const dispatch = useAppDispatch();

    const brokerDealerListState = useBrokerDealers();
    const agreementsState = useSettlementAgentAgreements();

    const selectedDealers = useSelector((state: AppState) => state.newBwicDealers.selectedDealers);
    const favoriteDealers = useSelector((state: AppState) => state.favoriteBrokerDealers.favorites);
    const selectedByDefault = useSelector((state: AppState) => state.newBwicDealers.selectedByDefault);
    const brokerDealerContactsState = useSelector((state: AppState) => state.entities.brokerDealerContactList);
    const brokerDealerUsersState = useSelector((state: AppState) => state.entities.brokerDealerUserList);
    const searchTerm = useSelector((state: AppState) => state.newBwicDealers.filter.searchTerm);
    const filterOptions = useSelector((state: AppState) => state.newBwicDealers.filter.filterOptions);
    const liveBidding = useSelector((state: AppState) => state.process.templates.current?.type === BwicProcessType.Live);
    const selectPlatformDealersPopupVisible = useSelector((state: AppState) => state.newBwicDealers.selectPlatformDealersPopupVisible);
    const isAllToAll = useSelector((state: AppState) => state.process.isAllToAll);
    const { agreement } = useAgreement();
    const agreementStatusConfirmed = agreement?.agreementStatus === SettlementAgreementStatus.confirmed

    const dealerList = useSelector(newBwicDealersSelector);
    const isLoadingFinished = isRequestSuccess(
        brokerDealerListState.requestState,
        brokerDealerContactsState.requestState,
        brokerDealerUsersState.requestState,
        agreementsState.requestState
    );

    useEffect(() => {
        if (shouldRequest(brokerDealerContactsState.requestState)) {
            dispatch(brokerDealerUserListActions.request());
            dispatch(brokerDealerContactListActions.request());
        }
    }, [brokerDealerListState.requestState, brokerDealerContactsState.requestState, agreementsState.requestState, dispatch]);

    const { dealers } = useDealersFilter(
        dealerList,
        brokerDealerContactsState.items,
        brokerDealerUsersState.items,
        searchTerm,
        filterOptions,
        favoriteDealers,
        agreementsState.items,
        !isLoadingFinished
    );

    const agreementsByDealer = useMemo(() => getClearingBankConfirmedAgreementsByDealer(agreementsState.items),
        [agreementsState.items]
    );

    const isSelectAllCheckboxDisabled = useCallback(() =>
        dealers.every(d => d.isClearingBank && agreementsByDealer[d.id]) ||
        (liveBidding && dealers.every(d => d.status !== CompanyStatus.active)), // Bidding type is Live and all companies are off-platform
        [liveBidding, dealers, agreementsByDealer]
    );

    const allSelected = isLoadingFinished && dealers.every(d => selectedDealers[d.id]);

    useEffect(() => {
        if (isLoadingFinished && dealers.length && !selectedByDefault) {
            dispatch(newBwicDealersActions.selectDealersByDefault(dealers));
        }
    }, [isLoadingFinished, dealers, selectedByDefault, liveBidding, dispatch]);

    const selectAllConfirmedClearingBank = () => {
        const selectedClearingBank: { [dealerId: number]: boolean } = {};
        dealerList.forEach(d => {
            if (d.isClearingBank && d.status === CompanyStatus.active && agreementsByDealer[d.id]) {
                selectedClearingBank[d.id] = true
            }
        });
        return selectedClearingBank;
    };

    // Reset selected state for off-platform companies if live bidding
    const selectAllLiveBidding = () => {
        if (liveBidding) {
            const offPlatformCompanies = dealerList.filter(d => d.status !== CompanyStatus.active);
            return apiUtils.normalize(offPlatformCompanies, c => c.id, () => false);
        };

        return {};
    }

    const handleSelectAllChange = () => {
        const allDealersSelectedLiveBidding = liveBidding && dealers.every(d => selectedDealers[d.id] || d.status !== CompanyStatus.active);

        const updatedSelectedDealers = {
            ...selectedDealers,
            ...apiUtils.normalize(dealers, d => d.id, () => liveBidding ? !allDealersSelectedLiveBidding : !allSelected),
            ...selectAllConfirmedClearingBank(),
            ...selectAllLiveBidding()
        };
        dispatch(newBwicDealersActions.selectDealers(updatedSelectedDealers));
    };

    const handleAddContact = () => {
        dispatch(dealerListActions.setEditableContact({
            id: 0,
            companyId: 0,
            firstName: '',
            lastName: '',
            role: '',
            email: '',
            phone: '',
            readonly: false,
            deleted: false,
            invitationSent: false
        }, '')
        )
    };

    const handleFilterChange = (option: DealerListFilterOption) => {
        dispatch(newBwicDealersActions.updateFilterOptions(option));
        dispatch(dealerListActions.expandDealers({}, true))
    };

    const handleTradingProtocolChange = (isAllToAll: boolean) => {
        dispatch(newBwicDealersActions.setSearchTerm(''));
        dispatch(newBwicDealersActions.clearFilterOptions());
        dispatch(processActions.setAllToAllProtocol(isAllToAll))
    };

    const resetFilterAndSearch = () => {
        dispatch(newBwicDealersActions.clearFilterOptions());
        dispatch(newBwicDealersActions.setSearchTerm(''));
    };

    const nextButtonEnabled = dealers.length && some(selectedDealers, d => d);

    return (
        <>
            <div className="sub-header">
                <WizardHeader hasError={!nextButtonEnabled} nextButtonEnabled={nextButtonEnabled}>
                    {
                        user.hasRoles(...roles.seller()) &&
                        <div className="sub-header-row type02 flex-row">
                            <div className="filters-area flex-row align-items-flex-end">
                                {
                                    appConfig.createAllToAllBySeller &&
                                    agreementStatusConfirmed &&
                                    <div className="filter-section">
                                        <div className="control-trading-protocol flex-row">
                                            <label className="form-label">Trading Protocol:</label>
                                            <div className="radio-group">
                                                <FlexRow>
                                                    <RadioButton
                                                        label="DirectBidding™"
                                                        name="tradingProtocol"
                                                        checked={isAllToAll === false}
                                                        onChange={() => handleTradingProtocolChange(false)}
                                                    />
                                                    <InfoTooltip text="Prefer transparency and relationship-driven execution? Opt for DirectBidding™ to disclose your identity to broker-dealer while still sending anonymous BWICs to the buy-side." />
                                                </FlexRow>
                                                <FlexRow>
                                                    <RadioButton
                                                        label="All-to-All"
                                                        name="tradingProtocol"
                                                        checked={isAllToAll === true}
                                                        onChange={() => handleTradingProtocolChange(true)}
                                                    />
                                                    <InfoTooltip text="Looking for privacy and anonymity in trade execution? Send anonymous BWICs to both buy-side and sell-side platform members with our All-to-All trading protocol." />
                                                </FlexRow>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="filter-section">
                                    <SearchInput
                                        value={searchTerm}
                                        placeholder="Search participant or company"
                                        onChange={(value: string) => dispatch(newBwicDealersActions.setSearchTerm(value))}
                                        onClear={() => dispatch(newBwicDealersActions.setSearchTerm(''))}
                                        disabled={!dealerList.length || !!isAllToAll}
                                        small
                                    />
                                </div>
                                <div className="filter-section filter-section-divider">
                                    <div className="vertical-divider" />
                                </div>
                                <div className="filter-section">
                                    <DealerListFilter
                                        disabled={!dealerList.length || !!isAllToAll}
                                        filterOptions={filterOptions}
                                        onFilterChange={handleFilterChange}
                                    />
                                </div>
                            </div>
                            <div className="flex-item-right controls">
                                <button disabled={!!isAllToAll} type="button" className="btn-link" onClick={handleAddContact}>
                                    <IconSVG name="addParticipant" width={16} height={16} />
                                    Add Contact
                                </button>
                            </div>
                        </div>
                    }
                </WizardHeader>
            </div>
            <Preloader inProgress={!isLoadingFinished}>
                {
                    isLoadingFinished &&
                    <DealerList
                        dealers={dealers}
                        contacts={brokerDealerContactsState.items}
                        users={brokerDealerUsersState.items}
                        enableContactOptions={!isAllToAll}
                        selectEnabled={true}
                        allSelected={allSelected}
                        favoriteButtonDisabled={true}
                        onSelectAllChange={handleSelectAllChange}
                        selectedDealers={selectedDealers}
                        onSelectChange={(dealerId) => dispatch(newBwicDealersActions.selectDealer(dealerId))}
                        agreements={agreementsState.items}
                        noResultsFoundMessage={!dealerList.length ? 'There are no broker-dealer companies.' : undefined}
                        onResetClick={dealerList.length ? resetFilterAndSearch : undefined}
                        selectAllCheckboxDisabled={isSelectAllCheckboxDisabled()}
                        liveBidding={liveBidding}
                    />
                }
            </Preloader>
            {selectPlatformDealersPopupVisible && <SelectPlatformDealersPopup />}
            {
                appConfig.createAllToAllBySeller &&
                user.hasRoles(roles.SellerTrader) &&
                agreementStatusConfirmed && <TradingProtocolPopup isAllToAllDefault={isAllToAll} />
            }
        </>
    )
};
