export enum TransactionsTabTypes {
    overview = "deal-overview",
    structure = "deal-structure",
    portfolio = "target-portfolio",
    documents = "documents",
    IOIs = "iois",
    analytics = "analytics",
    invitedClients = 'invited-clients',
    comparativeMetrics = 'comparative-metrics'
}
