import { trim } from 'lodash';
import sanitizeHTML from 'sanitize-html';

interface Props {
    html: string;
    maxLength: number;
    readMoreLink?: React.ReactNode;
    initiallyTruncated?: boolean;
}

export const TruncateHtml = ({ html, maxLength, readMoreLink, initiallyTruncated }: Props) => {
    const sanitizedText = trim(sanitizeHTML(html, {
        allowedTags: [],
        allowedAttributes: {}
    }));

    const sanitizedWithImages = trim(sanitizeHTML(html, {
        allowedTags: ['img'],
        allowedAttributes: {}
    }));

    if (sanitizedText.length > maxLength) {
        let truncatedText = sanitizedText.slice(0, maxLength);
        const lastSpaceIndex = truncatedText.lastIndexOf(' ');

        if (lastSpaceIndex !== -1) {
            truncatedText = truncatedText.slice(0, lastSpaceIndex);
        }

        return (
            <>
                <span className="news-description" dangerouslySetInnerHTML={{__html: truncatedText}}></span>
                {readMoreLink}
            </>
        );
    }

    if (!sanitizedText.length && sanitizedWithImages.length) {
        return <>{readMoreLink}</>;
    }

    if (initiallyTruncated) {
        return (
            <>
                <span className="news-description" dangerouslySetInnerHTML={{__html: sanitizedText}}></span>
                {readMoreLink}
            </>
        );
    }

    return <span dangerouslySetInnerHTML={{__html: sanitizedText}}></span>;
};
