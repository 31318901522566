export const marketTrendsCategoryId = 14188685758100;

export const sectionsId = {
    gettingStarted: "getting-started",
    webinars: "webinars",
    marketMonitoring: "market-monitoring",
    primaryIssuance: "primary-issuance",
    secondaryMarket: "secondary-market",
};

export const marketMonitoringLinks = {
    dashboard: [
        {
            text: "Dashboard Brochure",
            href: "https://mcusercontent.com/2cf228ffa59560c76cc481980/files/0f8f81d2-acda-c9e1-ceb5-3aed302a1cd9/CLO_Market_Dashboard.02.pdf",
        },
    ],
    portfolio: [
        {
            text: "Portfolio Brochure",
            href: "https://mcusercontent.com/2cf228ffa59560c76cc481980/files/e9511179-874b-90c1-56f4-109988fd5c85/KopenTech_Portfolio.pdf",
        },
    ],
    newsAndWebinar: [
        {
            text: "News & Webinar Brochure",
            href: "https://mcusercontent.com/2cf228ffa59560c76cc481980/files/7eb0a8f9-0b77-6d18-93ec-ca96dec39b2f/News_amp_Webinars.04.pdf",
        },
    ],
};

export const primaryIssuanceLinks = {
    issuanceMonitor: [
        {
            text: "Issuance Monitor Brochure",
            href: "https://mcusercontent.com/2cf228ffa59560c76cc481980/files/6956f695-5e3a-0b21-bf43-ef2c6b6b0ce9/Issuance_Monitor_Overview.04.pdf",
        },
    ],
    arrangerAndManagerProfile: [
        {
            text: "Arranger Profile Brochure",
            href: "https://mcusercontent.com/2cf228ffa59560c76cc481980/files/d2aed136-3680-516d-e35f-efb344ed4566/Arranger_Bank_Profile.02.pdf",
        },
        {
            text: "Manager Profiles Profile Brochure",
            href: "https://mcusercontent.com/2cf228ffa59560c76cc481980/files/835b275c-f5db-af0e-2abf-c290e4f21b9e/CLO_Manager_Profile.05.pdf",
        },
    ],
};

export const secondaryMarketLinks = {
    ktxElectronicTrading: [
        {
            text: "KTX™ Electronic Trading Brochure",
            href: "https://mcusercontent.com/2cf228ffa59560c76cc481980/files/7351ac50-d774-a2d6-cb64-a1914d1bf70f/Electronic_trading.02.pdf",
        },
    ],
    bwicAndDealerInventory: [
        {
            text: "BWIC Monitor Brochure",
            href: "https://mcusercontent.com/2cf228ffa59560c76cc481980/files/1a2a8135-9c32-abbd-555c-649e6c58239e/BWIC_Monitor.04.pdf",
        },
        {
            text: "Dealer Inventory Brochure",
            href: "https://mcusercontent.com/2cf228ffa59560c76cc481980/files/a1d1edc7-24d0-2145-a968-f88a95863b3b/Multi_Dealer_CLO_Inventory.03.pdf",
        },
    ],
    amrRefinancing: [
        {
            text: "AMR Refinancing Brochure",
            href: "https://mcusercontent.com/2cf228ffa59560c76cc481980/files/fb1e560f-3950-c358-2db6-0045d2ee9f33/Applicable_Margin_Reset_.pdf",
        },
    ],
};

