import { lowerCase } from "lodash";
import { useMemo } from "react";
import { RequestState } from "../../../../../constants/request-state";
import { SubscriptionFeature } from "../../../../../types/billing/SubscriptionFeature";
import { isRequesting } from "../../../../../utils";
import { ChartRequiredFeature } from "../../../../access/ChartRequiredFeature";
import { DashboardSkeleton } from "../../../../dashboard/DashboardSkeleton";
import { DashboardWidget, DashboardWidgetContent, DashboardWidgetHeader } from "../../../../dashboard/widget";
import { DashboardNoData } from "../../../../dashboard/DashboardNoData";
import { IssuanceSpreadBase } from "../../../../../types/dashboard/IssuanceSpread";
import { IssuanceSpreadChart } from "../../../../dashboard/usBslNewIssueSpreads/IssuanceSpreadChart";
import { isSpreadEmpty } from "./ComparativeMetrics";

interface Props {
    title: string
    description: string
    requestState: RequestState
    comparableSpread?: IssuanceSpreadBase
    dealSpread: IssuanceSpreadBase
    field: keyof Pick<IssuanceSpreadBase, 'was' | 'warf' | 'ds' | 'wal'>;
    divId: string
    chartNameLimitedPlan?: string
}

export function ComparativeMetricsWidget({ title, description, requestState, comparableSpread, dealSpread, field, divId, chartNameLimitedPlan }: Props) {
    const getDimension = (spread: IssuanceSpreadBase) =>
        spread === comparableSpread ? "Comparable Deals" : "This Deal"

    const hasDataToCompare =
        comparableSpread != null &&
        !isSpreadEmpty(comparableSpread?.[field]) &&
        !isSpreadEmpty(dealSpread?.[field])

    const chart = useMemo(() =>
        <IssuanceSpreadChart
            spreads={hasDataToCompare ? [comparableSpread, dealSpread] : []}
            fieldSelector={spread => spread[field]}
            divId={divId}
            getDimension={getDimension}
        />,
        // eslint-disable-next-line
        [comparableSpread, dealSpread, divId, field]);

    return (
        <DashboardWidget title={title} className="new-issue-chart-box">
            <DashboardSkeleton inProgress={isRequesting(requestState)}>
                <ChartRequiredFeature
                    feature={SubscriptionFeature.IssuanceMonitorFullAccess}
                    chartName={title}
                    chartNameLimitedPlan={chartNameLimitedPlan}
                    blockedClassName={"restricted-placeholder-" + lowerCase(title).replace(/\s+/g, "-")}
                >
                    <DashboardWidgetHeader>
                        <h3>{title}</h3>
                    </DashboardWidgetHeader>
                    <DashboardWidgetContent
                        requestState={requestState}
                        description={description}
                    >
                        {hasDataToCompare ? (
                            chart
                        ) : (
                            <DashboardNoData
                                imgName="no-data-on-security-found"
                                imgWidth={164}
                                imgHeight={128}
                                text="No data available"
                            />
                        )}
                    </DashboardWidgetContent>
                </ChartRequiredFeature>
            </DashboardSkeleton>
        </DashboardWidget>
    );
}