import { startCase } from 'lodash';
import { PipelineColumn } from '../../types/PipelineColumn';
import { OnHoverTooltip } from '../../../common';
import { IColumnDefinition } from '../../../bidding/common/table/types/ColumnDefinition';
import { ArrangerPipeline } from '../../../../types/amr-pipeline/models/ArrangerPipeline';
import { pipelineColumnLabels } from '../../../../constants/amr-pipeline';
import { constants } from '../../../../constants';
import IconSVG from '../../../../styles/svg-icons';
import { dateTimeUtils, formatUtils } from '../../../../utils';
import { ColumnBuilder } from '../../../bidding/common/table/columns/column-builder/ColumnBuilder';
import { IssuanceMonitorTab } from '../../types/PipelineTabTypes';
import { equityTitles } from '../../../../constants/amr-pipeline/equity';
import { Equity } from '../../../../types/amr-pipeline/enums/Equity';
import { FilterRelatedTransactionsIcon } from '../../common/FilterRelatedTransactionsIcon';
import { ManagerLink } from '../../common/ManagerLink';
import { ImSubscriptionActionBlocker } from '../../subscription/ImSubscriptionActionBlocker';
import { BankLink } from '../../common/BankLink';
import DownloadDocument from "../../common/documents/DownloadDocument";

export const lastUpdatedColumn: IColumnDefinition<ArrangerPipeline> = {
    columnKey: PipelineColumn.lastUpdated,
    renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.lastUpdated],
    renderColumnContent: function (arrangerPipeline) {
        const dateUpdatedEst = dateTimeUtils.changeDateTimeZone(
            arrangerPipeline.lastUpdated,
            constants.estTimezone,
            false,
        );

        const value = dateUpdatedEst.format(constants.dateFormat)

        const tooltipOverlay = `${dateUpdatedEst.format(constants.dateTimeFormat)} EST`;

        return <OnHoverTooltip overlay={tooltipOverlay}>{value}</OnHoverTooltip>;
    },
    sortingField: PipelineColumn.lastUpdated,
    className: 'data-list-cell-sm',
};

const arrangerColumn: IColumnDefinition<ArrangerPipeline> = {
    columnKey: PipelineColumn.arranger,
    renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.arranger],
    renderColumnContent: arrangerPipeline => {
        const { arranger } = arrangerPipeline;

        if (!arranger) {
            return constants.emptyPlaceholder;
        }

        return (
            <BankLink
                legalName={arranger.code ?? arranger.legalName}
                referenceName={arranger.referenceName}
                overlayText={arranger.legalName}
            />
        );
    },
    sortingField: PipelineColumn.arranger,
};

const toAllArrangersColumn: IColumnDefinition<ArrangerPipeline> = {
    columnKey: PipelineColumn.toAllArrangers,
    renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.toAllArrangers],
    renderColumnContent: (arrangerPipeline, context) => {
        const { dispatch, amrPipelineActions, setTab } = context;
        const { arranger } = arrangerPipeline;

        if (!arranger) {
            return null;
        }

        return (
            <ImSubscriptionActionBlocker>
                {blocked =>
                    blocked ? (
                        <button className="btn-link" disabled>
                            <IconSVG name="go-to" width={16} height={16} />
                        </button>
                    ) : (
                        <OnHoverTooltip overlay="All Arranger's Transactions">
                            <span
                                onClick={e => {
                                    e.stopPropagation();
                                    setTab(IssuanceMonitorTab.Deals);
                                    dispatch(
                                        amrPipelineActions.changeFilterSelectFromTable(
                                            arranger.referenceName,
                                            'arrangers',
                                        ),
                                    );
                                }}
                                className="btn-link btn-link-go-to"
                            >
                                <IconSVG name="go-to" width={16} height={16} />
                            </span>
                        </OnHoverTooltip>
                    )
                }
            </ImSubscriptionActionBlocker>
        );
    },
    className: 'data-list-cell-xxs',
};

export const dealNameColumn: IColumnDefinition<ArrangerPipeline> = {
    columnKey: PipelineColumn.dealName,
    renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.dealName],
    renderColumnContent: (arrangerPipeline, context) => {
        const { dealLegalName } = arrangerPipeline;

        return (
            <div>
                <div className="text-ellipsis name-box">
                    <OnHoverTooltip overlay={dealLegalName}>
                        <span>{dealLegalName}</span>
                    </OnHoverTooltip>
                </div>
            </div>
        );
    },
    sortingField: PipelineColumn.dealName,
    className: 'data-list-cell-xl',
};

const toRelatedDealsColumn: IColumnDefinition<ArrangerPipeline> = {
    columnKey: PipelineColumn.toRelatedDeals,
    renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.toRelatedDeals],
    renderColumnContent: (arrangerPipeline, context) => {
        const { dealLegalName, dealReferenceName, numberOfTransactions } = arrangerPipeline;
        const { setTab } = context;

        if (!numberOfTransactions) {
            return null;
        }

        return (
            <ImSubscriptionActionBlocker>
                {blocked => (
                    <FilterRelatedTransactionsIcon
                        disabled={blocked}
                        dealLegalName={dealLegalName}
                        dealReferenceName={dealReferenceName}
                        onClick={() => setTab(IssuanceMonitorTab.Deals)}
                    />
                )}
            </ImSubscriptionActionBlocker>
        );
    },
    className: 'data-list-cell-xxs padding-l-0',
};

export const managerColumn: IColumnDefinition<ArrangerPipeline> = {
    columnKey: PipelineColumn.manager,
    renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.manager],
    renderColumnContent: (arrangerPipeline, context) => {
        const { onManagerClick } = context;
        const { collateralManager } = arrangerPipeline;

        if (!collateralManager) {
            return constants.emptyPlaceholder;
        }

        return (
            <ManagerLink
                referenceName={collateralManager.referenceName}
                legalName={collateralManager.legalName}
                onClick={onManagerClick}
            />
        );
    },
    sortingField: 'collateralManager',
    className: 'data-list-cell-xl',
};

const toAllManagersColumn: IColumnDefinition<ArrangerPipeline> = {
    columnKey: PipelineColumn.toAllManagers,
    renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.toAllManagers],
    renderColumnContent: (arrangerPipeline, context) => {
        const { dispatch, amrPipelineActions, setTab } = context;
        const { collateralManager } = arrangerPipeline;

        if (!collateralManager) {
            return constants.emptyPlaceholder;
        }

        return (
            <ImSubscriptionActionBlocker>
                {blocked =>
                    blocked ? (
                        <button className="btn-link" disabled>
                            <IconSVG name="go-to" width={16} height={16} />
                        </button>
                    ) : (
                        <OnHoverTooltip overlay="All Manager's Transactions">
                            <span
                                onClick={e => {
                                    e.stopPropagation();
                                    setTab(IssuanceMonitorTab.Deals);
                                    dispatch(
                                        amrPipelineActions.changeFilterSelectFromTable(
                                            collateralManager.referenceName,
                                            'managers',
                                        ),
                                    );
                                }}
                                className="btn-link btn-link-go-to"
                            >
                                <IconSVG name="go-to" width={16} height={16} />
                            </span>
                        </OnHoverTooltip>
                    )
                }
            </ImSubscriptionActionBlocker>
        );
    },
    className: 'data-list-cell-xxs',
};

export const transactionTypeColumn: IColumnDefinition<ArrangerPipeline> = {
    columnKey: PipelineColumn.transactionType,
    renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.transactionType],
    renderColumnContent: arrangerPipeline =>
        arrangerPipeline.type ? startCase(arrangerPipeline.type) : constants.emptyPlaceholder,
    sortingField: PipelineColumn.transactionType,
};

export const collateralTypeColumn: IColumnDefinition<ArrangerPipeline> = {
    columnKey: PipelineColumn.collateralType,
    renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.collateralType],
    renderColumnContent: arrangerPipeline =>
        arrangerPipeline.collateralType
            ? formatUtils.formatCollateralType(arrangerPipeline.collateralType)
            : constants.emptyPlaceholder,
    sortingField: PipelineColumn.collateralType,
};

export const nonCallPeriodEndColumn: IColumnDefinition<ArrangerPipeline> = {
    columnKey: PipelineColumn.nonCallPeriodEnd,
    renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.nonCallPeriodEndYears],
    renderColumnContent: arrangerPipeline => (
        <span>
            {arrangerPipeline.nonCallPeriodEnd
                ? formatUtils.formatDecimal(arrangerPipeline.nonCallPeriodEnd)
                : constants.emptyPlaceholder}
        </span>
    ),
    sortingField: PipelineColumn.nonCallPeriodEnd,
    headerClassName: 'data-list-cell-sm text-right',
    bodyClassName: 'data-list-cell-sm text-right popover-column',
};

export const reinvestmentPeriodEndColumn: IColumnDefinition<ArrangerPipeline> = {
    columnKey: PipelineColumn.reinvestmentPeriodEnd,
    renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.reinvestmentPeriodEndYears],
    renderColumnContent: arrangerPipeline => (
        <span>
            {arrangerPipeline.reinvestmentPeriodEnd
                ? formatUtils.formatDecimal(arrangerPipeline.reinvestmentPeriodEnd)
                : constants.emptyPlaceholder}
        </span>
    ),
    sortingField: PipelineColumn.reinvestmentPeriodEnd,
    headerClassName: 'data-list-cell-sm text-right',
    bodyClassName: 'data-list-cell-sm text-right popover-column',
};

export const expectedTimingColumn: IColumnDefinition<ArrangerPipeline> = {
    columnKey: PipelineColumn.expectedTiming,
    renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.expectedTiming],
    renderColumnContent: arrangerPipeline => arrangerPipeline.expectedTiming ?? constants.emptyPlaceholder,
    className: 'data-list-cell-md',
    sortingField: PipelineColumn.expectedTiming,
};

export const dealStageColumn: IColumnDefinition<ArrangerPipeline> = {
    columnKey: PipelineColumn.dealStage,
    renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.dealStage],
    renderColumnContent: arrangerPipeline => arrangerPipeline.dealStage ?? constants.emptyPlaceholder,
    className: 'data-list-cell-sm',
    sortingField: PipelineColumn.dealStage,
};

export const euComplianceColumn: IColumnDefinition<ArrangerPipeline> = {
    columnKey: PipelineColumn.euCompliance,
    renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.euCompliance],
    renderColumnContent: arrangerPipeline => formatUtils.formatBooleanWithPlaceholder(arrangerPipeline.euCompliance),
    className: 'data-list-cell-sm',
    sortingField: PipelineColumn.euCompliance,
};

export const anchorColumn: IColumnDefinition<ArrangerPipeline> = {
    columnKey: PipelineColumn.anchor,
    renderColumnHeaderContent: () => (
        <OnHoverTooltip overlay="‘Yes’ - looking for an investor">
            {pipelineColumnLabels[PipelineColumn.anchor]}
            <IconSVG name="info" width={16} height={16} />
        </OnHoverTooltip>
    ),
    renderColumnContent: arrangerPipeline => formatUtils.formatBooleanWithPlaceholder(arrangerPipeline.anchor),
    className: 'data-list-cell-md',
    sortingField: PipelineColumn.anchor,
};

export const equityColumn: IColumnDefinition<ArrangerPipeline> = {
    columnKey: PipelineColumn.equity,
    renderColumnHeaderContent: () => (
        <OnHoverTooltip overlay="‘Yes’ - looking for an investor">
            {pipelineColumnLabels[PipelineColumn.equity]}
            <IconSVG name="info" width={16} height={16} />
        </OnHoverTooltip>
    ),
    renderColumnContent: arrangerPipeline =>
        arrangerPipeline.equity && arrangerPipeline.equity !== Equity.No
            ? equityTitles[arrangerPipeline.equity]
            : constants.emptyPlaceholder,
    className: 'data-list-cell-lg',
    sortingField: PipelineColumn.equity,
};

const syndicateColumn: IColumnDefinition<ArrangerPipeline> = {
    columnKey: PipelineColumn.syndicate,
    renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.syndicate],
    renderColumnContent: (arrangerPipeline, context) => {
        const { setSyndicateContacts } = context;
        const numberOfContacts = arrangerPipeline.syndicateContacts?.length;
        return numberOfContacts ? (
            <span
                className="text-ellipsis pseudo-link"
                onClick={e => {
                    e.stopPropagation();
                    setSyndicateContacts({
                        arrangerName: arrangerPipeline.arranger?.legalName,
                        contacts: arrangerPipeline.syndicateContacts,
                    });
                }}
            >
                {numberOfContacts} contact
                {numberOfContacts > 1 ? 's' : ''}
            </span>
        ) : (
            constants.emptyPlaceholder
        );
    },
    headerClassName: 'data-list-cell-sm',
    bodyClassName: 'data-list-cell-sm',
    sortingField: PipelineColumn.syndicate,
};

export const managerPresentationFileColumn: IColumnDefinition<ArrangerPipeline> = {
    columnKey: PipelineColumn.managerPresentationFile,
    renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.managerPresentationFile],
    renderColumnContent: (
        arrangerPipeline,
        { isManagerPresentationDownloading, managerPresentationLoadHandler, managerPresentationAccessType },
    ) => {
        if (!arrangerPipeline.managerPresentationFile) {
            return constants.emptyPlaceholder;
        }

        return (
            <DownloadDocument
                isLoading={isManagerPresentationDownloading}
                loadingText
                onClick={() =>
                    managerPresentationLoadHandler(
                        arrangerPipeline.referenceName,
                        arrangerPipeline.managerPresentationFile.name,
                        managerPresentationAccessType,
                    )
                }
            >
                <IconSVG name="downloadTemplate" width={16} height={16} />
                Download
            </DownloadDocument>
        );
    },
    headerClassName: 'data-list-cell-sm',
    bodyClassName: 'data-list-cell-sm',
};

export const disclosureFileColumn: IColumnDefinition<ArrangerPipeline> = {
    columnKey: PipelineColumn.disclosureFile,
    renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.disclosureFile],
    renderColumnContent: (arrangerPipeline, { disclosureLoadHandler, isDisclosureDownloading, bankReferenceName }) => {
        return arrangerPipeline.disclosureFile ? (
            <DownloadDocument
                isLoading={isDisclosureDownloading}
                loadingText
                onClick={() =>
                    disclosureLoadHandler(
                        arrangerPipeline.referenceName,
                        arrangerPipeline.disclosureFile.name,
                        bankReferenceName,
                    )
                }
            >
                <IconSVG name="downloadTemplate" width={16} height={16} />
                Download
            </DownloadDocument>
        ) : (
            constants.emptyPlaceholder
        );
    },
    headerClassName: 'data-list-cell-sm',
    bodyClassName: 'data-list-cell-sm',
};

const columns: IColumnDefinition<ArrangerPipeline>[] = [
    lastUpdatedColumn,
    arrangerColumn,
    toAllArrangersColumn,
    dealNameColumn,
    toRelatedDealsColumn,
    managerColumn,
    toAllManagersColumn,
    transactionTypeColumn,
    collateralTypeColumn,
    nonCallPeriodEndColumn,
    reinvestmentPeriodEndColumn,
    expectedTimingColumn,
    dealStageColumn,
    euComplianceColumn,
    anchorColumn,
    equityColumn,
    syndicateColumn,
];

export const getArrangerPipelineColumns = (isMedia: boolean) => {
    const visibleColumns = isMedia ? columns : [...columns, managerPresentationFileColumn, disclosureFileColumn];

    return visibleColumns.map(c => new ColumnBuilder(c));
};
