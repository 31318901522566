import moment from "moment";
import { IColumnDefinition } from "../../../../bidding/common/table/types/ColumnDefinition";
import { ColumnBuilder } from "../../../../bidding/common/table/columns/column-builder/ColumnBuilder";
import { constants, SORTING_TYPE } from "../../../../../constants";
import { formatUtils, moneyUtils } from "../../../../../utils";
import { OriginatingTransactionPortfolioItem } from "../../../../../types/amr-pipeline/models/OriginatingTransactionPortfolioItem";
import { OnHoverTooltip } from "../../../../common";

export const targetPar = {
    columnKey: "targetPar",
    renderColumnHeaderContent: () => "Target Par",
    renderColumnContent: (transactionPortfolio: OriginatingTransactionPortfolioItem) =>
        transactionPortfolio.targetPar
            ? moneyUtils.money(transactionPortfolio.targetPar, true)
            : constants.emptyPlaceholder,
    sortingField: "targetPar",
    headerClassName: "data-list-cell-md text-right",
    bodyClassName: "data-list-cell-md text-right",
    sortingType: SORTING_TYPE.number
};

export const rampedPar = {
    columnKey: "rampedPar",
    renderColumnHeaderContent: () => "Ramped Par",
    renderColumnContent: (
        transactionPortfolio: OriginatingTransactionPortfolioItem
    ) =>
        transactionPortfolio.rampedPar
            ? moneyUtils.money(transactionPortfolio.rampedPar, true)
            : constants.emptyPlaceholder,
    sortingField: "rampedPar",
    headerClassName: "data-list-cell-md text-right",
    bodyClassName: "data-list-cell-md text-right",
    sortingType: SORTING_TYPE.number,
};

const targetPortfolioColumnDefinitions: IColumnDefinition<OriginatingTransactionPortfolioItem>[] = [
    {
        columnKey: "issuer",
        renderColumnHeaderContent: () => "Issuer",
        renderColumnContent: (transactionPortfolio) => (
            <>
                { transactionPortfolio.issuer ?
                    <OnHoverTooltip overlay={transactionPortfolio.issuer}>
                        <span className="text-ellipsis">{transactionPortfolio.issuer}</span>
                    </OnHoverTooltip>
                    : constants.emptyPlaceholder
                }
            </>
        ),
        sortingField: "issuer",
        headerClassName: "data-list-cell-lg",
        bodyClassName: "data-list-cell-lg",
        sortingType: SORTING_TYPE.string,
    },
    {
        columnKey: "securityType",
        renderColumnHeaderContent: () => "Security Type",
        renderColumnContent: (transactionPortfolio) =>
            transactionPortfolio.securityType || constants.emptyPlaceholder,
        sortingField: "securityType",
        headerClassName: "data-list-cell-md",
        bodyClassName: "data-list-cell-md",
        sortingType: SORTING_TYPE.string,
    },
    {
        columnKey: "loanXId",
        renderColumnHeaderContent: () => "LoanX ID",
        renderColumnContent: (transactionPortfolio) =>
            transactionPortfolio.loanXId || constants.emptyPlaceholder,
        sortingField: "loanXId",
        headerClassName: "data-list-cell-md",
        bodyClassName: "data-list-cell-md",
        sortingType: SORTING_TYPE.string,
    },
    targetPar,
    rampedPar,
    {
        columnKey: "purchasePrice",
        renderColumnHeaderContent: () => "Purchase Price",
        renderColumnContent: (transactionPortfolio) =>
            transactionPortfolio.purchasePrice
                ? moneyUtils.money(transactionPortfolio.purchasePrice, true)
                : constants.emptyPlaceholder,
        sortingField: "purchasePrice",
        headerClassName: "data-list-cell-sm text-right",
        bodyClassName: "data-list-cell-sm text-right",
        sortingType: SORTING_TYPE.number,
    },
    {
        columnKey: "facility",
        renderColumnHeaderContent: () => "Facility",
        renderColumnContent: (transactionPortfolio) => (
            <>
                {transactionPortfolio.facility ?
                    <OnHoverTooltip overlay={transactionPortfolio.facility}>
                        <span className="text-ellipsis">{transactionPortfolio.facility}</span>
                    </OnHoverTooltip>
                    : constants.emptyPlaceholder
                }
            </>
        ), 
        sortingField: "facility",
        headerClassName: "data-list-cell-sm",
        bodyClassName: "data-list-cell-sm",
        sortingType: SORTING_TYPE.string,
    },
    {
        columnKey: "rateType",
        renderColumnHeaderContent: () => "Rate Type",
        renderColumnContent: (transactionPortfolio) =>
            transactionPortfolio.rateType || constants.emptyPlaceholder,
        sortingField: "rateType",
        headerClassName: "data-list-cell-sm",
        bodyClassName: "data-list-cell-sm",
        sortingType: SORTING_TYPE.string,
    },
    {
        columnKey: "spread",
        renderColumnHeaderContent: () => "Margin",
        renderColumnContent: (transactionPortfolio) =>
            transactionPortfolio.margin
                ? formatUtils.formatDecimal(transactionPortfolio.margin)
                : constants.emptyPlaceholder,
        sortingField: "margin",
        headerClassName: "data-list-cell-sm text-right",
        bodyClassName: "data-list-cell-sm text-right",
        sortingType: SORTING_TYPE.number,
    },
    {
        columnKey: "liborFloor",
        renderColumnHeaderContent: () => "LIBOR Floor",
        renderColumnContent: (transactionPortfolio) =>
            transactionPortfolio.liborFloor
                ? formatUtils.formatDecimal(transactionPortfolio.liborFloor)
                : constants.emptyPlaceholder,
        sortingField: "liborFloor",
        headerClassName: "data-list-cell-sm text-right",
        bodyClassName: "data-list-cell-sm text-right",
        sortingType: SORTING_TYPE.number,
    },
    {
        columnKey: "referenceIndex",
        renderColumnHeaderContent: () => "Reference Index",
        renderColumnContent: (transactionPortfolio) =>
            transactionPortfolio.referenceIndex
                ? transactionPortfolio.referenceIndex
                : constants.emptyPlaceholder,
        sortingField: "referenceIndex",
        headerClassName: "data-list-cell-md",
        bodyClassName: "data-list-cell-md",
        sortingType: SORTING_TYPE.string,
    },
    {
        columnKey: "maturity",
        renderColumnHeaderContent: () => "Maturity",
        renderColumnContent: (transactionPortfolio) =>
            transactionPortfolio.maturity
                ? moment(transactionPortfolio.maturity).format(constants.dateShortFormat)
                : constants.emptyPlaceholder,
        sortingField: "maturity",
        headerClassName: "data-list-cell-sm",
        bodyClassName: "data-list-cell-sm",
        sortingType: SORTING_TYPE.string,
    },
    {
        columnKey: "country",
        renderColumnHeaderContent: () => "Country",
        renderColumnContent: ({ country }) =>
            country ?? constants.emptyPlaceholder,
        sortingField: "country",
        headerClassName: "data-list-cell-sm",
        bodyClassName: "data-list-cell-sm",
    },
    {
        columnKey: "trancheSize",
        renderColumnHeaderContent: () => "Tranche Size",
        renderColumnContent: (transactionPortfolio) =>
            transactionPortfolio.trancheSize
                ? moneyUtils.money(transactionPortfolio.trancheSize, true)
                : constants.emptyPlaceholder,
        sortingField: "trancheSize",
        headerClassName: "data-list-cell-sm text-right",
        bodyClassName: "data-list-cell-sm text-right",
        sortingType: SORTING_TYPE.number,
    },
    {
        columnKey: "moodysIndustry",
        renderColumnHeaderContent: () => "Moody`s Industry",
        renderColumnContent: (transactionPortfolio) => (
            <>
                {
                    transactionPortfolio.moodysIndustry ?
                        <OnHoverTooltip overlay={transactionPortfolio.moodysIndustry}>
                            <span className="text-ellipsis">{transactionPortfolio.moodysIndustry}</span>
                        </OnHoverTooltip>
                    : constants.emptyPlaceholder
                }
            </>
        ),
        sortingField: "moodysIndustry",
        headerClassName: "data-list-cell-lg",
        bodyClassName: "data-list-cell-lg",
        sortingType: SORTING_TYPE.string,
    },
    {
        columnKey: "snPIndustry",
        renderColumnHeaderContent: () => "S&P Industry",
        renderColumnContent: (transactionPortfolio) => (
            <>
                {
                    transactionPortfolio.snPIndustry ?
                        <OnHoverTooltip overlay={transactionPortfolio.snPIndustry}>
                            <span className="text-ellipsis">{transactionPortfolio.snPIndustry}</span>
                        </OnHoverTooltip>
                        : constants.emptyPlaceholder
                }
            </>
        ),
        sortingField: "snPIndustry",
        headerClassName: "data-list-cell-lg",
        bodyClassName: "data-list-cell-lg",
        sortingType: SORTING_TYPE.string,
    },
    {
        columnKey: "moodysRecoveryRate",
        renderColumnHeaderContent: () => "Moody's Recovery Rate",
        renderColumnContent: (transactionPortfolio) =>
            transactionPortfolio.moodysRecoveryRate
                ? transactionPortfolio.moodysRecoveryRate
                : constants.emptyPlaceholder,
        sortingField: "moodysRecoveryRate",
        headerClassName: "data-list-cell-lg-02 text-right",
        bodyClassName: "data-list-cell-lg-02 text-right",
        sortingType: SORTING_TYPE.number,
    },
    {
        columnKey: "snPRecoveryRate",
        renderColumnHeaderContent: () => "S&P Recovery Rate",
        renderColumnContent: (transactionPortfolio) =>
            transactionPortfolio.snPRecoveryRate
                ? transactionPortfolio.snPRecoveryRate
                : constants.emptyPlaceholder,
        sortingField: "snPRecoveryRate",
        headerClassName: "data-list-cell-lg text-right",
        bodyClassName: "data-list-cell-lg text-right",
        sortingType: SORTING_TYPE.number,
    },
    {
        columnKey: "covLite",
        renderColumnHeaderContent: () => "Cov-Lite",
        renderColumnContent: (transactionPortfolio) =>
            formatUtils.formatBoolean(transactionPortfolio.covLite),
        sortingField: "covLite",
        headerClassName: "data-list-cell-sm",
        bodyClassName: "data-list-cell-sm text-capitalize",
    },
    {
        columnKey: "wal",
        renderColumnHeaderContent: () => "WAL",
        renderColumnContent: (transactionPortfolio) =>
            transactionPortfolio.wal
                ? formatUtils.formatDecimal(transactionPortfolio.wal)
                : constants.emptyPlaceholder,
        sortingField: "wal",
        headerClassName: "data-list-cell-sm text-right",
        bodyClassName: "data-list-cell-sm text-right",
        sortingType: SORTING_TYPE.number,
    },
    {
        columnKey: "moodysCreditFacilityRating",
        renderColumnHeaderContent: () => "Moody's - Credit Facility Rating",
        renderColumnContent: (transactionPortfolio) =>
            transactionPortfolio.moodysCreditFacilityRating ||
            constants.emptyPlaceholder,
        sortingField: "moodysCreditFacilityRating",
        headerClassName: "data-list-cell-xl",
        bodyClassName: "data-list-cell-xl",
        sortingType: SORTING_TYPE.string,
    },
    {
        columnKey: "snPCreditFacilityRating",
        renderColumnHeaderContent: () => "S&P - Credit Facility Rating",
        renderColumnContent: (transactionPortfolio) =>
            transactionPortfolio.snPCreditFacilityRating ||
            constants.emptyPlaceholder,
        sortingField: "snPCreditFacilityRating",
        headerClassName: "data-list-cell-xl",
        bodyClassName: "data-list-cell-xl",
        sortingType: SORTING_TYPE.string,
    },
    {
        columnKey: "fitchCreditFacilityRating",
        renderColumnHeaderContent: () => "Fitch - Credit Facility Rating",
        renderColumnContent: (transactionPortfolio) =>
            transactionPortfolio.fitchCreditFacilityRating ||
            constants.emptyPlaceholder,
        sortingField: "fitchCreditFacilityRating",
        headerClassName: "data-list-cell-xl",
        bodyClassName: "data-list-cell-xl",
        sortingType: SORTING_TYPE.string,
    },
    {
        columnKey: "moodysTrancheRating",
        renderColumnHeaderContent: () => "Moody's - Tranche Rating",
        renderColumnContent: (transactionPortfolio) =>
            transactionPortfolio.moodysTrancheRating ||
            constants.emptyPlaceholder,
        sortingField: "moodysTrancheRating",
        headerClassName: "data-list-cell-lg-02",
        bodyClassName: "data-list-cell-lg-02",
        sortingType: SORTING_TYPE.string,
    },
    {
        columnKey: "snPTrancheRating",
        renderColumnHeaderContent: () => "S&P - Tranche Rating",
        renderColumnContent: (transactionPortfolio) =>
            transactionPortfolio.snPTrancheRating ||
            constants.emptyPlaceholder,
        sortingField: "snPTrancheRating",
        headerClassName: "data-list-cell-lg",
        bodyClassName: "data-list-cell-lg",
        sortingType: SORTING_TYPE.string,
    },
    {
        columnKey: "fitchTrancheRating",
        renderColumnHeaderContent: () => "Fitch - Tranche Rating",
        renderColumnContent: (transactionPortfolio) =>
            transactionPortfolio.fitchTrancheRating ||
            constants.emptyPlaceholder,
        sortingField: "fitchTrancheRating",
        headerClassName: "data-list-cell-lg",
        bodyClassName: "data-list-cell-lg",
        sortingType: SORTING_TYPE.string,
    },
    {
        columnKey: "moodysWARF",
        renderColumnHeaderContent: () => "Moody's DPR (WARF)",
        renderColumnContent: (transactionPortfolio) =>
            moneyUtils.money(transactionPortfolio.moodysWARF, true) || constants.emptyPlaceholder,
        sortingField: "moodysWARF",
        headerClassName: "data-list-cell-lg text-right",
        bodyClassName: "data-list-cell-lg text-right",
        sortingType: SORTING_TYPE.number,
    },
    {
        columnKey: "modeledPrice",
        renderColumnHeaderContent: () => "Modeled Price",
        renderColumnContent: (transactionPortfolio) =>
            formatUtils.formatDecimal(transactionPortfolio.modeledPrice, 4)
                || constants.emptyPlaceholder,
        sortingField: "modeledPrice",
        headerClassName: "data-list-cell-sm text-right",
        bodyClassName: "data-list-cell-sm text-right",
        sortingType: SORTING_TYPE.number,
    },
    {
        columnKey: "indicativeBid",
        renderColumnHeaderContent: () => "Indicative Bid",
        renderColumnContent: (transactionPortfolio) =>
            formatUtils.formatDecimal(transactionPortfolio.indicativeBid, 4)
                || constants.emptyPlaceholder,
        sortingField: "indicativeBid",
        headerClassName: "data-list-cell-sm text-right",
        bodyClassName: "data-list-cell-sm text-right",
        sortingType: SORTING_TYPE.number,
    },
    {
        columnKey: "indicativeAsk",
        renderColumnHeaderContent: () => "Indicative Ask",
        renderColumnContent: (transactionPortfolio) =>
            formatUtils.formatDecimal(transactionPortfolio.indicativeAsk, 4)
                || constants.emptyPlaceholder,
        sortingField: "indicativeAsk",
        headerClassName: "data-list-cell-sm text-right",
        bodyClassName: "data-list-cell-sm text-right",
        sortingType: SORTING_TYPE.number,
    },
    {
        columnKey: "depth",
        renderColumnHeaderContent: () => "Depth",
        renderColumnContent: (transactionPortfolio) =>
            transactionPortfolio.depth
                ? transactionPortfolio.depth
                : constants.emptyPlaceholder,
        sortingField: "depth",
        headerClassName: "data-list-cell-sm text-right",
        bodyClassName: "data-list-cell-sm text-right",
        sortingType: SORTING_TYPE.number,
    },
];

export const targetPortfolioColumns = () => targetPortfolioColumnDefinitions.map((c) => new ColumnBuilder(c));
