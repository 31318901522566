import React from "react";
import classNames from 'classnames';
import { user } from '../../../../../user';
import { IColumnDefinition } from "../../../../bidding/common/table/types/ColumnDefinition";
import { ColumnBuilder } from "../../../../bidding/common/table/columns/column-builder/ColumnBuilder";
import { constants, roles } from "../../../../../constants";
import { amrFormatUtils, formatUtils, moneyUtils, numericUtils } from "../../../../../utils";
import { OriginatingTransactionClass } from "../../../../../types/amr-pipeline/models/OriginatingTransactionClass";
import { OnHoverTooltip } from "../../../../common";
import { OverlayClassNameContent } from "../../../common/OverlayClassNameContent";
import { constants as amrConstants } from "../../../common/constants";
import { OriginatingTransactionClassStatus, transactionClassStatusTitles } from "../../../../../types/amr-pipeline/enums/OriginatingTransactionClassStatus";
import { LastUpdatedPopover } from '../../LastUpdatedPopover';
import { RequiredFeature } from "../../../../access/RequiredFeature";
import { SubscriptionFeature } from "../../../../../types/billing/SubscriptionFeature";
import { BloombergLink } from "../../../../common/BloombergLink";
import { AmrTransaction } from "../../../../../types/amr-pipeline/models/AmrTransaction";
import { OriginatingTransaction } from "../../../../../types/amr-pipeline/models/OriginatingTransaction";
import { ValitanaLink } from "../../../../common/ValitanaLink";
import { ImSubscriptionValue } from '../../../subscription/ImSubscriptionValue';
import { BwicMonitorLink } from "../../../common/BwicMonitorLink";
import { BwicInventoryLink } from "../../../common/BwicInventoryLink";
import { AddToPortfolioLink } from "../../../common/AddToPortfolioLink";

export const newIssueColumns = () => {
    const isAdminOrDataEntryOrMedia = user.hasRoles(roles.Administrator, roles.DataEntry, roles.Media);

    const statsColumnDefinitions: IColumnDefinition<OriginatingTransactionClass>[] = [
        {
            columnKey: "className",
            renderColumnHeaderContent: () => "Class",
            renderColumnContent: (transactionClass) => (
                <OnHoverTooltip
                    overlay={
                        <OverlayClassNameContent
                            transactionClass={transactionClass}
                        />
                    }
                >
                    <span className="text-ellipsis">
                        {`${transactionClass.name} ${
                            transactionClass.originatingTransactionClassStatus ===
                            OriginatingTransactionClassStatus.NotOffered
                                ? amrConstants.notOffered
                                : ""
                        }`}
                    </span>
                </OnHoverTooltip>
            ),
        },
        {
            columnKey: "rating",
            renderColumnHeaderContent: () => "Rtg (M/S/F/K/D)",
            renderColumnContent: (transactionClass) =>
                amrFormatUtils.formatRating(transactionClass),
            className: "data-list-cell-xl",
        },
        {
            columnKey: "balance",
            renderColumnHeaderContent: () => "Balance",
            renderColumnContent: (transactionClass) =>
                transactionClass.balance != null && numericUtils.isNumber(transactionClass.balance)
                    ? moneyUtils.money(transactionClass.balance)
                    : constants.emptyPlaceholder,
            className: "data-list-cell-sm text-right",
        },
        {
            columnKey: "parSubordination",
            renderColumnHeaderContent: () => "C/E, %",
            renderColumnContent: (transactionClass) =>
                transactionClass.parSubordination
                    ? formatUtils.formatDecimal(
                          transactionClass.parSubordination
                      )
                    : constants.emptyPlaceholder,
            className: "data-list-cell-sm text-right",
        },
        {
            columnKey: "mvoc",
            renderColumnHeaderContent: () => "MVOC, %",
            renderColumnContent: (transactionClass) =>
                transactionClass.mvoc
                    ? formatUtils.formatDecimal(transactionClass.mvoc)
                    : constants.emptyPlaceholder,
            className: "data-list-cell-sm text-right",
        },
        {
            columnKey: "wal",
            renderColumnHeaderContent: () => "WAL",
            renderColumnContent: (transactionClass) =>
                transactionClass.wal
                    ? formatUtils.formatDecimal(transactionClass.wal)
                    : constants.emptyPlaceholder,
            className: "data-list-cell-sm text-right",
        },
        {
            columnKey: "coupon",
            renderColumnHeaderContent: () => "Coupon",
            renderColumnContent: ({ floaterIndex, margin}) =>
                amrFormatUtils.formatInventoryCoupon(floaterIndex, margin),
            className: "data-list-cell-md",
        },
        {
            columnKey: "dm",
            renderColumnHeaderContent: () => "DM, bps",
            renderColumnContent: (transactionClass, { transaction }) =>
                <ImSubscriptionValue transaction={transaction}>
                    {transactionClass.dm ?? constants.emptyPlaceholder}
                </ImSubscriptionValue>,
            className: "data-list-cell text-right",
        },
        {
            columnKey: "initialPrice",
            renderColumnHeaderContent: () =>  <span>Initial Price</span>,
            renderColumnContent: (transactionClass, { transaction }) => (
                <ImSubscriptionValue transaction={transaction}>
                    { transactionClass.initialPrice ?
                        <OnHoverTooltip overlay={transactionClass.initialPrice}>
                            <span className="text-ellipsis">{transactionClass.initialPrice}</span>
                        </OnHoverTooltip>
                        : constants.emptyPlaceholder
                    }
                </ImSubscriptionValue>
            ),
            className: "data-list-cell-sm text-right",
        },
        {
            columnKey: "price",
            renderColumnHeaderContent: () => "Price",
            renderColumnContent: (transactionClass, { transaction }) => (
                <ImSubscriptionValue transaction={transaction}>
                    { transactionClass.price ?
                        <OnHoverTooltip overlay={transactionClass.price}>
                            <span className="text-ellipsis">{transactionClass.price}</span>
                        </OnHoverTooltip>
                        : constants.emptyPlaceholder
                    }
                </ImSubscriptionValue>
            ),
            className: "data-list-cell-sm text-right",
        },
        {
            columnKey: "initialGuidance",
            renderColumnHeaderContent: () =>  <span>Initial Guidance</span>,
            renderColumnContent: (transactionClass, { transaction }) => (
                <ImSubscriptionValue transaction={transaction}>
                    { transactionClass.initialGuidance ?
                        <OnHoverTooltip overlay={transactionClass.initialGuidance}>
                            <span className="text-ellipsis">{transactionClass.initialGuidance}</span>
                        </OnHoverTooltip>
                        : constants.emptyPlaceholder
                    }
                </ImSubscriptionValue>
            ),
            className: "data-list-cell-sm",
        },
        {
            columnKey: "guidance",
            renderColumnHeaderContent: ({ transaction } : {
                transaction: AmrTransaction | OriginatingTransaction
            }) => {
                const withGuidance = transaction.classes.some(c => c.guidance);
                return withGuidance ? (
                    <RequiredFeature
                        feature={SubscriptionFeature.IssuanceMonitorFullAccess}
                        renderBlockedContent={() => 'Guidance'}
                    >
                        <LastUpdatedPopover
                            transaction={transaction}
                            field="guidance"
                        >
                            <span className="tooltip-wrapper pseudo-link">
                                Guidance
                            </span>
                        </LastUpdatedPopover>
                    </RequiredFeature>
                ) : (
                    <span>Guidance</span>
                )
            },
            renderColumnContent: (transactionClass, { transaction }) => (
                <ImSubscriptionValue transaction={transaction}>
                    { transactionClass.guidance ?
                        <OnHoverTooltip overlay={transactionClass.guidance}>
                            <span className="text-ellipsis">{transactionClass.guidance}</span>
                        </OnHoverTooltip>
                        : constants.emptyPlaceholder
                    }
                 </ImSubscriptionValue>
            ),
            className: "data-list-cell-sm",
        },
        {
            columnKey: "originatingTransactionClassStatus",
            renderColumnHeaderContent: () => "Class Status",
            renderColumnContent: (transactionClass, { transaction }) =>
                <ImSubscriptionValue transaction={transaction}>
                    { transactionClass.originatingTransactionClassStatus
                        ? transactionClassStatusTitles[transactionClass.originatingTransactionClassStatus]
                        : constants.emptyPlaceholder
                    }
                </ImSubscriptionValue>,
            className: "data-list-cell-sm",
        },
        {
            columnKey: "subscription",
            renderColumnHeaderContent: ({ transaction }: {
                transaction: AmrTransaction | OriginatingTransaction
            }) => {
                const withSubscription = transaction.classes.some(c => (c as OriginatingTransactionClass).subscription);
                return withSubscription ? (
                    <RequiredFeature
                        feature={SubscriptionFeature.IssuanceMonitorFullAccess}
                        renderBlockedContent={() => 'Subscription'}
                    >
                        <LastUpdatedPopover
                            transaction={transaction}
                            field="subscription"
                        >
                            <span className="tooltip-wrapper pseudo-link">
                                Subscription
                            </span>
                        </LastUpdatedPopover>
                    </RequiredFeature>
                ) : (
                    <span>Subscription</span>
                )
            },
            renderColumnContent: (transactionClass, { transaction }) => (
                <ImSubscriptionValue transaction={transaction}>
                    { transactionClass.subscription ?
                        <OnHoverTooltip overlay={transactionClass.subscription}>
                            <span className="text-ellipsis">{transactionClass.subscription}</span>
                        </OnHoverTooltip>
                        : constants.emptyPlaceholder
                    }
                </ImSubscriptionValue>
            ),
            className: "data-list-cell-sm",
        },
        {
            columnKey: "target",
            renderColumnHeaderContent: () => "O/C Target, %",
            renderColumnContent: (transactionClass) =>
                formatUtils.formatDecimal(transactionClass.ocTarget) ||
                constants.emptyPlaceholder,
                className: "data-list-cell-sm text-right",
        },
        {
            columnKey: "trigger",
            renderColumnHeaderContent: () => "O/C Trigger, %",
            renderColumnContent: (transactionClass) =>
                formatUtils.formatDecimal(transactionClass.ocTrigger) ||
                constants.emptyPlaceholder,
            className: "data-list-cell-sm text-right",
        },
        {
            columnKey: "cushion",
            renderColumnHeaderContent: () => "O/C Cushion, %",
            renderColumnContent: (transactionClass) =>
                transactionClass.ocCushion
                    ? formatUtils.formatDecimal(transactionClass.ocCushion)
                    : constants.emptyPlaceholder,
            className: "data-list-cell-sm text-right",
        },
        {
            columnKey: "ticker",
            renderColumnHeaderContent: () => "Ticker (144A)",
            renderColumnContent: (transactionClass) =>
                    transactionClass.tranche?.ticker144A || constants.emptyPlaceholder,
            className: "data-list-cell-lg",
        },
        {
            columnKey: "cusip",
            renderColumnHeaderContent: () => "CUSIP (144A)",
            renderColumnContent: (transactionClass) =>
                transactionClass.tranche?.cusip144A || constants.emptyPlaceholder,
            className: "data-list-cell-md",
        },
        {
            columnKey: "isin",
            renderColumnHeaderContent: () => "ISIN (144A)",
            renderColumnContent: (transactionClass) =>
                transactionClass.tranche?.isin144A || constants.emptyPlaceholder,
            className: "data-list-cell-sm",
        },
        {
            columnKey: "tickerRegS",
            renderColumnHeaderContent: () => "Ticker (Reg S)",
            renderColumnContent: (transactionClass) =>
                transactionClass.tranche?.tickerRegS || constants.emptyPlaceholder,
            className: "data-list-cell-lg",
        },
        {
            columnKey: "cusipRegS",
            renderColumnHeaderContent: () => "CUSIP (Reg S)",
            renderColumnContent: (transactionClass) =>
                transactionClass.tranche?.cusipRegS || constants.emptyPlaceholder,
            className: "data-list-cell-md",
        },
        {
            columnKey: "isinRegS",
            renderColumnHeaderContent: () => "ISIN (Reg S)",
            renderColumnContent: (transactionClass) =>
                transactionClass.tranche?.isinRegS || constants.emptyPlaceholder,
            className: "data-list-cell-md",
        },
        {
            columnKey: "tickerAccdInvCertif",
            renderColumnHeaderContent: () => "Ticker (Acc’d Inv./Certif.)",
            renderColumnContent: (transactionClass) =>
                transactionClass.tranche?.tickerAccdInvCertif || constants.emptyPlaceholder,
            className: "data-list-cell-lg-02",
        },
        {
            columnKey: "cusipAccdInvestor",
            renderColumnHeaderContent: () => "CUSIP (Acc’d Inv./Certif.)",
            renderColumnContent: (transactionClass) =>
                transactionClass.tranche?.cusipAccdInvCertif || constants.emptyPlaceholder,
            className: "data-list-cell-lg-02",
        },
        {
            columnKey: "isinAccdInvestor",
            renderColumnHeaderContent: () => "ISIN (Acc’d Inv./Certif.)",
            renderColumnContent: (transactionClass) =>
                transactionClass.tranche?.isinAccdInvCertif || constants.emptyPlaceholder,
            className: "data-list-cell-lg-02",
        },
    ];

    const actionsColumn: IColumnDefinition<OriginatingTransactionClass>[] = [
        {
            columnKey: 'emptyCol',
            renderColumnHeaderContent: () => '',
            renderColumnContent: () => '',
            className: 'data-list-cell-xxxs',
            stickRight: true,
        },
        {
            columnKey: 'actions',
            renderColumnHeaderContent: () => '',
            renderColumnContent: (transactionClass, context) => (
                <div className="action-row">
                    <div className="action-col">
                        <BwicMonitorLink
                            currency={context.transaction.currency}
                            identifiers={transactionClass.tranche}
                            secondaryPlaceholderColor
                        />
                    </div>
                    <div className="action-col">
                        <BwicInventoryLink
                            currency={context.transaction.currency}
                            identifiers={transactionClass.tranche}
                            secondaryPlaceholderColor
                        />
                    </div>
                    <div className="action-col" onClick={e => e.stopPropagation()}>
                        <AddToPortfolioLink
                            transaction={context.transaction}
                            transactionClass={transactionClass}
                            checkSubscription
                        />
                    </div>
                    <div className="action-col" onClick={e => e.stopPropagation()}>
                        <BloombergLink
                            identifiers={transactionClass.tranche}
                            placeholderIfEmpty
                            secondaryPlaceholderColor
                        />
                    </div>
                    <div className="action-col">
                        <ValitanaLink
                            placeholderIfEmpty
                            secondaryPlaceholderColor
                            identifiers={transactionClass.tranche}
                        />
                    </div>
                </div>
            ),
            className: classNames(
                {
                    'data-list-cell-lg-02': isAdminOrDataEntryOrMedia,
                    'data-list-cell-md-flexible': !isAdminOrDataEntryOrMedia,
                },
                'data-list-cell-bloomberg-menu overflow-visible text-right',
            ),
            stickRight: true,
        },
    ];

    const middleColumns = statsColumnDefinitions.map((c) => {
        return new ColumnBuilder(c);
    });

    const rightColumns = actionsColumn.map((c) => {
        return new ColumnBuilder(c);
    });

    return [[], middleColumns, rightColumns];
};
