import { routes } from "../../constants";
import { OnHoverTooltip } from "../common";

interface Props {
    referenceName: string
    legalName: string
    onClick?: () => void
}

export function CloManagerLink({ referenceName, legalName, onClick }: Props) {
    return (
        <OnHoverTooltip overlay={legalName}>
            <a
                href={routes.manageCloManagerUrl(referenceName)}
                target="_blank"
                onClick={onClick}
                rel="noreferrer"
                className="link-filter text-ellipsis"
            >
                {legalName}
            </a>
        </OnHoverTooltip>
    );
}
